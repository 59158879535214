import { format } from 'date-fns'
import { useContext } from 'react'
import MovieIcon from '@mui/icons-material/Movie';
import InstagramIcon from '@mui/icons-material/Instagram';
import SocialContext from 'src/context/socialContext'

import classes from './styles.module.css';

import Title from '../Title';
import { useTranslation } from 'react-i18next';
import SystemContext from 'src/context/systemContext';
import { imageBaseUrl } from 'src/utils/endpoints';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useMediaQuery } from '@mui/material';
import { Pagination } from 'swiper';

import "swiper/css";
import "swiper/css/pagination";

export default function SocialIntegration() {
  const lgUp = useMediaQuery(theme => theme.breakpoints.up("lg"));
  const smUp = useMediaQuery(theme => theme.breakpoints.up("sm"));

  const { t } = useTranslation()
  const { social } = useContext(SocialContext);
  const { system } = useContext(SystemContext);
  if (social?.length > 0) {
    return (
      <div className={classes.wrapper}>
        <Title text={t('Social media')} page="main" style={{ textAlign: "center", display: "block" }} />

        <Swiper
          pagination={{
            clickable: true,
          }}
          slidesPerView={lgUp ? 3 : smUp ? 2.2 : 1}
          spaceBetween={smUp ? 20 : 10}
          grabCursor={true}
          className={classes.slider}
          modules={[Pagination]}
        >

          {social.map(s => {
            return (
              <SwiperSlide key={s.id} className={"swiperSlide"}>
                <a href={s.permalink} target="_blank" rel="noreferrer" key={s.id} className={classes.social}>
                  <img src={s.media_type === "VIDEO" ? s.thumbnail_url : s.media_url} alt="" />
                  {s.media_type === "VIDEO" && <MovieIcon className={classes.icon} />}
                  <div className={classes.footer}>
                    <p className={classes.content}>{s.caption}</p>
                    <div className={classes.info}>
                      <img src={imageBaseUrl + system.find(s => s.name === "favicon")?.value || ""} alt="" width={40} />
                      <div className={classes.date}>
                        <span>{s.username}</span>
                        <span>{format(new Date(s.timestamp), "dd.MM.yyyy")}</span>
                      </div>
                      <InstagramIcon className={classes.instagramIcon} />
                    </div>
                  </div>
                </a>
              </SwiperSlide>
            )
          })}


        </Swiper>


      </div>

    )
  }

  return (
    null
  )
}
