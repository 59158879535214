import SchoolIcon from '@mui/icons-material/School';
import CoachList from "./CoachList";
import CoachEdit from "./CoachEdit";
import CoachCreate from "./CoachCreate";

const resource = {
  list: CoachList,
  create: CoachCreate,
  edit: CoachEdit,
  icon: SchoolIcon,
};

export default resource;
