import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import { imageBaseUrl } from 'src/utils/endpoints'
import classes from './styles.module.css'
import MainItemsWrapper from '../../main/MainItemWrapper';
import PlayersWrapper from './PlayersWrapper';
import { useContext } from 'react';
import SystemContext from 'src/context/systemContext';

export default function Player({ player, page }) {
    const { t, i18n } = useTranslation();
    const { system } = useContext(SystemContext)
    return (
        <PlayersWrapper page={page}>
            <Link to={`/players/${player.id}`} style={{ display: "block", textDecoration: "none", height: "100%" }}>
                <div className={classes.singlePlayer}>
                    <div className={classes.playerName}>{player.name}</div>
                    <img src={imageBaseUrl + player.image} alt={player.name} className={classes.playerImage}
                        onError={({ currentTarget }) => {
                            currentTarget.src = imageBaseUrl + system.find(s => s.name === "imagePlaceholder")?.value;
                        }} />
                </div>
            </Link>

        </PlayersWrapper>
    )
}
