
import { Edit, SimpleForm, TranslatableInputs } from 'react-admin'
import Editor from '../common/Editor'

export default function AboutEdit() {

    return (
        <Edit>
            <SimpleForm>
                <p>Описание</p>
                <TranslatableInputs locales={['ru', 'uz', 'en']} defaultLocale="ru">
                    <Editor source="description" />
                </TranslatableInputs>
            </SimpleForm>
        </Edit>
    )
}
