import React from "react";
import BodyContainer from "./Container/BodyContainer";
import Layout from "./layout/site";

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Обновить состояние с тем, чтобы следующий рендер показал запасной UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // Можно также сохранить информацию об ошибке в соответствующую службу журнала ошибок
        console.log(error, errorInfo);
        // logErrorToMyService(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // Можно отрендерить запасной UI произвольного вида
            return (
                <Layout page={"error"}>
                    <BodyContainer>
                        <h1>Что-то пошло не так.</h1>
                        <p>Скоро мы это исправим</p>
                    </BodyContainer>
                </Layout>
            );
        }

        return this.props.children;
    }
}