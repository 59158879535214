import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
} from "react-admin";

export default function UserList() {
  return (
    <List>
      <Datagrid rowClick="edit">
        <TextField source="id" label="id" />
        <TextField source="name" label="Имя" />
        <TextField source="number" label="Номер" />
        <ReferenceField
          source="playerRoleId"
          reference="player/role"
          label="Роль"
        >
          <TextField source="name.ru" label="Роль" />
        </ReferenceField>
        <ReferenceField
          source="clubId"
          reference="club/me"
          label="Клуб"
        >
          <TextField source="name" label="Клуб" />
        </ReferenceField>
        <TextField source="country" label="Страна" />

      </Datagrid>
    </List>
  );
}
