import { FormControl, MenuItem, Select } from '@mui/material'
import React from 'react'

import classes from './styles.module.css'

export default function LanguageSelect({ lang, setLang }) {

    const handleChange = (e) => {
        setLang(e.target.value)
    };

    return (
        <FormControl className={classes.languageSelect} variant="standard">
            <Select
                labelId="language-select-label"
                id="language-select"
                value={lang || "ru"}
                onChange={handleChange}
                IconComponent=""
                disableUnderline

            >
                <MenuItem value={"ru"} className={classes.langOption}>RU</MenuItem>
                <MenuItem value={"uz"} className={classes.langOption}>UZ</MenuItem>
            </Select>
        </FormControl>
    )
}
