import { useContext } from 'react'
import { Link } from 'react-router-dom';

import SponsorsContext from 'src/context/sponsorsContext'
import SystemContext from 'src/context/systemContext';
import { imageBaseUrl } from 'src/utils/endpoints';

import classes from './styles.module.css'

export default function Sponsors() {
    const { sponsors } = useContext(SponsorsContext);
    const { system } = useContext(SystemContext)
    return (
        <div className={classes.sponsor}>
            {sponsors.map(sponsor => <a href={sponsor.link || ""} target="_blank" rel="noreferrer" key={sponsor.id}><img src={imageBaseUrl + sponsor.image} alt="sponsor"
                onError={({ currentTarget }) => {
                    currentTarget.src = imageBaseUrl + system.find(s => s.name === "imagePlaceholder")?.value;
                }} /></a>)}
        </div>
    )
}
