import { useTranslation } from 'react-i18next'
import { imageBaseUrl } from 'src/utils/endpoints'
import classes from './styles.module.css'

export default function Sponsor({ data }) {
    const { i18n } = useTranslation()
    return (
        <div className={classes.sponsor}>
            <h2>{data.name}</h2>
            <div className={classes.content}>
                <a className={classes.image} href={data.link || ""} target="_blank" rel="noreferrer">
                    <img src={imageBaseUrl + data.image} alt="" />
                </a>
                <div dangerouslySetInnerHTML={{ __html: data.description[i18n.language] }} className="editorContent"></div>
            </div>
        </div>
    )
}
