import { Edit, maxLength, minLength, SimpleForm, TextInput, TranslatableInputs } from "react-admin";

export default function UserEdit() {
  
  return (
    <Edit>
      <SimpleForm>
        <TranslatableInputs locales={['ru', 'uz', 'en']} defaultLocale="ru">
          <TextInput source="name" label="Название" />
        </TranslatableInputs>
        <TextInput
          source="slug"
          label="Ссылка"
          validate={[
            maxLength(100, "Максимальная длина: 100"),
            minLength(3, "Минимальная длина: 3"),
          ]}
        />
      </SimpleForm>
    </Edit>
  );
}
