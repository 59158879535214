import nProgress from "nprogress";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import Title from "src/components/site/common/Title";
import BodyContainer from "src/Container/BodyContainer";
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';
import TelegramIcon from 'src/assets/icons/telegram.png'
import FacebookIcon from 'src/assets/icons/facebook.png'
import "src/global/common.css";
import Layout from "src/layout/site";
import { getNews, getNewsBySlug } from "src/services/news";

import classes from './styles.module.css'
import { format } from "date-fns";
import { imageBaseUrl } from "src/utils/endpoints";
import NewsItem from "src/components/site/news/NewsItem";
import { useMediaQuery } from "@mui/material";
import SystemContext from "src/context/systemContext";
import { useContext } from "react";
import { Helmet } from "react-helmet";

export default function NewsSinglePage() {
  const { system } = useContext(SystemContext)
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("576"), {
    defaultMatches: true,
    noSsr: false,
  });
  const { slug } = useParams()
  const [loading, setLoading] = useState(true);
  const [news, setNews] = useState();
  const [lastNews, setLastNews] = useState([]);
  const { t, i18n } = useTranslation()

  useEffect(() => {
    async function fetchAll() {
      nProgress.start();
      const tempNews = await getNewsBySlug(slug)
      setNews(tempNews);

      const tempLastNews = await getNews({ limit: 3, exclude: { slug: slug } })
      setLastNews(tempLastNews)
      
      setLoading(false);
      nProgress.done();
    }
    fetchAll();
  }, [slug]);

  return (
    <Layout loading={loading}>
      <Helmet>
        <title key="title">{`Новость – ${system?.find(s => s.name === "siteName")?.value}`}</title>
      </Helmet>
      <BodyContainer>
        <div className={classes.newsTop}>
          <Title text={news?.title?.[i18n.language]} style={{ textTransform: "none", padding: 0 }} />
          {!isSmall &&
            <div className={classes.share}>
              <a href={`https://t.me/share/url?url=${window.location.href}&text=${news?.title[i18n.language]}`} target="_blank" rel="noreferrer" >
                <img src={TelegramIcon} alt="telegram" width={50} />
              </a>
              <a href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`} target="_blank" rel="noreferrer">
                <img src={FacebookIcon} alt="facebook" width={50} />
              </a>
              <a href="/"
                onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(window.location.href);
                }} title={t("Copy")}>
                <ShareRoundedIcon htmlColor="#191919" className={classes.icon} />
              </a>
            </div>
          }
        </div>
        <div className={classes.newsDate}>
          <span>

            <CalendarMonthRoundedIcon htmlColor="#646464" className={classes.dateIcon} />
            {news && format(new Date(news?.createdAt.replace(/-/g, '/')), "dd.LL.yyyy")}
          </span>
          {isSmall &&
            <div className={classes.share}>
              <a href={`https://t.me/share/url?url=${window.location.href}&text=${news?.title[i18n.language]}`} target="_blank" rel="noreferrer" >
                <img src={TelegramIcon} alt="telegram" width={50} />
              </a>
              <a href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`} target="_blank" rel="noreferrer">
                <img src={FacebookIcon} alt="facebook" width={50} />
              </a>
              <a href="/"
                onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(window.location.href);
                }} title={t("Copy")}>
                <ShareRoundedIcon htmlColor="#191919" className={classes.icon} />
              </a>
            </div>
          }
        </div>
        <img src={imageBaseUrl + news?.mainImage} alt={news?.title?.[i18n.language]} width="100%" className={classes.newsMainImage} />
        <div className={`${classes.content} editorContent`} dangerouslySetInnerHTML={{ __html: news?.fullDescription[i18n.language] }}>

        </div>
        <h3 className={classes.lastNewsTitle}>{t("Latest news")}</h3>
        <div className={classes.lastNews}>
          {lastNews.map(n => <NewsItem key={n.id} data={n} page="match" />)}
        </div>
      </BodyContainer>
    </Layout >
  );
}
