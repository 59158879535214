import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@mui/material';
import Title from '../../common/Title'

import { Link } from 'react-router-dom';
import MainBlock from '../MainBlock';
import { SwiperSlide } from 'swiper/react';
import NewsItem from './NewsItem';



import classes from './styles.module.css'
import MainItems from '../MainItems';

export default function News({ news }) {
    const { t } = useTranslation();
    const theme = useTheme();
    const lgUp = useMediaQuery(theme => theme.breakpoints.up("lg"));
    const mdUp = useMediaQuery(theme => theme.breakpoints.up("md"));
    const smUp = useMediaQuery(theme => theme.breakpoints.up("sm"));

    return (
        <MainBlock>
            <Title text={t("Latest news")} page="main" />
            <MainItems>
                {news.map((singleNews) => (
                    <SwiperSlide key={singleNews.id}>
                        <NewsItem data={singleNews} />
                    </SwiperSlide>
                ))}
                <SwiperSlide style={{ height: "auto" }}>
                    <Link to="/news" className={classes.moreNews}>{t("See more news")}</Link>
                </SwiperSlide>
            </MainItems>
        </MainBlock >
    )
}
