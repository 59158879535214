import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import {
    AppBar,
    Box,
    IconButton,
    Toolbar,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { useContext } from "react";
import MenuIcon from '@mui/icons-material/Menu';

import classes from "./styles.module.css";


import LangContext from "src/context/langContext";
import Logo from "src/components/common/Logo";
import LanguageSelect from "./LanguageSelect";
import DropdownMenu from "./DropdownMenu";
import LangButtons from "./LangButtons";



const NavbarRoot = styled(AppBar)(({ theme }) => ({
    position: "sticky",
    top: -1,
    backgroundColor: theme.palette.background.blue,
    boxShadow: "none",
    borderBottom: "10px solid #E4E4E4",

}));
const CustomToolbar = styled(Toolbar)(({ theme }) => ({
    justifyContent: "space-between",
    alignItems: "center",
    background: "none",
    flexWrap: "wrap",
    minHeight: "66px",
    left: 0,
    padding: "10px 22px",
    width: "100%",
    margin: "auto",
    [theme.breakpoints.up("sm")]: {
        minHeight: "70px",
        gap: "10px",
    },
    [theme.breakpoints.up("1450")]: {
        minHeight: "90px",

    },


}));

export const Navbar = ({ menu, page, ...props }) => {
    const theme = useTheme()
    const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"), {
        defaultMatches: true,
        noSsr: false,
    });
    const up576 = useMediaQuery((theme) => theme.breakpoints.up("576"), {
        defaultMatches: true,
        noSsr: false,
    });
    const down1450 = useMediaQuery((theme) => theme.breakpoints.down("1450"), {
        defaultMatches: true,
        noSsr: false,
    });

    const { onSidebarOpen, ...other } = props;

    const { lang, setLang } = useContext(LangContext);


    if (lgUp) {
        return (
            <NavbarRoot
                sx={{
                    left: 0,
                    width: "100%",
                    maxWidth: "1920px",
                    margin: "0 auto",

                }}
                {...other}
            >
                <CustomToolbar>
                    <div className={classes.logoWrapper}>
                        <Link to={"/"} className={classes.logo}>
                            <Logo />
                        </Link>
                    </div>

                    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                        {menu.map(m => {
                            return (
                                // <Link to={m.href} key={m.id} className={classes.navbarLink}>
                                //     {m.title}
                                // </Link>
                                <DropdownMenu menu={m} key={m.id} />
                            )
                        })}
                    </Box>

                    <LangButtons />
                </CustomToolbar>
            </NavbarRoot>
        );
    }
    return (
        <NavbarRoot
            sx={{
                left: 0,
                width: "100%",
                maxWidth: "1920px",
                margin: "0 auto",
            }}
            {...other}
        >
            <CustomToolbar>
                <IconButton
                    onClick={onSidebarOpen}
                    sx={{
                        padding: 0,
                        display: {
                            xs: "inline-flex",
                        },
                    }}
                    disableRipple
                >
                    <MenuIcon htmlColor="#fff" />
                </IconButton>

                <Link to={"/"} className={classes.logo}>
                    <Logo />
                </Link>
                <LanguageSelect lang={lang} setLang={setLang} />
            </CustomToolbar>
        </NavbarRoot>
    );
};

Navbar.propTypes = {
    onSidebarOpen: PropTypes.func,
};