import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';

import SeasonList from "./SeasonList";
import SeasonEdit from "./SeasonEdit";
import SeasonCreate from "./SeasonCreate";

const resource = {
  list: SeasonList,
  create: SeasonCreate,
  edit: SeasonEdit,
  icon: HourglassBottomIcon,
};

export default resource;
