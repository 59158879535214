import { Box, Button, TextField } from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import DeleteIcon from "@mui/icons-material/Delete";

import classes from "./styles.module.css";
import ImageUpload from "../common/ImageUpload";
import RenderWithLangs from "../common/RenderWithLangs";

export default function SlideComponent({ item, handleChange, handleDelete }) {
  return (
    <div>
      <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
        <DragIndicatorIcon />
        <Button onClick={() => handleDelete(item.id)}>
          <DeleteIcon color="error" />
        </Button>
      </Box>
      <div className={classes.slideItemInputs}>
        <RenderWithLangs
          TabRu={
            <TextField
              className={classes.textField}
              label="Заголовок"
              value={item.title.ru}
              onChange={(e) =>
                handleChange({
                  value: e.target.value,
                  name: "title",
                  id: item.id,
                  lang: "ru",
                })
              }
            />}
          TabUz={
            <TextField
              className={classes.textField}
              label="Sarlavha"
              value={item.title.uz}
              onChange={(e) =>
                handleChange({
                  value: e.target.value,
                  name: "title",
                  id: item.id,
                  lang: "uz",
                })
              }
            />
          }
          TabEn={
            <TextField
              className={classes.textField}
              label="Title"
              value={item.title.en}
              onChange={(e) =>
                handleChange({
                  value: e.target.value,
                  name: "title",
                  id: item.id,
                  lang: "en",
                })
              }
            />
          }
        />

        <RenderWithLangs
          TabRu={<TextField
            className={classes.textField}
            label="Описание"
            value={item.description.ru}
            onChange={(e) =>
              handleChange({
                value: e.target.value,
                name: "description",
                id: item.id,
                lang: "ru"
              })
            }
          />
          }
          TabUz={
            <TextField
              className={classes.textField}
              label="Tavsif"
              value={item.description.uz}
              onChange={(e) =>
                handleChange({
                  value: e.target.value,
                  name: "description",
                  id: item.id,
                  lang: "uz"
                })
              }
            />
          }
          TabEn={
            <TextField
              className={classes.textField}
              label="Description"
              value={item.description.en}
              onChange={(e) =>
                handleChange({
                  value: e.target.value,
                  name: "description",
                  id: item.id,
                  lang: "en"
                })
              }
            />
          }
        />

        <TextField
          sx={{ width: "100%", maxWidth: "350px" }}
          label="Ссылка"
          value={item.slug}
          onChange={(e) =>
            handleChange({ value: e.target.value, name: "slug", id: item.id })
          }
        />
      </div>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "15px",
          marginTop: "15px",
        }}
      >
        <ImageUpload
          onChange={(file) =>
            handleChange({ value: file, name: "imageDesktop", id: item.id })
          }
          image={item.imageDesktop}
          title="Картинка для десктопа"
          sizes={{ width: 1920, height: 636 }}
        />
        <ImageUpload
          onChange={(file) =>
            handleChange({ value: file, name: "imageMobile", id: item.id })
          }
          image={item.imageMobile}
          title="Картинка для мобилки"
          sizes={{ width: 320, height: 220 }}
        />
      </Box>
    </div>
  );
}
