import StadiumSharpIcon from '@mui/icons-material/StadiumSharp';

import AcademyList from "./AcademyList";
import AcademyEdit from "./AcademyEdit";
import AcademyCreate from "./AcademyCreate";

const resource = {
  list: AcademyList,
  create: AcademyCreate,
  edit: AcademyEdit,
  icon: StadiumSharpIcon,
};

export default resource;
