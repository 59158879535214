import NewspaperIcon from '@mui/icons-material/Newspaper';

import NewsList from './NewsList';
import NewsEdit from './NewsEdit';
import NewsCreate from './NewsCreate';

const resource = {
    list: NewsList,
    create: NewsCreate,
    edit: NewsEdit,
    icon: NewspaperIcon,
};

export default resource;