import { Box } from "@mui/material";
import {
  Edit,
  ImageField,
  ImageInput,
  maxLength,
  minLength,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  TranslatableInputs,
} from "react-admin";
import { imageBaseUrl } from "src/utils/endpoints";
import Editor from "../common/Editor";

import classes from "./styles.module.css";

export default function NewsEdit() {
  return (
    <Edit sx={{ "& .MuiPaper-root": { overflow: "visible" } }}>
      <SimpleForm>
        <ReferenceInput source="matchId" reference="match">
          <SelectInput optionText={"name.ru"} label="Связанный матч" />
        </ReferenceInput>
        <ReferenceInput source="newsCategoryId" reference="news/category">
          <SelectInput optionText="name.ru" required label="Категория" />
        </ReferenceInput>
        <TranslatableInputs locales={["ru", "uz", "en"]} defaultLocale="ru">
          <TextInput
            source="title"
            label="Название"
            validate={[
              maxLength(100, "Максимальная длина: 100"),
              minLength(3, "Минимальная длина: 3"),
            ]}
          />
        </TranslatableInputs>
        <TextInput
          required
          source="slug"
          label="Ссылка"
          validate={[
            maxLength(100, "Максимальная длина: 100"),
            minLength(3, "Минимальная длина: 3"),
          ]}
        />
        <Box sx={{ width: "100%" }}>
          <TranslatableInputs
            locales={["ru", "uz", "en"]}
            defaultLocale="ru"
            groupKey="shortDescription"
          >
            <TextInput
              multiline
              source="shortDescription"
              label="Короткое описание"
              validate={[
                maxLength(100, "Максимальная длина: 100"),
                minLength(3, "Минимальная длина: 3"),
              ]}
              resettable
              minRows={3}
              sx={{ width: "100%" }}
            />
          </TranslatableInputs>
        </Box>

        <Box className={classes.editorBox}>
          <p>Полное описание</p>
          <TranslatableInputs
            locales={["ru", "uz", "en"]}
            defaultLocale="ru"
            groupKey="fullDescription"
          >
            <Editor size={"large"} source={"fullDescription"} />
          </TranslatableInputs>
        </Box>
        <ImageInput
          isRequired
          source="previewImage"
          label="Превью"
          accept="image/*"
          placeholder={<p>Нажмите сюда или перетащите картинку</p>}
          format={(value) => {
            if (typeof value === "string") {
              return { src: imageBaseUrl + value };
            }
            return value;
          }}
        >
          {/* <PreviewImage source="newsPreviewImage" /> */}
          <ImageField source="src" title="Превью" />
        </ImageInput>
        <ImageInput
          isRequired
          source="mainImage"
          label="Главная"
          accept="image/*"
          placeholder={<p>Нажмите сюда или перетащите картинку</p>}
          format={(value) => {
            if (typeof value === "string") {
              return { src: imageBaseUrl + value };
            }
            return value;
          }}
        >
          {/* <PreviewImage source="newsMainImage" /> */}
          <ImageField source="src" title="Главная" />
        </ImageInput>
      </SimpleForm>
    </Edit>
  );
}
