import nProgress from "nprogress";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { enGB, ru, uz } from "date-fns/locale";

import Title from "src/components/site/common/Title";
import RenderMatches from "src/components/site/matches/RenderMatches";
import BodyContainer from "src/Container/BodyContainer";

import "src/global/common.css";
import Layout from "src/layout/site";
import { getMatches } from "src/services/matches";

import classes from "./styles.module.css";
import MatchItem from "src/components/site/matches/MatchItem";
import SystemContext from "src/context/systemContext";
import { useContext } from "react";
import { Helmet } from "react-helmet";

export default function MatchesPage() {
  const { system } = useContext(SystemContext)
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [matches, setMatches] = useState([]);
  const [month, setMonth] = useState(new Date().getMonth());

  useEffect(() => {
    async function fetchAll() {
      nProgress.start();
      const tempMatches = await getMatches({
        filter: { date: new Date().setMonth(month) },
      });
      setMatches(tempMatches);
      setLoading(false);

      nProgress.done();
    }
    fetchAll();
  }, [month]);
  async function handleMonthChange(month) {
    setMonth(month);
  }
  return (
    <Layout loading={loading}>
      <Helmet>
        <title key="title">{`Матчи – ${system?.find(s => s.name === "siteName")?.value}`}</title>
      </Helmet>
      <BodyContainer>
        <Title text={t("Calendar")} />
        <RenderMatches handleMonthChange={handleMonthChange} month={month} />
        <p className={classes.currentMonth}>
          {i18n.language === "ru"
            ? ru.localize.month(month)
            : i18n.language === "uz"
              ? uz.localize.month(month)
              : enGB.localize.month(month)}{" "}
          {new Date().getFullYear()}
        </p>
        <div className={classes.matches}>
          {matches.map((match) => (
            <MatchItem match={match} key={match.id} />
          ))}
        </div>
      </BodyContainer>
    </Layout>
  );
}
