import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { imageBaseUrl } from 'src/utils/endpoints'
import classes from './styles.module.css'

export default function Staff({ staff }) {
    const { t, i18n } = useTranslation();
    return (
        <div className={classes.staff}>
            <div className={classes.content}>
                <div>
                    <h2 className={classes.title}>{t("Management")}</h2>
                    <ul>
                        {staff?.map(s => {
                            if (s.category === "lead") {
                                return <li key={s.id} className={classes.text}>{s.name} - {s.role[i18n.language]}</li>
                            }
                            return null
                        })}
                    </ul>
                </div>
                <div>
                    <h2 className={classes.title}>{t("Staff")}</h2>
                    <ul>
                        {staff?.map(s => {
                            if (s.category === "staff") {
                                return <li key={s.id} className={classes.text}>{s.name} - {s.role[i18n.language]}</li>
                            }
                            return null
                        })}
                    </ul>
                </div>
            </div>
            <img src="/logo.svg" alt="logo" className={classes.logo} />
        </div>
    )
}
