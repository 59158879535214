import React from "react";
import NewsItem from "./NewsItem";

import classes from "./styles.module.css";

export default function AllNews({ news }) {
  return (
    <div className={classes.news}>
      {news.map((singleNews) => (
        <NewsItem data={singleNews} key={singleNews.id} />
      ))}
    </div>
  );
}
