import { format } from 'date-fns'
import { useContext } from 'react';
import { useTranslation } from 'react-i18next'
import SystemContext from 'src/context/systemContext';
import { imageBaseUrl } from 'src/utils/endpoints'
import classes from './styles.module.css'

export default function Coach({ coach }) {
    const { t, i18n } = useTranslation();
    const { system } = useContext(SystemContext)
    return (
        <div className={classes.coach}>
            <div className={classes.name}>
                <h3>{coach.name}</h3>
            </div>
            <div className={classes.image}>
                <img src={imageBaseUrl + coach.image} alt=""
                    onError={({ currentTarget }) => {
                        currentTarget.src = imageBaseUrl + system.find(s => s.name === "imagePlaceholder")?.value;
                    }}
                />
            </div>
            <div className={classes.position}>
                {coach.role[i18n.language]}

            </div>
        </div>
    )
}
