import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SingleClub from "./SingleClub";
import classes from "./styles.module.css";

export default function TournamentTable({
  clubs: fetchedClubs,
  seasonSelected,
  tournamentSelected,
}) {
  const { t } = useTranslation();

  const [clubs, setClubs] = useState([]);
  useEffect(() => {
    let tempClubs = fetchedClubs?.filter(
      (c) => {

        if (c.isMine) {
          let tempMatches = c.matches.filter(
            (m) => m.seasonId === seasonSelected && m.championshipId === tournamentSelected && new Date(m.date) < new Date()
          );
          if (tempMatches?.length > 0) {
            return true
          }

          return false
        }

        let currentClubResults = c.club_results?.find(
          (cr) => cr.seasonId === seasonSelected
        );
        if (currentClubResults?.championshipId === tournamentSelected) {
          return true
        }
        return false

      }
    );
    for (let i = 0; i < tempClubs?.length; i++) {
      if (tempClubs[i].isMine === 0) {
        continue;
      }
      let tempMatches = tempClubs[i].matches.filter(
        (m) => m.seasonId === seasonSelected && new Date(m.date) < new Date()
      );
      let tempGoalsScored = tempMatches.reduce((pv, cv) => {
        let tempGoals = cv.goals.filter((g) => g.playerId);
        return pv + tempGoals.length;
      }, 0);
      let tempGoalsConceded = tempMatches.reduce((pv, cv) => {
        let tempGoals = cv.goals.filter((g) => g.playerName);
        return pv + tempGoals.length;
      }, 0);
      let tempWins = 0;
      let tempDraws = 0;
      let tempLosses = 0;
      tempMatches.forEach((match) => {
        let tempGoalsScored = match.goals.filter(
          (goal) => goal.playerId
        ).length;
        let tempGoalsConceded = match.goals.filter(
          (goal) => goal.playerName
        ).length;
        if (tempGoalsScored > tempGoalsConceded) {
          tempWins++;
        } else if (tempGoalsScored < tempGoalsConceded) {
          tempLosses++;
        } else {
          tempDraws++;
        }
      });
      tempClubs[i] = {
        ...tempClubs[i],
        club_results: [
          {
            seasonId: seasonSelected,
            totalMatches: tempMatches.length,
            wins: tempWins,
            draws: tempDraws,
            losses: tempLosses,
            goalsScored: tempGoalsScored,
            goalsConceded: tempGoalsConceded,
          },
        ],
      };
    }

    tempClubs = tempClubs?.sort((a, b) => {
      const tempResultA = a?.club_results?.find(
        (cr) => cr.seasonId === seasonSelected
      );
      const tempResultB = b?.club_results?.find(
        (cr) => cr.seasonId === seasonSelected
      );
      if (
        (tempResultB?.wins * 3 + tempResultB?.draws * 1 || 0) ===
        (tempResultA?.wins * 3 + tempResultA?.draws * 1 || 0)
      ) {
        return (
          (tempResultB?.goalsScored - tempResultB?.goalsConceded || 0) -
          (tempResultA?.goalsScored - tempResultA?.goalsConceded || 0)
        );
      }
      return (
        (tempResultB?.wins * 3 + tempResultB?.draws * 1 || 0) -
        (tempResultA?.wins * 3 + tempResultA?.draws * 1 || 0)
      );
    });
    setClubs(tempClubs);
  }, [seasonSelected, tournamentSelected, fetchedClubs]);

  return (
    <div className={classes.tournament}>
      <div className={classes.header}>
        <span className={classes.columnIndex}></span>
        <span className={classes.columnLogo}></span>
        <span className={classes.columnName}>{t("Team")}</span>
        <span className={classes.columnMatches}>{t("Matches")[0]}</span>
        <span className={classes.columnMatches}>{t("Wins")[0]}</span>
        <span className={classes.columnMatches}>{t("Draws")[0]}</span>
        <span className={classes.columnMatches}>{t("Losses")[0]}</span>
        <span className={classes.columnGoals}>{t("GF-GA")}</span>
        <span className={classes.columnMatches}>{t("Pts")}</span>
      </div>
      <div className={classes.clubs}>
        {clubs?.map((club, index) => (
          <SingleClub
            key={club.id}
            club={club}
            season={seasonSelected}
            index={index}
          />
        ))}
      </div>
    </div>
  );
}
