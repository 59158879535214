import { useTranslation } from 'react-i18next';
import MoreButton from '../../common/MoreButton';
import Title from '../../common/Title'

import SingleMedia from '../../common/singleMedia/SingleMedia';
import MainBlock from '../MainBlock';
import MainItems from '../MainItems';
import { SwiperSlide } from 'swiper/react';

export default function Media({ photos }) {
    const { t } = useTranslation();
    return (
        <MainBlock>
            <Title text={t("Media")} page="main" />
            <MainItems>
                {photos.map((photo) => (
                    <SwiperSlide key={photo.id}>
                        <SingleMedia data={photo} page="main" />
                    </SwiperSlide>
                ))}
            </MainItems>
            <MoreButton link="/media" text={t("More photos")} />
        </MainBlock>
    )
}
