import { format } from 'date-fns';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import SystemContext from 'src/context/systemContext';
import { imageBaseUrl } from 'src/utils/endpoints';
import i18n from 'src/utils/i18n';
import Title from '../common/Title';

import classes from './styles.module.css'

const Club = ({ club, title, type = "" }) => {
    const { t, i18n } = useTranslation();
    const { system } = useContext(SystemContext)
    return (
        <div>
            <Title text={title} page={type === "second" ? "main" : ""} />
            <img src={imageBaseUrl + club?.image} alt="" width={"100%"}
                onError={({ currentTarget }) => {
                    currentTarget.src = imageBaseUrl + system.find(s => s.name === "imagePlaceholder")?.value;
                }} />
            <div className={classes.content}>
                <div>
                    <h2 className={classes.title}>
                        {t("Team")}
                    </h2>
                    <ul>
                        {club?.players?.map(player => {
                            return <li key={player.id} className={classes.text}>
                                #{player.number} {player.name} <span>{format(new Date(player.dob.replace(/-/g, '/')), "dd.MM.yyyy")}</span>
                            </li>
                        })}
                    </ul>

                </div>
                <div>
                    <h2 className={classes.title}>
                        {t("Coaches and Staff")}
                    </h2>
                    <ul>
                        {club?.coaches?.map(coach => {
                            return <li key={coach.id} className={classes.text}>
                                <span className={classes.coach}>
                                    <span>{coach.name}</span> - {coach.role[i18n.language]}
                                </span>
                            </li>
                        })}
                    </ul>

                </div>
            </div>
        </div>
    );
}

export default Club;
