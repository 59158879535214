import nProgress from "nprogress";
import { useContext } from "react";
import SystemContext from "src/context/systemContext";
import { imageBaseUrl } from "src/utils/endpoints";

import classes from './styles.module.css'

const Logo = ({ type = "", width, ...props }) => {
  const { system } = useContext(SystemContext);

  return (
    <img
      src={imageBaseUrl + system?.find?.((s) => s.name === "logo")?.value}
      alt="логотип клуба"
      onError={(e) => {
        e.target.src = "/logo.svg"
      }}
      className={`${classes.logo} ${type === "bottom" ? classes.bottom : ""}`}
    />
  );
};

export default Logo;
