import React from 'react'
import { Edit, ReferenceInput, SelectInput, SimpleForm, TextInput, TranslatableInputs } from 'react-admin'
import SortableImages from '../common/SortableImages';

export default function MediaEdit(props) {

    return (

        <Edit {...props}>
            <SimpleForm>
                <ReferenceInput source="matchId" reference="match">
                    <SelectInput optionText="name.ru" label="Связанный матч" />
                </ReferenceInput>
                <TranslatableInputs locales={['ru', 'uz', 'en']} defaultLocale="ru">
                    <TextInput source="title" label="Заголовок" />
                </TranslatableInputs>
                <SortableImages source="images" />
            </SimpleForm>
        </Edit>
    )
}
