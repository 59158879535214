import nProgress from "nprogress";
import { useContext } from "react";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Title from "src/components/site/common/Title";
import Staff from "src/components/site/staff/Staff";
import BodyContainer from "src/Container/BodyContainer";
import SystemContext from "src/context/systemContext";
import Layout from "src/layout/site";
import { getStaff } from "src/services/staff";

import classes from "./styles.module.css";

export default function StaffPage() {
  const { system } = useContext(SystemContext)
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [staff, setStaff] = useState();

  useEffect(() => {
    async function fetchAll() {
      nProgress.start();
      const tempStaff = await getStaff();
      setStaff(tempStaff);
      setLoading(false);
      nProgress.done();
    }
    fetchAll();
  }, []);

  return (
    <Layout loading={loading}>
      <Helmet>
        <title key="title">{`Руководство и Персонал – ${system?.find(s => s.name === "siteName")?.value}`}</title>
      </Helmet>
      <BodyContainer>
        <>
          <Title text={t("Management and Staff")} />
          <Staff staff={staff} />

        </>
      </BodyContainer>
    </Layout>
  );
}
