import { Box } from '@mui/system';
import { Create, email, maxLength, minLength, PasswordInput, ReferenceInput, required, SelectInput, SimpleForm, TextInput } from 'react-admin'

export default function UserCreate(props) {
    const validateConfirmPassword = (value, allValues) => {

        if (value !== allValues.password) {
            return "Пароли должны совпадать"
        }
        return undefined;
    }
    return (
        <Create {...props}>
            <SimpleForm>
                <ReferenceInput source="userRoleId" reference="users/roles">
                    <SelectInput optionText="name" required label="Роль" />
                </ReferenceInput>
                <TextInput source="name" label="Имя" validate={[maxLength(100, "Максимальная длина: 100"), minLength(3, "Минимальная длина: 3")]} />
                <TextInput source="email" label="Email" validate={[email(), required()]} />
                <Box sx={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
                    <PasswordInput source="password" label="Пароль" validate={[minLength(6, "Минимальная длина: 6"), maxLength(100, "Максимальная длина: 100")]} />
                    <PasswordInput source="confirmPassword" label="Повторите пароль" validate={validateConfirmPassword} />

                </Box>
            </SimpleForm>
        </Create>
    )
}
