import { useMediaQuery } from "@mui/material";
import nProgress from "nprogress";
import { useContext } from "react";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import MoreButton from "src/components/site/common/MoreButton";
import Title from "src/components/site/common/Title";
import MassMedia from "src/components/site/massMedia/MassMedia";
import BodyContainer from "src/Container/BodyContainer";
import SystemContext from "src/context/systemContext";

import "src/global/common.css";
import Layout from "src/layout/site";
import { getMassMedia } from "src/services/massMedia";


export default function MassMediaPage() {
    const { system } = useContext(SystemContext)
    const { t } = useTranslation();
    const mdUp = useMediaQuery((theme) => theme.breakpoints.up("md"), {
        defaultMatches: true,
        noSsr: false,
    });
    const [loading, setLoading] = useState(true);
    const [massMedia, setMassMedia] = useState([]);

    useEffect(() => {
        async function fetchAll() {
            nProgress.start();
            const tempMassMedia = await getMassMedia({ limit: mdUp ? 7 : 6 })
            setMassMedia(tempMassMedia)
            setLoading(false);
            nProgress.done();
        }
        fetchAll();
    }, []);

    async function handleClick() {
        nProgress.start();
        let currentMassMedia = [...massMedia];
        const tempMassMedia = await getMassMedia({ offset: currentMassMedia.length, limit: 6 });
        currentMassMedia = currentMassMedia.concat(tempMassMedia);
        setMassMedia(currentMassMedia);
        nProgress.done();
    }

    return (
        <Layout loading={loading}>
            <Helmet>
                <title key="title">{`СМИ – ${system?.find(s => s.name === "siteName")?.value}`}</title>
            </Helmet>
            <BodyContainer>
                <Title text={t("Mass media")} />
                <MassMedia massMedia={massMedia} />
                <MoreButton text={t("More news")} onClick={handleClick} />
            </BodyContainer>
        </Layout>
    );
}
