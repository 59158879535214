import http from "./httpService";
import {apiBaseUrl} from "../utils/endpoints";
async function getSystem() {
  try {
    const { data } = await http.get(apiBaseUrl + "/system");
    return data;
  } catch (err) {
    return err.response;
  }
}

async function getPlaceholder() {
  try {
    const { data } = await http.get(apiBaseUrl + "/system/placeholder");
    return data.value;
  } catch (err) {
    return err.response;
  }
}
async function postSystem(system) {
  try {
    const formData = new FormData();
    system.forEach((sys) => {
      if (sys.imageFile) {
        formData.append(sys.name, sys.imageFile);
      } else if (sys.value !== null) {
        formData.append(sys.name, sys.value);
      }
    });
    const result = await http.post(apiBaseUrl + "/system", formData);
    return result;
  } catch (err) {
    return err.response;
  }
}

export { getSystem, getPlaceholder, postSystem };