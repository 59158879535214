
import React from 'react'
import { Create, DateInput, ImageField, ImageInput, NumberInput, SelectInput, SimpleForm, TextInput, TranslatableInputs } from 'react-admin'

export default function StaffCreate(props) {

    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput source="name" label="Полное имя" />
                <SelectInput source="category" choices={[
                    { id: 'lead', name: 'Руководство' },
                    { id: 'staff', name: 'Персонал' },
                ]} />
                <TranslatableInputs locales={['ru', 'uz', 'en']} defaultLocale="ru">
                    <TextInput source="role" label="роль" />
                </TranslatableInputs>
                <DateInput source="dob" label="Дата рождения" />
                <NumberInput source="arrivalYear" label="Год начала сотрудничества" />
                <ImageInput source="image" label="Фото сотрудника" accept="image/*" placeholder={<p>Нажмите сюда или перетащите картинку</p>}>
                    <ImageField source="src" title="avatar" />
                </ImageInput>
            </SimpleForm>
        </Create>
    )
}
