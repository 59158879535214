import React from 'react'
import { Create, SimpleForm, TextInput, TranslatableInputs } from 'react-admin'
import SortableImages from '../common/SortableImages';

export default function MediaCreate(props) {

    return (

        <Create {...props}>
            <SimpleForm>
                <TranslatableInputs locales={['ru', 'uz', 'en']} defaultLocale="ru">
                    <TextInput source="title" label="Заголовок" />
                </TranslatableInputs>
                <SortableImages source="images" />
            </SimpleForm>
        </Create>
    )
}
