import { apiBaseUrl } from "./endpoints";
import jwtDecode from "jwt-decode";

const authProvider = {
  login: ({ email, password }) => {
    const request = new Request(apiBaseUrl + "/auth", {
      method: "POST",
      body: JSON.stringify({ email, password }),
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((auth) => {
        localStorage.setItem("token", auth.token);
      })
      .catch((err) => {
        console.log("err: ", err);
        throw new Error("Network error");
      });
  },
  checkAuth: () => {
    // Required for the authentication to work
    let token = localStorage.getItem("token");

    const request = new Request(apiBaseUrl + "/auth/verifyAdmin", {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json", "x-auth-token": token }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .catch((err) => {
        console.log("err: ", err);
        throw new Error("Authorization error");
      });
  },
  getPermissions: () => {
    // Required for the authentication to work
    return Promise.resolve();
  },
  logout: () => {
    localStorage.removeItem("token");
    return Promise.resolve();
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("token");
      return Promise.reject();
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },

  getIdentity: () => {
    let user = jwtDecode(localStorage.getItem("token"));
    return Promise.resolve({
      id: user.id,
      fullName: user.name,
      avatar: user.imageUrl,
    });
  },
};

export default authProvider;
