import { CircularProgress, Skeleton } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const AppLoading = () => {
  return (
    <Box
      sx={{
        background: "#061552",
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress sx={{ color: "#FFF112" }} />
    </Box>
  );
};

export default AppLoading;
