import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import classes from "./styles.module.css";

import SystemContext from "src/context/systemContext";
import { useContext } from "react";
import Social from "src/components/site/common/Social";
import SponsorsContext from "src/context/sponsorsContext";

export default function Footer() {
  const { system } = useContext(SystemContext);
  const { sponsors } = useContext(SponsorsContext);
  const { t } = useTranslation();
  return (
    <>
      <Box className={classes.footer}>
        <div className={classes.footerMenu}>
          <div className={classes.footerMenuItem}>
            <h3>{t("Matches")}</h3>
            <div className={classes.footerLinks}>
              <Link to="/matches">{t("Tournaments")}</Link>
              <Link to="/statistics">{t("Statistics")}</Link>
              <Link to="/matches">{t("Calendar")}</Link>
            </div>
          </div>
          <div className={classes.footerMenuItem}>
            <h3>{t("Team")}</h3>
            <div className={classes.footerLinks}>
              <Link to="/players">{t("Players")}</Link>
              <Link to="/coaches">{t("Coaches")}</Link>
              <Link to="/staff">{t("Staff")}</Link>
              <Link to="/womens-football">{t("Women's football")}</Link>
            </div>
          </div>
          <div className={classes.footerMenuItem}>
            <h3>{t("News")}</h3>
            <div className={classes.footerLinks}>
              <Link to="/news">{t("News")}</Link>
              <Link to="/mass-media">{t("Mass media")}</Link>
            </div>
          </div>
          <div className={classes.footerMenuItem}>
            <h3>{t("Academy")}</h3>
            <div className={classes.footerLinks}>
              <Link to="/academy">{t("Academy")}</Link>
              <Link to="/stadium">{t("Stadium")}</Link>
            </div>
          </div>
          <div className={classes.footerMenuItem}>
            <h3>{t("Gallery")}</h3>
            <div className={classes.footerLinks}>
              <Link to="/media">{t("Gallery")}</Link>
            </div>
          </div>
          <div className={classes.footerMenuItem}>
            <h3>{t("About us")}</h3>
            <div className={classes.footerLinks}>
              <Link to="/about">{t("About us")}</Link>
            </div>
          </div>
          <div className={classes.footerMenuItem}>
            <h3>{t("Sponsors")}</h3>
            <div className={classes.footerLinks}>
              {sponsors.map(sponsor =>
                <Link to="/sponsors" key={sponsor.id}>{sponsor.name}</Link>
              )}
            </div>
          </div>
        </div>
        <Social system={system} type="footer" />
      </Box>
    </>
  );
}
