import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import SponsorList from "./SponsorList";
import SponsorEdit from "./SponsorEdit";
import SponsorCreate from "./SponsorCreate";

const resource = {
  list: SponsorList,
  create: SponsorCreate,
  edit: SponsorEdit,
  icon: AttachMoneyIcon,
};

export default resource;
