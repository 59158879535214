import classes from './styles.module.css'
import SingleMassMedia from './SingleMassMedia'

export default function MassMedia({ massMedia }) {
    return (
        <div className={classes.wrapper}>
            {massMedia?.map(m => <SingleMassMedia key={m.id} data={m} />)}
        </div>
    )
}
