import { useTranslation } from 'react-i18next';
import classes from './styles.module.css'

export default function PlayerStatistics({ players }) {
    const { t } = useTranslation();
    return (
        <div>
            <div className={classes.statsPlayers}>
                <h2>{t("Players statistics")}</h2>
                <div className={classes.table}>
                    <div className={classes.tableTop}>
                        <span></span>
                        <span className={classes.columnName}>{t("Player")}</span>

                        <span className={classes.columnMatches}>{t("Matches")[0]}</span>
                        <span className={classes.columnMatches}>{t("Goals")[0]}</span>
                    </div>
                    <div className={classes.tableData}>
                        {players?.map(player => (
                            <div key={player.id} className={classes.tableRow}>
                                <span>{player.number}</span>
                                <span className={classes.columnName}>{player.name}</span>
                                <span className={classes.columnMatches}>{player.matches.length}</span>
                                <span className={classes.columnMatches}>{player.goals.length}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
