import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import { useGetList } from "react-admin";
import { useController } from "react-hook-form";

export default function SelectNews({ source, label }) {
  const { data, isLoading } = useGetList(source, { filter: { matchId: null } });
  const selectedData = useController({ name: source });
  if (isLoading) {
    return null;
  }
  return (
    <Autocomplete
      fullWidth
      multiple
      id="tags-standard"
      options={data.concat(selectedData.field.value)}
      getOptionLabel={(option) => option.title.ru}
      defaultValue={[]}
      value={selectedData.field.value}
      onChange={(e, newValue) => {
        selectedData.field.onChange(newValue);
      }}
      renderInput={(params) => (
        <TextField {...params} variant="standard" label={label} />
      )}
    />
  );
}
