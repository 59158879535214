import i18n from "i18next";
import HttpBackend from 'i18next-http-backend';
import { initReactI18next } from "react-i18next";
import { getLanguage } from "./language";

let lng = getLanguage();
i18n.options.lng = lng;

i18n.use(HttpBackend).use(initReactI18next).init({
    fallbackLng: "uz",
    lng: lng,
    backend: {
        loadPath: `/locales/{{lng}}.json`
    },
    interpolation: {
        escapeValue: false
    },
});
export default i18n;