import http from "./httpService";
import { apiBaseUrl } from "../utils/endpoints";
async function getSocial() {
    try {
        const { data } = await http.get(apiBaseUrl + "/social");
        return data;
    } catch (err) {
        return err.response;
    }
}
export { getSocial };