import {
  ArrayInput,
  Edit,
  FormTab,
  ImageField,
  ImageInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
} from "react-admin";
import { imageBaseUrl } from "src/utils/endpoints";

export default function ClubEdit() {
  return (
    <Edit>
      <TabbedForm>
        <FormTab label="Клуб" value={"1"}>
          <TextInput source="name" label="Название" />
          <ReferenceInput source="championshipId" reference="championship">
            <SelectInput optionText="name.ru" required label="Чемпионат" />
          </ReferenceInput>
          <ImageInput
            source="logo"
            label="Логотип клуба"
            accept="image/*"
            placeholder={<p>Нажмите сюда или перетащите картинку</p>}
            format={(value) => {
              if (typeof value === "string") {
                return { src: imageBaseUrl + value };
              }
              return value;
            }}
          >
            <ImageField source="src" title="avatar" />
          </ImageInput>
         
        </FormTab>
        <FormTab label="Статистика" value={"2"}>
          <ArrayInput
            source="club_results"
            label="Результаты клуба"
            sx={{
              "& .RaSimpleFormIterator-form": {
                display: "flex",
                flexDirection: "row!important",
                flexWrap: "wrap",
                gap: "20px",
              },
              "&.MuiFormControl-root": { paddingTop: "15px" },
            }}
          >
            <SimpleFormIterator>
              <ReferenceInput source="seasonId" reference="season">
                <SelectInput
                  fullWidth
                  optionText="name"
                  required
                  label="Сезон"
                />
              </ReferenceInput>
              <ReferenceInput source="championshipId" reference="championship">
                <SelectInput
                  fullWidth
                  optionText="name.ru"
                  required
                  label="Чемпионат"
                />
              </ReferenceInput>
              <NumberInput
                source="totalMatches"
                label="Всего матчей"
                sx={{ maxWidth: "150px" }}
              />
              <NumberInput
                source="wins"
                label="Побед"
                sx={{ maxWidth: "150px" }}
              />
              <NumberInput
                source="losses"
                label="Поражений"
                sx={{ maxWidth: "150px" }}
              />
              <NumberInput
                source="draws"
                label="Ничьи"
                sx={{ maxWidth: "150px" }}
              />
              <NumberInput
                source="goalsScored"
                label="Голов забито"
                sx={{ maxWidth: "150px" }}
              />
              <NumberInput
                source="goalsConceded"
                label="Голов пропущено"
                sx={{ maxWidth: "150px" }}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
}
