import React from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import classes from './styles.module.css'
import CustomPagination from './CustomPagination';

const CustomNavigation = ({ prevRef, nextRef, pagRef }) => {
    return (
        <div className={classes.navigation}>
            <button ref={prevRef}>
                <KeyboardArrowLeftIcon />
            </button>
            <CustomPagination pagRef={pagRef} />
            <button ref={nextRef}>
                <KeyboardArrowRightIcon />
            </button>
        </div>
    );
}

export default CustomNavigation;
