import nProgress from "nprogress";
import { useContext } from "react";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Coach from "src/components/site/coach/Coach";
import Title from "src/components/site/common/Title";
import BodyContainer from "src/Container/BodyContainer";
import SystemContext from "src/context/systemContext";
import Layout from "src/layout/site";
import { getCoahes } from "src/services/coach";

import classes from "./styles.module.css";

export default function CoachPage() {
  const { t } = useTranslation();
  const { system } = useContext(SystemContext)
  const [loading, setLoading] = useState(true);
  const [coaches, setCoaches] = useState();

  useEffect(() => {
    async function fetchAll() {
      nProgress.start();
      const tempCoaches = await getCoahes({ filter: { clubId: 1 } });
      setCoaches(tempCoaches);
      setLoading(false);
      nProgress.done();
    }
    fetchAll();
  }, []);

  return (
    <Layout loading={loading}>
      <Helmet>
        <title key="title">{`Тренеры – ${system?.find(s => s.name === "siteName")?.value}`}</title>
      </Helmet>
      <BodyContainer>
        <>
          <Title text={t("Coaches")} />
          <div className={classes.coaches}>
            {coaches?.map(coach => <Coach key={coach.id} coach={coach} />)}
          </div>
        </>
      </BodyContainer>
    </Layout>
  );
}
