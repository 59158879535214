import http from "./httpService";
import { apiBaseUrl } from "../utils/endpoints";
async function getMatches({ limit, filter = {} }) {
    try {
        if (limit) {
            let { data } = await http.get(apiBaseUrl + "/match", { params: { range: `[0, ${limit}]`, sort: `["date", "DESC"]`, filter: filter } });
            return data;
        } else {
            let { data } = await http.get(apiBaseUrl + "/match", { params: { sort: `["date", "DESC"]`, filter: filter } });
            return data;

        }
    } catch (err) {
        return err.response;
    }
}
async function getMatch(id) {
    try {

        let { data } = await http.get(apiBaseUrl + "/match/" + id);
        return data;
    } catch (err) {
        return err.response;
    }
}
export { getMatches, getMatch };