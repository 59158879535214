import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  ReferenceField,
} from "react-admin";

export default function UserList() {
  return (
    <List>
      <Datagrid rowClick="edit">
        <TextField source="id" label="id" />
        <TextField source="name" label="Имя" />
        <EmailField source="email" label="Email" />
        <ReferenceField
          source="userRoleId"
          reference="users/roles"
          label="Роль"
        >
          <TextField source="name" label="Роль" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
}
