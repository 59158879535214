import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { imageBaseUrl } from "src/utils/endpoints";
import classes from "./styles.module.css";
import { format } from "date-fns";

import CameraImage from "src/assets/icons/camera.png";
import { useContext } from "react";
import SystemContext from "src/context/systemContext";

export default function MediaItem({ data, page }) {
  const { system } = useContext(SystemContext)
  const { i18n } = useTranslation();
  return (
    <div className={`${page === "match" ? classes.matchMedia : classes.newsItemWrapper}`}>
      <Link
        to={`/media/${data.id}`}
        style={{ display: "block", height: "100%" }}
      >
        <div className={classes.newsItem}>
          <img
            src={imageBaseUrl + data.images[0]}
            alt={data.title[i18n.language]}
            onError={({ currentTarget }) => {
              currentTarget.src = imageBaseUrl + system.find(s => s.name === "imagePlaceholder")?.value;
          }}
          />
          <div className={classes.newsItemContent}>
            <div className={classes.imageCount}>
              <img src={CameraImage} alt="camera" />
              <span>{data.images.length}</span>
            </div>
            <div className={classes.newsItemContentInner}>
              <p>{data.title[i18n.language]}</p>
              <span className={classes.date}>
                {format(new Date(data.createdAt.replace(/-/g, '/')), "dd.MM.yyyy")}
              </span>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}
