import http from "./httpService";
import { apiBaseUrl } from "../utils/endpoints";
async function getTournaments() {
    try {
        const { data } = await http.get(apiBaseUrl + "/tournaments");
        return data;
    } catch (err) {
        return err.response;
    }
}

export {
    getTournaments
}