import { differenceInYears, format } from "date-fns";
import nProgress from "nprogress";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Flag from "src/components/common/Flag";
import Title from "src/components/site/common/Title";
import BodyContainer from "src/Container/BodyContainer";
import SystemContext from "src/context/systemContext";
import Layout from "src/layout/site";
import { getPlayerById, getPlayerStatistics } from "src/services/players";
import { imageBaseUrl } from "src/utils/endpoints";

import classes from "./styles.module.css";

export default function SinglePlayerPage() {
  const { system } = useContext(SystemContext);
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [player, setPlayer] = useState();
  const [playerStatistics, setPlayerStatistics] = useState();
  useEffect(() => {
    async function fetchAll() {
      nProgress.start();
      const tempPlayer = await getPlayerById(id);
      setPlayer(tempPlayer);

      const tempPlayerStatistics = await getPlayerStatistics(id);
      setPlayerStatistics(tempPlayerStatistics);

      setLoading(false);
      nProgress.done();
    }
    fetchAll();
  }, [id]);
  return (
    <Layout loading={loading}>
      <Helmet>
        <title key="title">{`Игрок – ${
          system?.find((s) => s.name === "siteName")?.value
        }`}</title>
      </Helmet>
      <BodyContainer>
        <>
          <Title text={t("Player card")} />
          <h2 className={classes.playerName}>{player?.name}</h2>
          <div className={classes.playerCard}>
            <img
              src={imageBaseUrl + player?.image}
              alt=""
              onError={({ currentTarget }) => {
                currentTarget.src =
                  imageBaseUrl +
                  system?.find((s) => s.name === "imagePlaceholder")?.value;
              }}
            />
            <div className={classes.playerInfo}>
              <div className={classes.playerInfoItem}>
                <span>{t("Role")}</span>
                <span>{player?.player_role.name?.[i18n.language]}</span>
              </div>
              <div className={classes.playerInfoItem}>
                <span>{t("Age")}</span>
                <span>
                  {player &&
                    differenceInYears(
                      new Date(),
                      new Date(player.dob.replace(/-/g, "/"))
                    )}
                </span>
              </div>
              <div className={classes.playerInfoItem}>
                <span>{t("Date of birth")}</span>
                <span>
                  {player &&
                    format(
                      new Date(player.dob.replace(/-/g, "/")),
                      "dd.MM.yyyy"
                    )}
                </span>
              </div>
              <div className={classes.playerInfoItem}>
                <span>{t("Height")}</span>
                <span>{player?.height}</span>
              </div>
              <div className={classes.playerInfoItem}>
                <span>{t("Weight")}</span>
                <span>{player?.weight}</span>
              </div>
              <div className={classes.playerInfoItem}>
                <span>{t("Country")}</span>
                <span>
                  <Flag code={player?.country} />
                </span>
              </div>
            </div>
          </div>
          <div className={classes.playerStatistics}>
            <div>
              <span>{t("Matches")}</span>
              <span className={classes.playerStatisticsValue}>
                {playerStatistics?.games}
              </span>
            </div>
            <div>
              <span>{t("Goals")}</span>
              <span className={classes.playerStatisticsValue}>
                {playerStatistics?.goals}
              </span>
            </div>
          </div>
        </>
      </BodyContainer>
    </Layout>
  );
}
