
import { Link } from 'react-router-dom';

import classes from './styles.module.css'

export default function DropdownMenu({ menu }) {

    return (
        <div className={classes.dropdownMenu}>
            <Link
                to={menu.href}
                className={classes.navbarLink}
            >
                {menu.title}
            </Link>
            <div className={`${classes.subMenu} subMenu`}>
                {menu.subMenu.map(sm =>
                    <Link to={sm.href} key={"submenu" + menu.id + '' + sm.id}> {sm.title}</Link>

                )}

            </div>
        </div>
    )
}
