import { Box } from "@mui/system";
import {
  ArrayInput,
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  Create,
  DateTimeInput,
  FormDataConsumer,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  TranslatableInputs,
  WithRecord,
} from "react-admin";

export default function UserCreate(props) {
  return (
    <Create {...props}>
      <SimpleForm>
        <BooleanInput label="Домашний матч" source="isHome" />
        <TranslatableInputs locales={["ru", "uz", "en"]} defaultLocale="ru">
          <TextInput source="name" label="Название матча" />
        </TranslatableInputs>
        <TextInput source="stadium" label="Стадион" />
        <TextInput source="city" label="Город" />
        <DateTimeInput source="date" label="Дата и время матча" />
        <ReferenceInput source="myClubId" reference="club/me">
          <SelectInput optionText="name" required label="Наш клуб" />
        </ReferenceInput>
        <ReferenceInput source="clubId" reference="club">
          <SelectInput optionText="name" required label="Клуб" />
        </ReferenceInput>
        <ReferenceInput source="seasonId" reference="season">
          <SelectInput optionText="name" required label="Сезон" />
        </ReferenceInput>
        <ReferenceInput source="championshipId" reference="championship">
          <SelectInput optionText="name.ru" required label="Чемпионат" />
        </ReferenceInput>
        <ReferenceArrayInput source="refereeId" reference="referee">
          <AutocompleteArrayInput optionText="name" label="Рефери" />
        </ReferenceArrayInput>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Box>
            <p>Состав Surxon</p>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: "15px" }}>

              <FormDataConsumer>
                {({ formData, ...rest }) => {
                  return (
                    <ReferenceArrayInput source="mainPlayerId" reference="player" filter={{ clubId: formData.myClubId }} perPage={40} sort={{ field: 'id', order: 'ASC' }}>
                      <AutocompleteArrayInput optionText="name" label="Основной состав" />
                    </ReferenceArrayInput>
                  )
                }}
              </FormDataConsumer>

              <FormDataConsumer>
                {({ formData, ...rest }) => {
                  return (
                    <ReferenceArrayInput source="subPlayerId" reference="player" filter={{ clubId: formData.myClubId }} perPage={40} sort={{ field: 'id', order: 'ASC' }}>
                      <AutocompleteArrayInput optionText="name" label="Запасные" />
                    </ReferenceArrayInput>
                  )
                }}
              </FormDataConsumer>

            </Box>
          </Box>
          <Box>
            <p>Состав соперника</p>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: "15px" }}>
              <ArrayInput
                source="opMainPlayerId"
                label="Основной состав"
                sx={{ "&.MuiFormControl-root": { paddingTop: "15px" } }}
              >
                <SimpleFormIterator>
                  <TextInput source="name" label="Имя игрока" />
                </SimpleFormIterator>
              </ArrayInput>
              <ArrayInput
                source="opSubPlayerId"
                label="Запасные"
                sx={{ "&.MuiFormControl-root": { paddingTop: "15px" } }}
              >
                <SimpleFormIterator>
                  <TextInput source="name" label="Имя игрока" />
                </SimpleFormIterator>
              </ArrayInput>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            width: "100%",
            gap: "20px",
            display: "flex",
            flexWrap: "wrap",
            marginTop: "40px",
          }}
        >
          <Box sx={{ width: "100%", maxWidth: "400px" }}>
            <p>Голы Клуба Surxon</p>
            <ArrayInput source="goals" label="">
              <SimpleFormIterator>

                <FormDataConsumer>
                  {({ formData, getSource, ...rest }) => {
                    return (
                      <ReferenceInput source={getSource("playerId")} reference="player" filter={{ clubId: formData.myClubId }} perPage={40} sort={{ field: 'id', order: 'ASC' }} isRequired>
                        <AutocompleteInput optionText="name" label="Имя игрока" />
                      </ReferenceInput>
                    )
                  }}
                </FormDataConsumer>


              </SimpleFormIterator>
            </ArrayInput>
          </Box>

          <Box sx={{ width: "100%", maxWidth: "400px" }}>
            <p>Голы соперника</p>
            <ArrayInput source="opGoals" label="">
              <SimpleFormIterator>
                <TextInput source="name" label="Имя игрока" />
              </SimpleFormIterator>
            </ArrayInput>
          </Box>
        </Box>
      </SimpleForm>
    </Create>
  );
}
