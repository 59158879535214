
import React from 'react'
import { Create, DateInput, ImageField, ImageInput, ReferenceInput, SelectInput, SimpleForm, TextInput, TranslatableInputs } from 'react-admin'
import Editor from '../common/Editor'

export default function ChampionshipCreate(props) {

    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput source="name" label="Полное имя" />
                <ReferenceInput source="clubId" reference="club/me">
                    <SelectInput optionText="name" required label="Клуб" />
                </ReferenceInput>
                <TranslatableInputs locales={['ru', 'uz', 'en']} defaultLocale="ru">
                    <TextInput source="role" label="роль" />
                </TranslatableInputs>
                <p style={{ marginTop: "15px" }}>Описание</p>
                <TranslatableInputs locales={['ru', 'uz', 'en']} defaultLocale="ru">
                    <Editor source="description" label="Описание" />
                </TranslatableInputs>
                <DateInput source="dob" label="Дата рождения" />
                <ImageInput source="image" label="Фото тренера" accept="image/*" placeholder={<p>Нажмите сюда или перетащите картинку</p>}>
                    <ImageField source="src" title="avatar" />
                </ImageInput>
            </SimpleForm>
        </Create>
    )
}
