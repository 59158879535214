
import { Edit, ImageField, ImageInput, SimpleForm, TextInput, TranslatableInputs } from 'react-admin'
import { imageBaseUrl } from 'src/utils/endpoints';
import Editor from '../common/Editor'

export default function SponsorEdit() {

    return (
        <Edit>
            <SimpleForm>
                <TextInput source="name" label="Название" />
                <TextInput source="link" label="Ссылка" />
                <p style={{ marginTop: "15px" }}>Описание</p>
                <TranslatableInputs locales={['ru', 'uz', 'en']} defaultLocale="ru">
                    <Editor source="description" />
                </TranslatableInputs>
                <ImageInput source="image" label="Логотип спонсора" accept="image/*" placeholder={<p>Нажмите сюда или перетащите картинку</p>} format={(value) => {
                    if (typeof value === "string") {
                        return { src: imageBaseUrl + value };
                    }
                    return value;
                }}>
                    <ImageField source="src" title="avatar" />
                </ImageInput>
            </SimpleForm>
        </Edit>
    )
}
