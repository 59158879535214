import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { Title, useDataProvider, useNotify, useTheme } from "react-admin";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import SlideComponent from "./SlideComponent";

import classes from "./styles.module.css";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  for (let i = 0; i < result.length; i++) {
    result[i].order = i;
  }

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle, theme) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "#6ec7f0" : theme?.[0]?.palette?.sliderItem.main || "#ccc8c8",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver, theme) => ({
  background: isDraggingOver ? "lightblue" : theme?.[0]?.palette?.sliderList.main || "#ebeced",
  padding: grid,
  maxWidth: "100%",
  overflow: "hidden",
});

export default function SlidesListContent() {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const theme = useTheme()
  const [items, setItems] = useState([]);


  useEffect(() => {
    async function fetchAll() {

      const { data: slides } = await dataProvider.getList("slides", {
        sort: { field: "order", order: "ASC" },
        pagination: { page: 1, perPage: 100 },
      });
      slides.forEach(slide => {
        slide.id = String(slide.id)
      })
      setItems(slides);

    }

    fetchAll();
  }, [dataProvider]);

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const tempItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    setItems(tempItems);
  }
  async function handleSave() {

    await dataProvider.create("slides", { data: items });
    notify(`Successfully updated`, { type: 'info' });
  }
  function handleItemAdd() {
    let tempItems = [...items];
    tempItems.push({
      id: "item-" + (tempItems.length + 1),
      title: { ru: "", uz: "", en: "" },
      description: { ru: "", uz: "", en: "" },
      slug: "",
      imageDesktop: "",
      imageMobile: "",
      order: tempItems.length,
    });
    setItems(tempItems);
  }

  function handleChange({ name, value, id, lang }) {
    let tempItems = [...items];
    let index = tempItems.findIndex((item) => item.id === id);
    if (name === "imageDesktop" || name === "imageMobile") {
      tempItems[index][name] = { file: value };
    } else {
      if (lang) {
        tempItems[index][name][lang] = value;
      } else {
        tempItems[index][name] = value;
      }
    }

    setItems(tempItems);
  }

  function handleDelete(id) {
    let tempItems = [...items];
    let index = tempItems.findIndex((item) => item.id === id);
    tempItems.splice(index, 1);

    setItems(tempItems);
  }
  return (
    <div className={classes.wrapper}>
      <Title title="Слайды" />
      <DragDropContext onDragEnd={onDragEnd}>
        <div className={classes.topButtons}>
          <Button className={classes.createSlide} onClick={handleItemAdd}>
            Добавить слайд
          </Button>
          <Button
            className={classes.createSlide}
            onClick={handleSave}
            variant="contained"
          >
            Сохранить
          </Button>
        </div>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver, theme)}
            >
              {items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style,
                        theme
                      )}
                    >
                      <SlideComponent
                        key={item.id}
                        item={item}
                        handleChange={handleChange}
                        handleDelete={handleDelete}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}
