import SocialContainer from 'src/Container/SocialContainer'

import classes from './styles.module.css'

import telegram from 'src/assets/icons/telegram.png';
import instagram from 'src/assets/icons/instagram.png';
import facebook from 'src/assets/icons/facebook.png';
import twitter from 'src/assets/icons/twitter.png';
import youtube from 'src/assets/icons/youtube.png';
import tiktok from 'src/assets/icons/tiktok.png';

export default function Social({ system, type }) {
    if (system?.length === 0) {
        return null
    }
    return (
        <div className={`${classes.socialWrapper} ${type === "footer" ? "footer" : ""}`}>
            {type === "footer" && <h3>Наши социальные сети</h3>}
            <SocialContainer>
                <ul className={classes.social}>
                    <li>
                        <a href={system.find(s => s.name === "telegram")?.value} className={classes.socialLink}>
                            <img src={telegram} alt="telegram" width="20" height="20" />
                            @TELEGRAM
                        </a>
                    </li>
                    <li>
                        <a href={system.find(s => s.name === "instagram")?.value} className={classes.socialLink}>
                            <img src={instagram} alt="instagram" width="20" height="20" />
                            @INSTAGRAM
                        </a>
                    </li>
                    <li>
                        <a href={system.find(s => s.name === "facebook")?.value} className={classes.socialLink}>
                            <img src={facebook} alt="facebook" width="20" height="20" />
                            @FACEBOOK
                        </a>
                    </li>
                    <li>
                        <a href={system.find(s => s.name === "twitter")?.value} className={classes.socialLink}>
                            <img src={twitter} alt="twitter" width="20" height="20" />
                            @TWITTER
                        </a>
                    </li>
                    <li>
                        <a href={system.find(s => s.name === "youtube")?.value} className={classes.socialLink}>
                            <img src={youtube} alt="youtube" width="20" height="20" />
                            @YOUTUBE
                        </a>
                    </li>
                    <li>
                        <a href={system.find(s => s.name === "tiktok")?.value} className={classes.socialLink}>
                            <img src={tiktok} alt="tiktok" width="20" height="20" />
                            @TIKTOK
                        </a>
                    </li>
                </ul>

            </SocialContainer>

        </div >
    )
}
