
import React from 'react'
import { Create, ImageField, ImageInput, SimpleForm, TextInput, TranslatableInputs } from 'react-admin'
import Editor from '../common/Editor'

export default function SponsorCreate(props) {

    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput source="name" label="Название" />
                <TextInput source="link" label="Ссылка" />
                <p style={{ marginTop: "15px" }}>Описание</p>
                <TranslatableInputs locales={['ru', 'uz', 'en']} defaultLocale="ru">
                    <Editor source="description" />
                </TranslatableInputs>
                <ImageInput source="image" label="Логотип спонсора" accept="image/*" placeholder={<p>Нажмите сюда или перетащите картинку</p>}>
                    <ImageField source="src" title="avatar" />
                </ImageInput>
            </SimpleForm>
        </Create>
    )
}
