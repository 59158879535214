import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  DateField,
} from "react-admin";

export default function UserList() {
  return (
    <List>
      <Datagrid rowClick="edit">
        <TextField source="id" label="id" />
        <TextField source="name.ru" label="Название" />
        <TextField source="stadium" label="Стадион" />
        <TextField source="city" label="Город" />
        <ReferenceField source="clubId" reference="club" label="Соперник">
          <TextField source="name" label="Соперник" />
        </ReferenceField>
        <ReferenceField source="seasonId" reference="season" label="Сезон">
          <TextField source="name" label="Сезон" />
        </ReferenceField>
        <ReferenceField
          source="championshipId"
          reference="championship"
          label="Чемпионат"
        >
          <TextField source="name.ru" label="Чемпионат" />
        </ReferenceField>
        <DateField source="date" label="Дата" />
      </Datagrid>
    </List>
  );
}
