import React from 'react';

import classes from './styles.module.css'

const PlayersWrapper = ({ children }) => {
    return (
        <div className={classes.playersWrapper}>
            {children}
        </div>
    );
}

export default PlayersWrapper;
