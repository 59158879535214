import SettingsIcon from '@mui/icons-material/Settings';

import SystemList from "./SystemList";

const resource = {
  list: SystemList,
  icon: SettingsIcon,
};

export default resource;
