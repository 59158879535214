import { useContext } from "react";
import { EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";

import classes from "./styles.module.css";
import MatchesPagination from "./MatchesPagination";
import { t } from "i18next";
import SystemContext from "src/context/systemContext";
import MatchStatistics from "./MatchStatistics";
import NewsItem from "../news/NewsItem";
import MediaItem from "../media/MediaItem";

export default function MatchTabs({ match, weather }) {
  const { system } = useContext(SystemContext)
  return (
    <div>
      <Swiper
        spaceBetween={30}
        effect={"fade"}
        modules={[EffectFade]}
        className={classes.matchSwiper}
        fadeEffect={{
          crossFade: true
        }}
        // autoHeight={true}

      >
        <MatchesPagination />

        <div className={classes.swiperWrapper}>
          <SwiperSlide>
            <div className={classes.info}>
              <h3>{t("Report")}</h3>
              <div className={classes.report}>
                {weather &&
                  <div className={classes.reportItem}>
                    <h4>{t("Weather")}</h4>
                    <span>{Math.round(weather?.main.temp) > 0 ? "+" + Math.round(weather?.main.temp) : Math.round(weather?.main.temp)}
                      <img src={`http://openweathermap.org/img/wn/${weather?.weather[0].icon}@2x.png`} alt={weather?.weather[0].description} width={25} /></span>
                  </div>
                }
                <div className={classes.reportItem}>
                  <h4>{t("Referee")}</h4>
                  <span>{match.referees.map(r => r.name).join(" ")}</span>
                </div>
                {match.visit &&
                  <div className={classes.reportItem}>
                    <h4>{t("Attendance")}</h4>
                    <span>{match.visit}</span>
                  </div>
                }
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={classes.info}>
              <h3>{t("Lineup")}</h3>
              <div className={classes.lineup}>
                <div className={classes.club}>
                  {match.isHome ? (
                    <>   <h4>{system.find(s => s.name === "siteName")?.value}</h4>
                      <div>
                        <h5>{t("Main Lineup")}</h5>
                        <ul>
                          {match.players.map(player => {
                            if (player.match_players.type === "main") {
                              return (<li key={player.id}>{player.name}</li>)
                            }
                            return null
                          })}
                        </ul>
                      </div>
                      <div>
                        <h5>{t("Substitutes")}</h5>
                        <ul>
                          {match.players.map(player => {
                            if (player.match_players.type === "sub") {
                              return (<li key={player.id}>{player.name}</li>)
                            }
                            return null
                          })}
                        </ul>
                      </div>
                    </>
                  ) : (
                    <>  <h4>{match.club.name}</h4>
                      <div>
                        <h5>{t("Main Lineup")}</h5>
                        <ul>
                          {match.matchPlayers.map(player => {
                            if (player.type === "main" && player.isOppositeClub) {
                              return (<li key={player.id}>{player.playerName}</li>)
                            }
                            return null
                          })}
                        </ul>
                      </div>
                      <div>
                        <h5>{t("Substitutes")}</h5>
                        <ul>
                          {match.matchPlayers.map(player => {
                            if (player.type === "sub" && player.isOppositeClub) {
                              return (<li key={player.id}>{player.playerName}</li>)
                            }
                            return null
                          })}
                        </ul>
                      </div>
                    </>
                  )}

                </div>
                <div className={classes.club}>
                  {!match.isHome ? (
                    <>   <h4>{system.find(s => s.name === "siteName")?.value}</h4>
                      <div>
                        <h5>{t("Main Lineup")}</h5>
                        <ul>
                          {match.players.map(player => {
                            if (player.match_players.type === "main") {
                              return (<li key={player.id}>{player.name}</li>)
                            }
                            return null
                          })}
                        </ul>
                      </div>
                      <div>
                        <h5>{t("Substitutes")}</h5>
                        <ul>
                          {match.players.map(player => {
                            if (player.match_players.type === "sub") {
                              return (<li key={player.id}>{player.name}</li>)
                            }
                            return null
                          })}
                        </ul>
                      </div>
                    </>
                  ) : (
                    <>  <h4>{match.club.name}</h4>
                      <div>
                        <h5>{t("Main Lineup")}</h5>
                        <ul>
                          {match.matchPlayers.map(player => {
                            if (player.type === "main" && player.isOppositeClub) {
                              return (<li key={player.id}>{player.playerName}</li>)
                            }
                            return null
                          })}
                        </ul>
                      </div>
                      <div>
                        <h5>{t("Substitutes")}</h5>
                        <ul>
                          {match.matchPlayers.map(player => {
                            if (player.type === "sub" && player.isOppositeClub) {
                              return (<li key={player.id}>{player.playerName}</li>)
                            }
                            return null
                          })}
                        </ul>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={classes.info}>
              <h3>{t("Statistics")}</h3>
              <div className={classes.statistics}>
                {match.match_statistics.map(stat => <MatchStatistics key={stat.id} data={stat} isHome={match.isHome} />)}
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={classes.info}>
              <h3>{t("News")}</h3>
              <div className={classes.news}>
                {match.news.map(n => <NewsItem key={n.id} data={n} page="match" />)}
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={classes.info}>
              <h3>{t("Photo/Video")}</h3>
              <div className={classes.news}>
                {match.media.map(m => <MediaItem key={m.id} data={m} page="match" />)}
              </div>
            </div>
          </SwiperSlide>

        </div>
      </Swiper>
    </div >
  );
}
