import nProgress from "nprogress";
import { useContext } from "react";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Title from "src/components/site/common/Title";
import PlayerStatistics from "src/components/site/statistics/PlayerStatistics";
import BodyContainer from "src/Container/BodyContainer";
import SystemContext from "src/context/systemContext";
import Layout from "src/layout/site";
import { getStatistics } from "src/services/statistics";

import classes from "./styles.module.css";

export default function StatisticsPage() {
  const { system } = useContext(SystemContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [players, setPlayers] = useState();
  const [macthesCount, setMacthesCount] = useState();
  const [goalsCount, setGoalsCount] = useState();

  useEffect(() => {
    async function fetchAll() {
      nProgress.start();
      const tempStatistics = await getStatistics();
      
      const tempGames = tempStatistics.matchesCount;
      const tempGoals = tempStatistics.goalsCount;
      setPlayers(tempStatistics.players);
      setMacthesCount(tempGames);
      setGoalsCount(tempGoals);
      setLoading(false);
      nProgress.done();
    }
    fetchAll();
  }, []);

  return (
    <Layout loading={loading}>
      <Helmet>
        <title key="title">{`Статистика – ${
          system?.find((s) => s.name === "siteName")?.value
        }`}</title>
      </Helmet>
      <BodyContainer>
        <>
          <Title text={t("Statistics")} />
          <div className={classes.statsTop}>
            <h2>{t("Club statistics")}</h2>
            <div className={classes.statsBox}>
              <div>
                <span className={classes.statsTitle}>{t("Matches")}</span>
                <span className={classes.statsValue}>{macthesCount}</span>
              </div>
              <div>
                <span className={classes.statsTitle}>{t("Goals")}</span>
                <span className={classes.statsValue}>{goalsCount}</span>
              </div>
            </div>
          </div>
          <PlayerStatistics players={players} />
        </>
      </BodyContainer>
    </Layout>
  );
}
