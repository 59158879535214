import { useTranslation } from "react-i18next";
import { enGB, ru, uz } from "date-fns/locale";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import classes from "./styles.module.css";
import { FormControl, MenuItem, Select, useMediaQuery } from "@mui/material";

export default function RenderMatches({ handleMonthChange, month }) {
  let { i18n } = useTranslation();
  const smUp = useMediaQuery((theme) => theme.breakpoints.up("sm"), {
    defaultMatches: true,
  });
  if (smUp) {
    return (
      <div className={classes.monthes}>
        {Array.from(Array(12).keys()).map((i) => {
          return (
            <button
              key={i}
              className={`${classes.box} ${month === i ? classes.active : ""}`}
              onClick={(e) => handleMonthChange(i)}
            >
              <span className={classes.year}>{new Date().getFullYear()}</span>
              <span className={classes.month}>
                {i18n.language === "ru"
                  ? ru.localize.month(i, { width: "abbreviated" })
                  : i18n.language === "uz"
                  ? uz.localize.month(i, { width: "abbreviated" })
                  : enGB.localize.month(i, { width: "abbreviated" })}
              </span>
            </button>
          );
        })}
      </div>
    );
  }

  return (
    <FormControl className={classes.monthSelect} variant="standard">
      <Select
        labelId="month-select-label"
        id="month-select"
        value={month}
        onChange={(e) => handleMonthChange(e.target.value)}
        IconComponent={() => <ExpandMoreOutlinedIcon />}
        disableUnderline
      >
        {Array.from(Array(12).keys()).map((i) => {
          return (
            <MenuItem key={i} value={i} className={classes.monthOption}>
              {new Date().getFullYear()}{" "}
              {i18n.language === "ru"
                ? ru.localize.month(i)
                : i18n.language === "uz"
                ? uz.localize.month(i)
                : enGB.localize.month(i)}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
