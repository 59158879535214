import GroupsIcon from '@mui/icons-material/Groups';

import ClubList from "./ClubList";
import ClubEdit from "./ClubEdit";
import ClubCreate from "./ClubCreate";

const resource = {
  list: ClubList,
  create: ClubCreate,
  edit: ClubEdit,
  icon: GroupsIcon,
};

export default resource;
