import { useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import Logo from "src/components/common/Logo";
import SocialIntegration from "src/components/site/common/socialIntegration/SocialIntegration";
import Sponsors from "src/components/site/common/Sponsors";
import BodyContainer from "src/Container/BodyContainer";

import classes from "./styles.module.css";

export default function BodyBottom() {
  const smUp = useMediaQuery((theme) => theme.breakpoints.up("sm"), {
    defaultMatches: true,
    noSsr: false,
  });
  return (
    <div className={classes.bodyBottom}>
      <BodyContainer name="footer">
        <SocialIntegration />
        <Link to={"/"} className={classes.logoWrapper}>
          <Logo type="bottom" />
        </Link>
        <div className={classes.sponsorWrapper}>
          <Sponsors />
        </div>
      </BodyContainer>
    </div>
  );
}
