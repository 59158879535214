import { Box } from "@mui/material";
import {
  AutocompleteInput,
  Create,
  DateInput,
  ImageField,
  ImageInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { COUNTRIES } from "src/utils/const";

export default function PlayerCreate(props) {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" label="Полное имя" />
        <ReferenceInput source="playerRoleId" reference="player/role">
          <SelectInput optionText="name.ru" required label="Роль" />
        </ReferenceInput>
        <ReferenceInput source="clubId" reference="club/me">
          <SelectInput optionText="name" required label="Клуб" />
        </ReferenceInput>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
          <NumberInput source="height" label="Рост" />
          <NumberInput source="weight" label="Вес" />
          <DateInput source="dob" label="Дата рождения" />
          <NumberInput source="number" label="Номер игрока" />
        </Box>
        <AutocompleteInput source="country" choices={COUNTRIES} />
        <ImageInput
          source="image"
          label="Фото игрока"
          accept="image/*"
          placeholder={<p>Нажмите сюда или перетащите картинку</p>}
        >
          <ImageField source="src" title="avatar" />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
}
