

import { forwardRef, memo, useCallback, useRef, useState, useEffect } from "react";
import { PhotoAlbum } from "react-photo-album";
import {
    closestCenter,
    DndContext,
    DragOverlay,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    useSortable,
} from "@dnd-kit/sortable";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import classes from "./styles.module.css";

import { imageBaseUrl } from "src/utils/endpoints";
import { useController } from "react-hook-form";
import simpleSvgPlaceholder from "@cloudfour/simple-svg-placeholder";
// @dnd-kit requires string 'id' on sortable elements

const PhotoFrame = forwardRef(({ placeholder, ...props }, ref) => {
    const { layoutOptions, imageProps, overlay, active, insertPosition, sortableProps, photo } =
        props;
    const { alt, style, ...restImageProps } = imageProps;
    return (
        <div
            ref={ref}
            style={{
                // width: overlay ? `calc(100% - ${2 * layoutOptions.padding}px)` : style.width,
                width: overlay ? "100%" : `calc(30% - ${2 * layoutOptions.padding}px)`,
                padding: style.padding,
                marginBottom: style.marginBottom,
            }}
            className={[
                classes["photo-frame"],
                overlay ? "overlay" : "",
                active ? "active" : "",
                insertPosition === "before" ? "insertBefore" : "",
                insertPosition === "after" ? "insertAfter" : "",
            ].join(" ")}
            {...sortableProps}
        >
            <Button
                variant="contained"
                component="label"
                sx={{ marginBottom: "10px" }}
                onClick={() => photo.handleDelete(photo.id)}
            >
                <DeleteIcon />
            </Button>
            <img
                alt={alt}
                style={{
                    ...style,
                    width: "100%",
                    height: "auto",
                    objectFit: "contain",
                    padding: 0,
                    marginBottom: 0,
                }}
                {...restImageProps}
                onError={async function (e) {
                    e.target.src = imageBaseUrl + placeholder;
                }}
            />
        </div>
    );
});
PhotoFrame.displayName = "PhotoFrame";

const MemoizedPhotoFrame = memo(PhotoFrame);

const SortablePhotoFrame = ({ ...props }) => {
    const { photo, activeIndex } = props;
    const { attributes, listeners, isDragging, index, over, setNodeRef } = useSortable({
        id: photo.id,
    });
    return (
        <MemoizedPhotoFrame
            ref={setNodeRef}
            active={isDragging}
            insertPosition={
                activeIndex !== undefined && over?.id === photo.id && !isDragging
                    ? index > activeIndex
                        ? "after"
                        : "before"
                    : undefined
            }
            aria-label="sortable image"
            sortableProps={{
                ...attributes,
                ...listeners,
            }}
            {...props}
        />
    );
};

const SortableImages = () => {
    const placeholder = simpleSvgPlaceholder({
        width: 200,
        height: 200,
        text: `200x200`,
    });

    const images = useController({ name: "images", defaultValue: [] });
    const [photos, setPhotos] = useState([]);


    const handleImageChange = (e) => {
        const tempImages = [...images.field.value];
        Array.from(e.target.files).forEach((file) => {
            tempImages.push({
                file: file,
                src: URL.createObjectURL(file),
                key: tempImages[tempImages.length - 1]?.key + 1 || 1,
                width: 200,
                height: 200,
            });
            // tempImageFiles.push({
            //   key: tempImageFiles[tempImageFiles.length - 1]?.key + 1 || 1,
            //   file: file,
            // });
        });
        images.field.onChange(tempImages);
        // setImageFiles(tempImageFiles);
    };
    const handleImageDelete = useCallback((id) => {
        const tempImages = [...images.field.value];
        const index = tempImages.findIndex((i) => i.key === id);
        tempImages.splice(index, 1);
        images.field.onChange(tempImages);
    }, [images.field])

    const handleImageOrder = useCallback((oldId, newId) => {
        let tempImages = [...images.field.value];
        // let tempImageFiles = [...imageFiles];
        if (tempImages.length !== 0) {
            tempImages = arrayMove(tempImages, oldId, newId);
            // tempImageFiles = arrayMove(tempImageFiles, oldId, newId);
            images.field.onChange(tempImages);
            // setImageFiles(tempImageFiles);
        }
    }, [images.field])




    useEffect(() => {

        const tempImages = [
            ...images.field.value.map((photo) => ({
                ...photo,
                id: photo.key || photo.src,
                handleDelete: handleImageDelete,
            })),
        ];
        setPhotos(tempImages);

    }, [images.field.value]);

    const renderedPhotos = useRef({});
    const [activeId, setActiveId] = useState(null);
    const activeIndex = activeId ? photos.findIndex((photo) => photo.id === activeId) : undefined;

    const sensors = useSensors(
        useSensor(MouseSensor, { activationConstraint: { distance: 5 } }),
        useSensor(TouchSensor, { activationConstraint: { delay: 50, tolerance: 10 } }),
        useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
    );

    const handleDragStart = useCallback(({ active }) => setActiveId(active.id), []);

    const handleDragEnd = useCallback(
        (event) => {
            const { active, over } = event;

            if (active?.id !== over?.id) {
                setPhotos((items) => {
                    const oldIndex = items.findIndex((item) => item.id === active.id);
                    const newIndex = items.findIndex((item) => item.id === over.id);
                    handleImageOrder(oldIndex, newIndex);
                    return arrayMove(items, oldIndex, newIndex);
                });
            }
        },
        [handleImageOrder]
    );

    const renderPhoto = useCallback(
        (props) => {
            // capture rendered photos for future use in DragOverlay
            renderedPhotos.current[props.photo.id] = props;
            return <SortablePhotoFrame activeIndex={activeIndex} {...props} />;
        },
        [activeIndex]
    );
    return (
        <div style={{ width: "100%" }}>
            <label style={{ display: "block", width: "fit-content", margin: "15px 0", cursor: "pointer", padding: "15px 10px", background: "#4f3cc9", color: "#fff", borderRadius: "15px" }} >
                Загрузить фото
                <input type="file" name="image" hidden multiple onChange={handleImageChange} />
            </label>
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
            >
                <SortableContext items={photos}>
                    <div style={{ marginTop: 10, marginBottom: 30 }} className={classes["react-photo-album"]}>
                        <PhotoAlbum
                            photos={photos}
                            layout="rows"
                            spacing={20}
                            padding={10}
                            renderPhoto={renderPhoto}
                            className="test"
                        />
                    </div>
                </SortableContext>
                <DragOverlay>
                    {activeId && <PhotoFrame overlay {...renderedPhotos.current[activeId]} placeholder={placeholder} />}
                </DragOverlay>
            </DndContext>
        </div>
    );
};

export default SortableImages;