import { HttpError } from 'react-admin';
import { apiBaseUrl, imageBaseUrl } from './endpoints';

import httpClient from './httpClient';

const errorMsg = "Ошибка, проверьте все поля и попробуйте еще раз";
const myDataProvider = (dataProvider) => {
    return {
        ...dataProvider,
        getOne: (resource, params) => {
            if (resource === "media") {
                return httpClient(`${apiBaseUrl}/${resource}/${params.id}`, {
                    method: 'GET'
                }).then(({ json }) => {
                    let tempImages = [];
                    json.images.forEach((image, index) => {
                        tempImages.push({
                            src: imageBaseUrl + image,
                            key: index + 1,
                            width: 200,
                            height: 200,
                        });
                    });
                    return {
                        data: { ...params.data, id: json.id, images: tempImages, title: json.title, matchId: json.matchId },
                    };
                }).catch(err => {
                    console.log("error: ", err);
                    return err;
                });
            } else if (resource === "match") {
                return httpClient(`${apiBaseUrl}/${resource}/${params.id}`, {
                    method: 'GET'
                }).then(({ json }) => {
                    return {
                        data: {
                            ...json,
                            refereeId: json.referees.map(r => r.id),
                            mainPlayerId: json.players.map(p => p.match_players.type !== "sub" && p.id),
                            subPlayerId: json.players.map(p => p.match_players.type !== "main" && p.id),
                            opMainPlayerId: json.matchPlayers.filter(p => p.isOppositeClub === 1 && p.type === "main").map(p => { return { name: p.playerName }; }),
                            opSubPlayerId: json.matchPlayers.filter(p => p.isOppositeClub === 1 && p.type === "sub").map(p => { return { name: p.playerName }; }),
                            goals: json.goals.filter(g => g.playerId),
                            opGoals: json.goals.filter(g => !g.playerId).map(g => { return { name: g.playerName }; }),
                            id: json.id
                        },
                    };
                }).catch(err => {
                    console.log("error: ", err);
                    return err;
                });
            }
            else {
                return dataProvider.getOne(resource, params);

            }
        },
        create: (resource, params) => {
            if (resource === "news") {
                let formData = new FormData();
                formData.append('newsCategoryId', params.data.newsCategoryId);
                formData.append('title', JSON.stringify(params.data.title));
                formData.append('slug', params.data.slug);
                formData.append('shortDescription', JSON.stringify(params.data.shortDescription));
                formData.append('fullDescription', JSON.stringify(params.data.fullDescription));
                formData.append('previewImage', params.data.previewImage?.rawFile);
                formData.append('mainImage', params.data.mainImage?.rawFile);
                return httpClient(`${apiBaseUrl}/${resource}`, {
                    method: 'POST',
                    body: formData,
                }).then(({ json }) => {
                    console.log("json: ", json);
                    return ({
                        data: { ...params.data, id: json.id },
                    });
                }).catch(err => {
                    //status body name

                    return Promise.reject(new HttpError(err.body?.msg || errorMsg, err.status || 400, err.body));
                });
            } else if (resource === "mass-media") {
                let formData = new FormData();
                formData.append('title', JSON.stringify(params.data.title));
                formData.append('slug', params.data.slug);
                formData.append('source', params.data.source);
                formData.append('image', params.data.image?.rawFile);

                return httpClient(`${apiBaseUrl}/${resource}`, {
                    method: 'POST',
                    body: formData,
                }).then(({ json }) => ({
                    data: { ...params.data, id: json.id },
                })).catch(err => {
                    return Promise.reject(new HttpError(err.body?.msg || errorMsg, err.status || 400, err.body));
                });
            } else if (resource === "slides") {
                let formData = new FormData();
                params.data.forEach(item => {
                    formData.append("slides", JSON.stringify(item));



                    if (item.imageDesktop.file) {
                        formData.append("imageDesktop", item.imageDesktop.file);
                    }
                    if (item.imageMobile.file) {
                        formData.append("imageMobile", item.imageMobile.file);
                    }

                });
                return httpClient(`${apiBaseUrl}/${resource}`, {
                    method: 'POST',
                    body: formData,
                }).then(({ json }) => ({
                    data: { ...params.data, id: json.id },
                })).catch(err => {
                    return Promise.reject(new HttpError(err.body?.msg || errorMsg, err.status || 400, err.body));
                });
            } else if (resource === "system") {
                let formData = new FormData();
                params.data.forEach(system => {
                    formData.append("system", JSON.stringify(system));

                    if (system.file) {
                        formData.append(system.name, system.file);
                    }

                });
                return httpClient(`${apiBaseUrl}/${resource}`, {
                    method: 'POST',
                    body: formData,
                }).then(({ json }) => ({
                    data: { ...params.data, id: json.id },
                })).catch(err => {
                    return Promise.reject(new HttpError(err.body?.msg || errorMsg, err.status || 400, err.body));
                });
            } else if (resource === "media") {
                let formData = new FormData();
                formData.append("title", JSON.stringify(params.data.title));

                params.data.images.forEach(image => {
                    formData.append("images", JSON.stringify(image));

                    if (image.file) {
                        formData.append("mediaImages", image.file);
                    }

                });
                return httpClient(`${apiBaseUrl}/${resource}`, {
                    method: 'POST',
                    body: formData,
                }).then(({ json }) => ({
                    data: { ...params.data, id: json.id },
                })).catch(err => {
                    return Promise.reject(new HttpError(err.body?.msg || errorMsg, err.status || 400, err.body));
                });
            } else if (resource === "player") {
                let formData = new FormData();
                formData.append('name', params.data.name);
                formData.append('playerRoleId', params.data.playerRoleId);
                formData.append('height', params.data.height);
                formData.append('weight', params.data.weight);
                formData.append('dob', params.data.dob);
                formData.append('number', params.data.number);
                formData.append('country', params.data.country);
                formData.append('clubId', params.data.clubId);
                formData.append('image', params.data.image?.rawFile);
                return httpClient(`${apiBaseUrl}/${resource}`, {
                    method: 'POST',
                    body: formData,
                }).then(({ json }) => ({
                    data: { ...params.data, id: json.id },
                })).catch(err => {
                    return Promise.reject(new HttpError(err.body?.msg || errorMsg, err.status || 400, err.body));
                });
            } else if (resource === "coach") {
                let formData = new FormData();
                formData.append('name', params.data.name);
                formData.append('clubId', params.data.clubId);
                formData.append('role', JSON.stringify(params.data.role));
                formData.append('description', JSON.stringify(params.data.description));
                formData.append('dob', params.data.dob);
                formData.append('image', params.data.image?.rawFile);
                return httpClient(`${apiBaseUrl}/${resource}`, {
                    method: 'POST',
                    body: formData,
                }).then(({ json }) => ({
                    data: { ...params.data, id: json.id },
                })).catch(err => {
                    return Promise.reject(new HttpError(err.body?.msg || errorMsg, err.status || 400, err.body));
                });
            } else if (resource === "staff") {
                let formData = new FormData();
                formData.append('name', params.data.name);
                formData.append('role', JSON.stringify(params.data.role));
                formData.append('arrivalYear', params.data.arrivalYear);
                formData.append('dob', params.data.dob);
                formData.append('category', params.data.category);
                formData.append('image', params.data.image?.rawFile);
                return httpClient(`${apiBaseUrl}/${resource}`, {
                    method: 'POST',
                    body: formData,
                }).then(({ json }) => ({
                    data: { ...params.data, id: json.id },
                })).catch(err => {
                    return Promise.reject(new HttpError(err.body?.msg || errorMsg, err.status || 400, err.body));
                });
            } else if (resource === "sponsor") {
                let formData = new FormData();
                formData.append('name', params.data.name);
                formData.append('link', params.data.link);
                formData.append('description', params.data.description ? JSON.stringify(params.data.description) : "");
                formData.append('image', params.data.image?.rawFile);
                return httpClient(`${apiBaseUrl}/${resource}`, {
                    method: 'POST',
                    body: formData,
                }).then(({ json }) => ({
                    data: { ...params.data, id: json.id },
                })).catch(err => {
                    return Promise.reject(new HttpError(err.body?.msg || errorMsg, err.status || 400, err.body));
                });
            } else if (resource === "academy") {
                let formData = new FormData();
                formData.append('title', params.data.title ? JSON.stringify(params.data.title) : "");
                formData.append('description', params.data.description ? JSON.stringify(params.data.description) : "");
                formData.append('image', params.data.image?.rawFile);
                return httpClient(`${apiBaseUrl}/${resource}`, {
                    method: 'POST',
                    body: formData,
                }).then(({ json }) => ({
                    data: { ...params.data, id: json.id },
                })).catch(err => {
                    return Promise.reject(new HttpError(err.body?.msg || errorMsg, err.status || 400, err.body));
                });
            } else if (resource === "club" || resource === "club/me") {
                let formData = new FormData();
                formData.append('name', params.data.name);
                formData.append('championshipId', params.data.championshipId);
                formData.append('logo', params.data.logo?.rawFile);
                if (params.data.image) {
                    formData.append('image', params.data.image?.rawFile);
                }
                return httpClient(`${apiBaseUrl}/${resource}`, {
                    method: 'POST',
                    body: formData,
                }).then(({ json }) => ({
                    data: { ...params.data, id: json.id },
                })).catch(err => {
                    return Promise.reject(new HttpError(err.body?.msg || errorMsg, err.status || 400, err.body));
                });
            } else {
                return dataProvider.create(resource, params);

            }
        },
        update: (resource, params) => {
            if (resource === "news") {
                let formData = new FormData();
                formData.append('newsCategoryId', params.data.newsCategoryId);
                formData.append('title', JSON.stringify(params.data.title));
                formData.append('slug', params.data.slug);
                formData.append('matchId', params.data.matchId);
                formData.append('shortDescription', JSON.stringify(params.data.shortDescription));
                formData.append('fullDescription', JSON.stringify(params.data.fullDescription));
                if (typeof params.data.previewImage === "string") {
                    formData.append('previewImage', params.data.previewImage);
                } else {
                    formData.append('previewImage', params.data.previewImage?.rawFile);
                }

                if (typeof params.data.previewImage === "string") {
                    formData.append('mainImage', params.data.mainImage);

                } else {

                    formData.append('mainImage', params.data.mainImage?.rawFile);
                }
                return httpClient(`${apiBaseUrl}/${resource}/${params.id}`, {
                    method: 'PUT',
                    body: formData,
                }).then(({ json }) => ({
                    data: { ...params.data, id: json.id },
                })).catch(err => {
                    return Promise.reject(new HttpError(err.body?.msg || errorMsg, err.status || 400, err.body));
                });
            } else if (resource === "mass-media") {
                let formData = new FormData();
                formData.append('title', JSON.stringify(params.data.title));
                formData.append('slug', params.data.slug);
                formData.append('source', params.data.matchId);
                if (typeof params.data.image === "string") {
                    formData.append('image', params.data.image);
                } else {
                    formData.append('image', params.data.image?.rawFile);
                }
                return httpClient(`${apiBaseUrl}/${resource}/${params.id}`, {
                    method: 'PUT',
                    body: formData,
                }).then(({ json }) => ({
                    data: { ...params.data, id: json.id },
                })).catch(err => {
                    return Promise.reject(new HttpError(err.body?.msg || errorMsg, err.status || 400, err.body));
                });
            }
            else if (resource === "media") {
                let formData = new FormData();
                formData.append("title", JSON.stringify(params.data.title));
                formData.append('matchId', params.data.matchId);

                params.data.images.forEach(image => {
                    formData.append("images", JSON.stringify(image));

                    if (image.file) {
                        formData.append("mediaImages", image.file);
                    }

                });
                return httpClient(`${apiBaseUrl}/${resource}/${params.data.id}`, {
                    method: 'PUT',
                    body: formData,
                }).then(({ json }) => ({
                    data: { ...params.data, id: json.id },
                })).catch(err => {
                    return Promise.reject(new HttpError(err.body?.msg || errorMsg, err.status || 400, err.body));
                });
            }
            else if (resource === "player") {
                let formData = new FormData();
                formData.append('name', params.data.name);
                formData.append('playerRoleId', params.data.playerRoleId);
                formData.append('height', params.data.height);
                formData.append('weight', params.data.weight);
                formData.append('dob', params.data.dob);
                formData.append('number', params.data.number);
                formData.append('country', params.data.country);
                formData.append('clubId', params.data.clubId);

                if (typeof params.data.image === "string") {
                    formData.append('image', params.data.image);
                } else {
                    formData.append('image', params.data.image?.rawFile);
                }

                return httpClient(`${apiBaseUrl}/${resource}/${params.data.id}`, {
                    method: 'PUT',
                    body: formData,
                }).then(({ json }) => ({
                    data: { ...params.data, id: json.id },
                })).catch(err => {
                    return Promise.reject(new HttpError(err.body?.msg || errorMsg, err.status || 400, err.body));
                });
            }
            else if (resource === "coach") {
                let formData = new FormData();
                formData.append('name', params.data.name);
                formData.append('clubId', params.data.clubId);
                formData.append('role', JSON.stringify(params.data.role));
                formData.append('description', JSON.stringify(params.data.description));
                formData.append('dob', params.data.dob);

                if (typeof params.data.image === "string") {
                    formData.append('image', params.data.image);
                } else {
                    formData.append('image', params.data.image?.rawFile);
                }

                return httpClient(`${apiBaseUrl}/${resource}/${params.data.id}`, {
                    method: 'PUT',
                    body: formData,
                }).then(({ json }) => ({
                    data: { ...params.data, id: json.id },
                })).catch(err => {
                    return Promise.reject(new HttpError(err.body?.msg || errorMsg, err.status || 400, err.body));
                });
            }
            else if (resource === "staff") {
                let formData = new FormData();
                formData.append('name', params.data.name);
                formData.append('role', JSON.stringify(params.data.role));
                formData.append('arrivalYear', params.data.arrivalYear);
                formData.append('dob', params.data.dob);
                formData.append('category', params.data.category);

                if (typeof params.data.image === "string") {
                    formData.append('image', params.data.image);
                } else {
                    formData.append('image', params.data.image?.rawFile);
                }

                return httpClient(`${apiBaseUrl}/${resource}/${params.data.id}`, {
                    method: 'PUT',
                    body: formData,
                }).then(({ json }) => ({
                    data: { ...params.data, id: json.id },
                })).catch(err => {
                    return Promise.reject(new HttpError(err.body?.msg || errorMsg, err.status || 400, err.body));
                });
            }
            else if (resource === "sponsor") {
                let formData = new FormData();
                formData.append('name', params.data.name);
                formData.append('link', params.data.link);
                formData.append('description', params.data.description ? JSON.stringify(params.data.description) : "");
                if (typeof params.data.image === "string") {
                    formData.append('image', params.data.image);
                } else {
                    formData.append('image', params.data.image?.rawFile);
                }

                return httpClient(`${apiBaseUrl}/${resource}/${params.data.id}`, {
                    method: 'PUT',
                    body: formData,
                }).then(({ json }) => ({
                    data: { ...params.data, id: json.id },
                })).catch(err => {
                    return Promise.reject(new HttpError(err.body?.msg || errorMsg, err.status || 400, err.body));
                });
            }
            else if (resource === "academy") {
                let formData = new FormData();
                formData.append('title', params.data.title ? JSON.stringify(params.data.title) : "");
                formData.append('description', params.data.description ? JSON.stringify(params.data.description) : "");

                if (typeof params.data.image === "string") {
                    formData.append('image', params.data.image);
                } else {
                    formData.append('image', params.data.image?.rawFile);
                }

                return httpClient(`${apiBaseUrl}/${resource}/${params.data.id}`, {
                    method: 'PUT',
                    body: formData,
                }).then(({ json }) => ({
                    data: { ...params.data, id: json.id },
                })).catch(err => {
                    return Promise.reject(new HttpError(err.body?.msg || errorMsg, err.status || 400, err.body));
                });
            }
            else if (resource === "club" || resource === "club/me") {
                let formData = new FormData();
                formData.append('name', params.data.name);
                formData.append('championshipId', params.data.championshipId);
                if (params.data.club_results?.length > 0) {
                    for (let i = 0; i < params.data.club_results.length; i++) {
                        formData.append('club_results', JSON.stringify(params.data.club_results[i]));
                    }
                }
                if (typeof params.data.logo === "string") {
                    formData.append('logo', params.data.logo);
                } else {
                    formData.append('logo', params.data.logo?.rawFile);
                }
                if (params.data.image) {
                    if (typeof params.data.image === "string") {
                        formData.append('image', params.data.image);
                    } else {
                        formData.append('image', params.data.image?.rawFile);
                    }
                }

                return httpClient(`${apiBaseUrl}/${resource}/${params.data.id}`, {
                    method: 'PUT',
                    body: formData,
                }).then(({ json }) => ({
                    data: { ...params.data, id: json.id },
                })).catch(err => {
                    return Promise.reject(new HttpError(err.body?.msg || errorMsg, err.status || 400, err.body));
                });
            }
            else {
                return dataProvider.update(resource, params);

            }
        }
    };
};


export default myDataProvider;
