import CategoryIcon from "@mui/icons-material/Category";

import NewsCategoryList from "./NewsCategoryList";
import NewsCategoryCreate from "./NewsCategoryCreate";
import NewsCategoryEdit from "./NewsCategoryEdit";

const resource = {
  list: NewsCategoryList,
  create: NewsCategoryCreate,
  edit: NewsCategoryEdit,
  icon: CategoryIcon,
};

export default resource;
