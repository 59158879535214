
import {
  Edit,
  SimpleForm,
  TextInput,
} from "react-admin";
export default function NewsEdit() {
  return (
    <Edit>
      <SimpleForm>
        <TextInput
          source="name"
          label="Полное имя"
        />
      </SimpleForm>
    </Edit>
  );
}
