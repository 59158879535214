import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import EastIcon from "@mui/icons-material/East";

import Match from "../../common/match/Match";

import classes from "./styles.module.css";
import { useMediaQuery } from "@mui/material";
import MoreButton from "../../common/MoreButton";
import Title from "../../common/Title";
import MainBlock from "../MainBlock";
import MainItems from "../MainItems";
import { SwiperSlide } from "swiper/react";

export default function Matches({ matches }) {
  const { t } = useTranslation();
  const isLarge = useMediaQuery((theme) => theme.breakpoints.up("576"), {
    defaultMatches: true,
    noSsr: false,
  });
  return (
    <MainBlock>
      <div className={classes.matchesTitle}>
        <Title text={t('Matches')} style={{ padding: 0 }} page="main" />
        {isLarge && (
          <Link to="/matches">
            {t("Calendar")} <EastIcon />
          </Link>
        )}
      </div>
      <MainItems>
        {matches.map((match) => (
          <SwiperSlide key={match.id}>
            <Match match={match} key={match.id} page="main" />
          </SwiperSlide>
        ))}
      </MainItems>

      {!isLarge && (
        <MoreButton link="/matches" text={t("More matches")} />
      )}
    </MainBlock>
  );
}
