import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
} from "react-admin";

export default function UserList() {
  return (
    <List>
      <Datagrid rowClick="edit">
        <TextField source="id" label="id" />
        <TextField source="name" label="Название" />
        <ReferenceField source="championshipId" reference="championship" label="Чемпионат">
          <TextField source="name.ru" label="Чемпионат"/>
        </ReferenceField>
      </Datagrid>
    </List>
  );
}
