import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';


import { UserRoleList } from './UserRoleList';
import UserRoleCreate from './UserRoleCreate';
import UserRoleEdit from './UserRoleEdit';

const resource = {
    list: UserRoleList,
    create: UserRoleCreate,
    edit: UserRoleEdit,
    icon: AdminPanelSettingsIcon,
};

export default resource;