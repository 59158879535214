import * as React from "react";
import {
  List,
  Datagrid,
  TextField
} from "react-admin";

export default function UserList() {
  return (
    <List>
      <Datagrid rowClick="edit">
        <TextField source="id" label="id" />
        <TextField source="name.ru" label="Имя" />
        <TextField source="slug" label="Ссылка" />
      </Datagrid>
    </List>
  );
}
