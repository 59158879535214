
import { DateInput, Edit, ImageField, ImageInput, ReferenceInput, SelectInput, SimpleForm, TextInput, TranslatableInputs } from 'react-admin'
import { imageBaseUrl } from 'src/utils/endpoints';
import Editor from '../common/Editor'

export default function ChampionshipEdit() {

    return (
        <Edit>
            <SimpleForm>
                <TextInput source="name" label="Полное имя" />
                <ReferenceInput source="clubId" reference="club/me">
                    <SelectInput optionText="name" required label="Клуб" />
                </ReferenceInput>
                <TranslatableInputs locales={['ru', 'uz', 'en']} defaultLocale="ru">
                    <TextInput source="role" label="роль" />
                </TranslatableInputs>
                <p style={{ marginTop: "15px" }}>Описание</p>
                <TranslatableInputs locales={['ru', 'uz', 'en']} defaultLocale="ru">
                    <Editor source="description" label="Описание" />
                </TranslatableInputs>
                <DateInput source="dob" label="Дата рождения" />
                <ImageInput source="image" label="Фото тренера" accept="image/*" placeholder={<p>Нажмите сюда или перетащите картинку</p>}
                    format={(value) => {
                        if (typeof value === "string") {
                            return { src: imageBaseUrl + value };
                        }
                        return value;
                    }}>
                    <ImageField source="src" title="avatar" />
                </ImageInput>
            </SimpleForm>
        </Edit>
    )
}
