import { useState, useEffect } from "react";
import { Box, Button, Card, CardContent, CardHeader, Divider, TextField } from "@mui/material";
import simpleSvgPlaceholder from "@cloudfour/simple-svg-placeholder";


import classes from "./styles.module.css";

import { imageBaseUrl } from "src/utils/endpoints";
import { Title, useDataProvider, useNotify } from "react-admin";

const SystemForm = () => {
    const dataProvider = useDataProvider();
    const notify = useNotify();

    const [system, setSystem] = useState([]);
    useEffect(() => {
        async function fetchAll() {

            const { data: tempSystem } = await dataProvider.getList("system", {
                pagination: { page: 1, perPage: 100 },
                sort: { field: 'id', order: 'ASC' }
            });
            setSystem(tempSystem);

        }

        fetchAll();
    }, [dataProvider]);
    const placeholder = simpleSvgPlaceholder({ width: 200, height: 200 });
    async function handleFormSubmit(e) {
        e.preventDefault()
        await dataProvider.create('system', { data: system })
        notify(`Successfully updated`, { type: 'info' });

    }
    const handleImageChange = (e, index) => {
        const tempSystem = [...system];
        tempSystem[index].file = e.target.files[0];

        tempSystem[index].value = URL.createObjectURL(e.target.files[0]);
        setSystem(tempSystem);
    };

    return (
        <form onSubmit={handleFormSubmit} encType="multipart/form-data">
            <Title title="Система" />
            <Card>
                <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "space-between", padding: "15px" }}>
                    <CardHeader subheader={"Редактировать систему"} title="Система" />
                    <Button color="primary" variant="contained" type="submit">
                        Обновить
                    </Button>

                </Box>
                <Divider />
                <CardContent>
                    {system?.map((sys, index) => {
                        return (
                            <Box key={sys.id}>
                                {sys.type === "text" ? (
                                    <TextField
                                        fullWidth
                                        label={sys.label}
                                        margin="normal"
                                        name={sys.name}
                                        onChange={(e) => {
                                            const tempSystem = [...system];
                                            tempSystem[index].value = e.target.value;
                                            setSystem(tempSystem);
                                        }}
                                        value={sys.value || ""}
                                        variant="outlined"
                                        inputProps={{}}
                                    />
                                ) : sys.type === "image" ? (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-start",
                                            width: "fit-content",
                                            padding: "20px",
                                            borderRadius: "8px",
                                            marginBottom: "20px",
                                            maxWidth: "400px",
                                        }}
                                    >
                                        <Button variant="contained" component="label" sx={{ marginBottom: "20px" }}>
                                            {sys.label}
                                            <input
                                                type="file"
                                                name={sys.name}
                                                hidden
                                                onChange={(e) => {
                                                    handleImageChange(e, index);
                                                }}
                                            />
                                        </Button>
                                        <img
                                            src={sys.file ? sys.value : sys.value ? imageBaseUrl + sys.value : ""}
                                            alt="preview"
                                            onError={({ currentTarget }) => {
                                                currentTarget.src = placeholder;
                                            }}
                                            className={classes.imagePreview}
                                        />
                                    </Box>
                                ) : (
                                    ""
                                )}
                            </Box>
                        );
                    })}
                </CardContent>

            </Card>
        </form>
    );
};

export default SystemForm;