
import { Edit, ImageField, ImageInput, SimpleForm, TextInput, TranslatableInputs } from 'react-admin'
import { imageBaseUrl } from 'src/utils/endpoints';

export default function MassMediaEdit() {

    return (
        <Edit>
            <SimpleForm>
                <TranslatableInputs locales={['ru', 'uz', 'en']} defaultLocale="ru">
                    <TextInput source="title" label="Заголовок" />
                </TranslatableInputs>
                <TextInput source="source" label="Источник (Instagram, Facebook, ...)" />
                <TextInput source="slug" label="Ссылка" />
                <ImageInput
                    source="image"
                    label="Картинка"
                    accept="image/*"
                    placeholder={<p>Нажмите сюда или перетащите картинку</p>}
                    format={(value) => {
                        if (typeof value === "string") {
                          return { src: imageBaseUrl + value };
                        }
                        return value;
                      }}
                >
                    <ImageField source="src" title="title" />
                </ImageInput>
            </SimpleForm>
        </Edit>
    )
}
