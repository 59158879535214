import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';

import MatchList from "./MatchList";
import MatchEdit from "./MatchEdit";
import MatchCreate from "./MatchCreate";

const resource = {
  list: MatchList,
  create: MatchCreate,
  edit: MatchEdit,
  icon: SportsSoccerIcon,
};

export default resource;
