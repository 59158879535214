import React from "react";
import { useContext } from "react";
import { Helmet } from "react-helmet";
import BodyContainer from "src/Container/BodyContainer";
import SystemContext from "src/context/systemContext";
import Layout from "src/layout/site";

export default function NotFound() {
  const { system } = useContext(SystemContext)
  return (
    <Layout>
      <Helmet>
        <title key="title">{`Не найдено – ${system?.find(s => s.name === "siteName")?.value}`}</title>
      </Helmet>
      <BodyContainer>
        <div
          style={{
            color: "#061552",
            fontSize: "30px",
            textAlign: "center",
            padding: "100px",
            fontWeight: 600,
          }}
        >
          404 - Страница не найдена
        </div>

      </BodyContainer>
    </Layout>
  );
}
