import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { imageBaseUrl } from 'src/utils/endpoints'
import CameraImage from 'src/assets/icons/camera.png'
import classes from './styles.module.css'
import { useContext } from 'react';
import SystemContext from 'src/context/systemContext';

export default function SingleNewsBlock({ data, type }) {
    const { i18n } = useTranslation();
    const { system } = useContext(SystemContext)
    return (
        <div className={classes.singleNews}>
            <img src={imageBaseUrl + (type === "media" ? data.images[0] : data.previewImage)} alt={"preview"}
                onError={({ currentTarget }) => {
                    currentTarget.src = imageBaseUrl + system.find(s => s.name === "imagePlaceholder")?.value;
                }} />
            <div className={classes.singleNewsContent}>
                {type === "media" &&
                    <div className={classes.imageCount}>
                        <img src={CameraImage} alt="" />
                        <span>{data.images.length}</span>
                    </div>}
                <div className={classes.singleNewsContentInner}>
                    <p>{data.title[i18n.language]}</p>
                    {type !== "media" &&
                        <p className={classes.shortDescription}>{data.shortDescription[i18n.language]}</p>
                    }
                    <span className={classes.date}>{format(new Date(data.createdAt.replace(/-/g, '/')), 'dd.MM.yyyy')}</span>
                </div>
            </div>
        </div>
    )
}
