import React from "react";
import MediaItem from "./MediaItem";

import classes from "./styles.module.css";

export default function AllMedia({ media }) {
  return (
    <div className={classes.news}>
      {media.map((singleMedia) => (
        <MediaItem data={singleMedia} key={singleMedia.id} />
      ))}
    </div>
  );
}
