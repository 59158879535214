import { useMediaQuery } from "@mui/material";
import { format } from "date-fns";
import { enGB, ru, uz } from "date-fns/locale";
import nProgress from "nprogress";
import { useContext } from "react";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import MatchTabs from "src/components/site/match/MatchTabs";
import BodyContainer from "src/Container/BodyContainer";
import SystemContext from "src/context/systemContext";

import "src/global/common.css";
import Layout from "src/layout/site";
import { getMatch } from "src/services/matches";
import { getWeather } from "src/services/weather";
import { imageBaseUrl } from "src/utils/endpoints";

import classes from "./styles.module.css";

export default function MatchPage({ props }) {
  const { system } = useContext(SystemContext)
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up("md"), {
    defaultMatches: true,
    noSsr: false,
  });
  const smUp = useMediaQuery((theme) => theme.breakpoints.up("sm"), {
    defaultMatches: true,
    noSsr: false,
  });
  const isSmall = useMediaQuery((theme) => theme.breakpoints.up("576"), {
    defaultMatches: true,
    noSsr: false,
  });
  const { i18n } = useTranslation();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [match, setMatch] = useState();
  const [weather, setWeather] = useState();
  useEffect(() => {
    async function fetchAll() {
      nProgress.start();
      const tempMatch = await getMatch(id);
      setMatch(tempMatch);
      nProgress.done();
      setLoading(false);
    }
    fetchAll();
  }, [id]);
  useEffect(() => {
    async function fetchAll() {
      if (match) {
        let tempWeather = await getWeather({ city: match.city });
        let result;
        let date = new Date(match.date.replace(/-/g, '/')).getTime();
        result = tempWeather.list.find((w, i) => (w.dt * 1000) >= date && (tempWeather.list[i - 1]?.dt * 1000) <= date)
        setWeather(result)
      }
    }
    fetchAll();
  }, [match])
  return (
    <Layout loading={loading}>
      <Helmet>
        <title key="title">{`Матч – ${system?.find(s => s.name === "siteName")?.value}`}</title>
      </Helmet>
      {!loading && (
        <BodyContainer>
          <div className={classes.matchTop}>
            <h1 className={classes.matchTitle}>
              {match.championship?.name?.[i18n.language]} |{" "}
              {match.name?.[i18n.language]} | {match.city} "{match.stadium}"
            </h1>
            <p className={classes.date}>
              {match.date &&
                format(new Date(match.date.replace(/-/g, '/')), "d LLLL HH:mm", {
                  locale:
                    i18n.language === "ru"
                      ? ru
                      : i18n.language === "uz"
                        ? uz
                        : enGB,
                })}
            </p>
            <div className={classes.clubs}>
              <div className={classes.logos}>
                <div className={classes.logo}>
                  {match.isHome ? (
                    <> <img
                      src={imageBaseUrl + match?.myClub?.logo}
                      alt="club logo"
                      width={mdUp ? 200 : smUp ? 150 : isSmall ? 100 : 56}
                      onError={({ currentTarget }) => {
                        currentTarget.src = imageBaseUrl + system.find(s => s.name === "imagePlaceholder")?.value;
                      }}
                    />
                      <span>
                        {match.myClub.name}
                      </span>
                    </>
                  ) : (
                    <>
                      <img
                        src={imageBaseUrl + match?.club?.logo}
                        alt="club logo"
                        width={mdUp ? 200 : smUp ? 150 : isSmall ? 100 : 56}
                        onError={({ currentTarget }) => {
                          currentTarget.src = imageBaseUrl + system.find(s => s.name === "imagePlaceholder")?.value;
                        }}
                      />
                      <span>{match.club.name}</span>
                    </>
                  )}

                </div>
                <span>
                  {
                    match.isHome ? (
                      <> {match?.goals?.filter((g) => g.playerId).length}:
                        {match?.goals?.filter((g) => g.playerName).length}
                      </>
                    ) : (
                      <>
                        {match?.goals?.filter((g) => g.playerName).length}:
                        {match?.goals?.filter((g) => g.playerId).length}
                      </>
                    )
                  }

                </span>
                <div className={classes.logo}>
                  {!match.isHome ? (
                    <> <img
                      src={imageBaseUrl + match?.myClub?.logo}
                      alt="club logo"
                      width={mdUp ? 200 : smUp ? 150 : isSmall ? 100 : 56}
                      onError={({ currentTarget }) => {
                        currentTarget.src = imageBaseUrl + system.find(s => s.name === "imagePlaceholder")?.value;
                      }}
                    />
                      <span>
                        {match.myClub.name}
                      </span>
                    </>
                  ) : (
                    <>
                      <img
                        src={imageBaseUrl + match?.club?.logo}
                        alt="club logo"
                        width={mdUp ? 200 : smUp ? 150 : isSmall ? 100 : 56}
                        onError={({ currentTarget }) => {
                          currentTarget.src = imageBaseUrl + system.find(s => s.name === "imagePlaceholder")?.value;
                        }}
                      />
                      <span>{match.club.name}</span>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <MatchTabs match={match} weather={weather} />
        </BodyContainer>
      )}
    </Layout>
  );
}
