import nProgress from "nprogress";
import { useContext } from "react";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import MoreButton from "src/components/site/common/MoreButton";
import Title from "src/components/site/common/Title";
import AllNews from "src/components/site/news/AllNews";
import BodyContainer from "src/Container/BodyContainer";
import SystemContext from "src/context/systemContext";


import Layout from "src/layout/site";
import { getNews } from "src/services/news";


export default function NewsPage() {
  const { system } = useContext(SystemContext)
  const { t } = useTranslation();
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchAll() {
      nProgress.start();
      const tempNews = await getNews({ limit: 7 });
      setNews(tempNews);
      setLoading(false);
      nProgress.done();
    }
    fetchAll();
  }, []);
  async function handleClick() {
    nProgress.start();
    let currentNews = [...news];
    const tempNews = await getNews({ offset: currentNews.length, limit: 6 });
    currentNews = currentNews.concat(tempNews);
    setNews(currentNews);
    nProgress.done();
  }
  return (
    <Layout loading={loading}>
      <Helmet>
        <title key="title">{`Новости – ${system?.find(s => s.name === "siteName")?.value}`}</title>
      </Helmet>
      <BodyContainer>
        <Title text={t("News")} />
        <AllNews news={news} />
        <MoreButton text={t("More news")} onClick={handleClick} />
      </BodyContainer>
    </Layout>
  );
}
