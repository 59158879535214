import nProgress from "nprogress";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Club from "src/components/site/clubs/Club";
import Title from "src/components/site/common/Title";
import BodyContainer from "src/Container/BodyContainer";

import "src/global/common.css";
import Layout from "src/layout/site";
import { getClub } from "src/services/club";
import { imageBaseUrl } from "src/utils/endpoints";


export default function U21Page() {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(true);
    const [club, setClub] = useState();

    useEffect(() => {
        async function fetchAll() {
            nProgress.start();
            const tempClub = await getClub(2);
            setClub(tempClub)
            setLoading(false);
            nProgress.done();
        }
        fetchAll();
    }, []);
    return (
        <Layout loading={loading}>
            <BodyContainer>
                <Club club={club} title={"U-21"}/>
            </BodyContainer>
        </Layout>
    );
}
