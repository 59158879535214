import { createTheme } from "@mui/material";
let theme = createTheme({
    breakpoints: {
        values: {
            xs: 320,
            500: 500,
            576: 576,
            sm: 768.5,
            md: 992,
            lg: 1200,
            1550: 1550,
            1450: 1450,
            xl: 1920,
        },
    },
});
theme = createTheme(theme, {
    components: {
        MuiAccordion: {
            styleOverrides: {
                root: {
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    "&:last-of-type": {
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,
                    },
                    "&.Mui-expanded": {
                        margin: 0,
                    },
                },
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                content: {
                    paddingTop: "9px",
                    margin: 0,
                    flexGrow: 0,
                    "&.Mui-expanded": {
                        margin: 0,
                    },
                },
                contentGutters: {
                    margin: 0,
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                listbox: {
                    border: "none",
                },
                popper: {
                    borderWidth: "5px",
                    borderColor: "black",
                },
            },
        },
        MuiButtonBase: {
            // The properties to apply
            disableRipple: true, // No more ripple, on the whole application 💣!
        },
        MuiButton: {
            disableRipple: true,
            defaultProps: {
                disableElevation: true,
                disableRipple: true,
            },
            styleOverrides: {
                root: {
                    textTransform: "none",
                    padding: 0,
                    minWidth: "auto",
                    "&:hover": {
                        // background: "initial",
                    },
                },
            },
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: "32px 24px",
                    "&:last-child": {
                        paddingBottom: "32px",
                    },
                },
            },
        },
        MuiCardHeader: {
            defaultProps: {
                titleTypographyProps: {
                    variant: "h6",
                },
                subheaderTypographyProps: {
                    variant: "body2",
                },
            },
            styleOverrides: {
                root: {
                    padding: "32px 24px",
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: {
                "*": {
                    boxSizing: "border-box",
                    margin: 0,
                    padding: 0,
                },
                html: {
                    MozOsxFontSmoothing: "grayscale",
                    WebkitFontSmoothing: "antialiased",
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "100%",
                    width: "100%",
                },
                body: {
                    display: "flex",
                    flex: "1 1 auto",
                    flexDirection: "column",
                    minHeight: "100%",
                    width: "100%",
                },
                ul: {
                    listStyle: "none",
                },
                "#__next": {
                    display: "flex",
                    flex: "1 1 auto",
                    flexDirection: "column",
                    height: "100%",
                    width: "100%",
                    background: "white",
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                },
            },
        },
        MuiList: {
            styleOverrides: {
                root: {
                    padding: 0,
                },
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {

            },
        },
        MuiTextField: {
            styleOverrides: {
            },
        },
        MuiPagination: {
            styleOverrides: {},
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    padding: 0,
                    paddingRight: "7px",
                    minHeight: "auto"
                },
                standard: {
                    // paddingRight: "7px!important"
                },
            },
        },
        MuiSwitch: {
            styleOverrides: {
                track: {
                    background: "black",
                },
            },
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    backgroundColor: "#F3F4F6",
                    ".MuiTableCell-root": {
                        color: "#374151",
                    },
                    borderBottom: "none",
                    "& .MuiTableCell-root": {
                        borderBottom: "none",
                        fontSize: "12px",
                        fontWeight: 600,
                        lineHeight: 1,
                        letterSpacing: 0.5,
                        textTransform: "uppercase",
                    },
                    "& .MuiTableCell-paddingCheckbox": {
                        paddingTop: 4,
                        paddingBottom: 4,
                    },
                },
            },
        },
    },
    palette: {
        mode: "light",
        order: {
            process: "#F2BD35",
            waitingPayment: "#d2d4cb",
            paid: "#b0e807",
            sent: "#07bbe8",
            completed: "#07e82c",
            canceled: "#e8071a",
        },
        neutral: {
            black: "#181818",
            inactive: "#AFAFAF",
            100: "#F3F4F6",
            200: "#E5E7EB",
            300: "#D1D5DB",
            400: "#9CA3AF",
            500: "#6B7280",
            600: "#4B5563",
            700: "#374151",
            800: "#1F2937",
            900: "#111827",
        },
        black: {
            active: "#000",
            focus: "rgba(55, 65, 81, 0.12)",
            hover: "rgba(55, 65, 81, 0.04)",
            selected: "rgba(55, 65, 81, 0.08)",
            disabledBackground: "rgb(0, 0, 0)",
            disabled: "rgb(0, 0, 0)",
        },
        action: {
            active: "#6B7280",
            focus: "rgba(55, 65, 81, 0.12)",
            hover: "rgba(55, 65, 81, 0.04)",
            selected: "rgba(55, 65, 81, 0.08)",
            disabledBackground: "rgba(55, 65, 81, 0.12)",
            disabled: "rgba(55, 65, 81, 0.26)",
        },
        background: {
            default: "#061552",
            paper: "#FFFFFF",
            blue: "#061552",
            black: "#000",
            whitish: "#FAFAFA",
            darkBlue: "#010827"
        },
        divider: "#E6E8F0",
        primary: {
            main: "#5048E5",
            light: "#828DF8",
            dark: "#3832A0",
            contrastText: "#FFFFFF",
        },
        secondary: {
            main: "#10B981",
            light: "#3FC79A",
            dark: "#0B815A",
            contrastText: "#FFFFFF",
        },
        success: {
            main: "#14B8A6",
            light: "#43C6B7",
            dark: "#0E8074",
            contrastText: "#FFFFFF",
        },
        info: {
            main: "#2196F3",
            light: "#64B6F7",
            dark: "#0B79D0",
            contrastText: "#FFFFFF",
        },
        warning: {
            main: "#FFB020",
            light: "#FFBF4C",
            dark: "#B27B16",
            contrastText: "#FFFFFF",
        },
        error: {
            main: "#D14343",
            light: "#DA6868",
            dark: "#922E2E",
            contrastText: "#FFFFFF",
        },
        text: {
            primary: "#121828",
            secondary: "#65748B",
            disabled: "rgba(55, 65, 81, 0.48)",
        },
        red: {
            main: "red",
            light: "red",
            dark: "red",
            contrastText: "#FFFFFF",
        },
        blackRadio: {
            main: "#181818",
            light: "#181818",
            dark: "#181818",
            contrastText: "#181818",
        },
        white: {
            main: "#fff",
            light: "#fff",
            dark: "#fff",
            contrastText: "#181818",
        },
    },
    shape: {
        borderRadius: 8,
    },
    shadows: [
        "none",
        "0px 1px 1px rgba(100, 116, 139, 0.06), 0px 1px 2px rgba(100, 116, 139, 0.1)",
        "0px 1px 2px rgba(100, 116, 139, 0.12)",
        "0px 1px 4px rgba(100, 116, 139, 0.12)",
        "0px 1px 5px rgba(100, 116, 139, 0.12)",
        "0px 1px 6px rgba(100, 116, 139, 0.12)",
        "0px 2px 6px rgba(100, 116, 139, 0.12)",
        "0px 3px 6px rgba(100, 116, 139, 0.12)",
        "0px 2px 4px rgba(31, 41, 55, 0.06), 0px 4px 6px rgba(100, 116, 139, 0.12)",
        "0px 5px 12px rgba(100, 116, 139, 0.12)",
        "0px 5px 14px rgba(100, 116, 139, 0.12)",
        "0px 5px 15px rgba(100, 116, 139, 0.12)",
        "0px 6px 15px rgba(100, 116, 139, 0.12)",
        "0px 7px 15px rgba(100, 116, 139, 0.12)",
        "0px 8px 15px rgba(100, 116, 139, 0.12)",
        "0px 9px 15px rgba(100, 116, 139, 0.12)",
        "0px 10px 15px rgba(100, 116, 139, 0.12)",
        "0px 12px 22px -8px rgba(100, 116, 139, 0.25)",
        "0px 13px 22px -8px rgba(100, 116, 139, 0.25)",
        "0px 14px 24px -8px rgba(100, 116, 139, 0.25)",
        "0px 10px 10px rgba(31, 41, 55, 0.04), 0px 20px 25px rgba(31, 41, 55, 0.1)",
        "0px 25px 50px rgba(100, 116, 139, 0.25)",
        "0px 25px 50px rgba(100, 116, 139, 0.25)",
        "0px 25px 50px rgba(100, 116, 139, 0.25)",
        "0px 25px 50px rgba(100, 116, 139, 0.25)",
    ],
    typography: {
        span: {
            fontSize: "11.1438px",
            lineHeight: "17px",
            [theme.breakpoints.up("sm")]: {
                fontSize: "17px",
                lineHeight: "24px",
            },
        },
        // button: {
        //     fontWeight: 600,
        // },
        fontFamily: "Inter",
        body1: {
            fontSize: "1rem",
            fontWeight: 400,
            lineHeight: 1.5,
        },
        body2: {
            fontSize: "0.875rem",
            fontWeight: 400,
            lineHeight: 1.57,
        },
        subtitle1: {
            fontSize: "1rem",
            fontWeight: 500,
            lineHeight: 1.75,
        },
        subtitle2: {
            fontSize: "0.875rem",
            fontWeight: 500,
            lineHeight: 1.57,
        },
        overline: {
            fontSize: "0.75rem",
            fontWeight: 600,
            letterSpacing: "0.5px",
            lineHeight: 2.5,
            textTransform: "uppercase",
        },
        caption: {
            fontSize: "0.75rem",
            fontWeight: 400,
            lineHeight: 1.66,
        },
        h1: {
            fontWeight: 700,
            fontSize: "3.5rem",
            lineHeight: 1.375,
        },
        h2: {
            fontWeight: 700,
            fontSize: "3rem",
            lineHeight: 1.375,
        },
        h3: {
            fontWeight: 700,
            lineHeight: "40px",
            fontSize: "18px",
            color: "#181818",
        },
        h4: {
            fontWeight: 700,
            fontSize: "2rem",
            lineHeight: 1.375,
        },
        h5: {
            fontWeight: 600,
            fontSize: "1.5rem",
            lineHeight: 1.375,
        },
        h6: {
            fontWeight: 600,
            fontSize: "1.125rem",
            lineHeight: 1.375,
        },
    },
});

export { theme };