import nProgress from "nprogress";
import { useContext } from "react";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Title from "src/components/site/common/Title";
import BodyContainer from "src/Container/BodyContainer";
import SystemContext from "src/context/systemContext";

import "src/global/common.css";
import Layout from "src/layout/site";
import { getAbout } from "src/services/about";

import classes from './styles.module.css'

export default function AboutPage() {
  const { t, i18n } = useTranslation()
  const { system } = useContext(SystemContext)
  const [about, setAbout] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchAll() {
      nProgress.start();
      const tempAbout = await getAbout();
      setAbout(tempAbout);
      setLoading(false);
      nProgress.done();
    }
    fetchAll();
  }, []);

  return (
    <Layout loading={loading}>
      <Helmet>
        <title key="title">{`О нас – ${system?.find(s => s.name === "siteName")?.value}`}</title>
      </Helmet>
      <BodyContainer>
        <Title text={t("About us")} />
        <div className={`${classes.about} editorContent`} dangerouslySetInnerHTML={{ __html: about?.[0].description[i18n.language] }}></div>
      </BodyContainer>
    </Layout>
  );
}
