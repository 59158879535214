
import React from 'react'
import { Create, SimpleForm, TextInput, TranslatableInputs } from 'react-admin'

export default function ChampionshipCreate(props) {

    return (
        <Create {...props}>
            <SimpleForm>
                <TranslatableInputs locales={['ru', 'uz', 'en']} defaultLocale="ru">
                    <TextInput source="name" label="Название" />
                </TranslatableInputs>
            </SimpleForm>
        </Create>
    )
}
