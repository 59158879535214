import React from 'react';

import classes from './styles.module.css'

const CustomPagination = ({ pagRef }) => {
    return (
        <div ref={pagRef} className={classes.pagination}>

        </div>
    );
}

export default CustomPagination;
