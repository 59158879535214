
import { Route, Routes, useLocation } from 'react-router-dom';
import { Box, CssBaseline, Skeleton, ThemeProvider } from '@mui/material';

import SystemContext from "src/context/systemContext";
import LangContext from "src/context/langContext";



import { theme } from "src/theme/site";

import "src/utils/i18n";


import "src/global/fonts.css";
import { useEffect, useState } from 'react';
import nprogress from 'nprogress';

import 'nprogress/nprogress.css';
import 'src/global/common.css';
import 'src/global/editor.css';
import 'src/global/nprogress.css';

import { setLanguage } from './utils/language';
import AboutPage from 'src/pages/AboutPage';
import MainPage from './pages/MainPage';

import { getSystem } from './services/system';
import Container from './Container/Container';
import AdminPage from './pages/admin/AdminPage';
import MatchesPage from './pages/MatchesPage';
import MatchPage from './pages/MatchPage';
import NotFound from './pages/NotFound';
import NewsPage from './pages/NewsPage';
import MediaPage from './pages/MediaPage';
import NewsSinglePage from './pages/NewsSinglePage';
import MediaSinglePage from './pages/MediaSinglePage';
import PlayersPage from './pages/PlayersPage';
import SinglePlayerPage from './pages/SinglePlayerPage';
import CoachPage from './pages/CoachPage';
import StaffPage from './pages/StaffPage';
import AcademyPage from './pages/AcademyPage';
import SponsorsPage from './pages/SponsorsPage';
import StatisticsPage from './pages/StatisticsPage';
import ErrorBoundary from './ErrorBoundary';
import TournamentsPage from './pages/TournamentsPage';
import SocialContext from './context/socialContext';
import { getSocial } from './services/social';
import SponsorsContext from './context/sponsorsContext';
import { getSponsors } from './services/sponsor';
import Seo from './components/common/Seo';
import { imageBaseUrl } from './utils/endpoints';
import { Helmet } from 'react-helmet';
import MassMediaPage from './pages/MassMediaPage';
import AppLoading from './components/common/AppLoading';
import WomensFotballPage from './pages/WomensFotball';
import U21Page from './pages/U21Page';
import U19Page from './pages/U19Page';



const App = () => {
    nprogress.configure({ showSpinner: false });
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [social, setSocial] = useState();
    const [system, setSystem] = useState([]);
    const [sponsors, setSponsors] = useState([]);
    const [isAdmin, setIsAdmin] = useState(location.pathname.startsWith("/admin"));
    const [lang, setLang] = useState();
    const systemValue = { system, setSystem };
    const socialValue = { social, setSocial };
    const sponsorsValue = { sponsors, setSponsors };
    const langValue = { lang, setLang };

    useEffect(() => {
        if (location.pathname.startsWith("/admin")) {
            setIsAdmin(true);
        }

        try {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        } catch (error) {
            // for older browser
            window.scrollTo(0, 0);
        }

    }, [location]);

    useEffect(() => {
        async function fetchAll() {
            nprogress.start();
            let tempSystem = await getSystem();
            let tempSponsors = await getSponsors();
            const tempLang = localStorage.getItem("lang") || "ru";
            setLang(tempLang);
            setSystem(tempSystem);
            setSponsors(tempSponsors);
            setLoading(false);

            nprogress.done();

            let tempSocial = await getSocial();
            setSocial(tempSocial || []);
        }
        if (!isAdmin) {
            fetchAll();

        } else {
            setLoading(false);

        }
    }, [isAdmin]);

    useEffect(() => {
        async function fetchAll() {
            document.documentElement.lang = lang;
            await setLanguage(lang);
        }
        if (lang) {
            fetchAll();
        }
    }, [lang]);

    if (loading) {
        return (
            <AppLoading />

        );
    }


    if (isAdmin) {
        return (
            <LangContext.Provider value={langValue}>
                <SystemContext.Provider value={systemValue}>
                    <SocialContext.Provider value={socialValue}>
                        <SponsorsContext.Provider value={sponsorsValue}>
                            <Helmet>
                                <meta name="robots" content="noindex,nofollow" />
                            </Helmet>
                            <Routes >
                                <Route path="/admin/*" element={<AdminPage />} />
                                <Route path="/" element={<MainPage />} />
                                <Route path="/news" element={<NewsPage />} />
                                <Route path="/news/:slug" element={<NewsSinglePage />} />
                                <Route path="/matches" element={<MatchesPage />} />
                                <Route path="/matches/:id" element={<MatchPage />} />
                                <Route path="/media" element={<MediaPage />} />
                                <Route path="/media/:id" element={<MediaSinglePage />} />
                                <Route path="/players" element={<PlayersPage />} />
                                <Route path="/players/:id" element={<SinglePlayerPage />} />
                                <Route path="/coaches" element={<CoachPage />} />
                                <Route path="/staff" element={<StaffPage />} />
                                <Route path="/academy" element={<AcademyPage />} />
                                <Route path="/sponsors" element={<SponsorsPage />} />
                                <Route path="/about" element={<AboutPage />} />
                                <Route path="/statistics" element={<StatisticsPage />} />
                                <Route path="/mass-media" element={<MassMediaPage />} />
                                <Route path="/womens-football" element={<WomensFotballPage />} />
                                <Route path='*' element={<NotFound />} />
                            </Routes>
                        </SponsorsContext.Provider>
                    </SocialContext.Provider>
                </SystemContext.Provider>
            </LangContext.Provider>
        );
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Seo title={""} siteName={system?.find(s => s.name === "siteName")?.value} lang={lang} icon={system?.find(s => s.name === "favicon")?.value ? imageBaseUrl + system?.find(s => s.name === "favicon")?.value : ""} />
            <LangContext.Provider value={langValue}>
                <SystemContext.Provider value={systemValue}>
                    <SocialContext.Provider value={socialValue}>
                        <SponsorsContext.Provider value={sponsorsValue}>
                            <Container>
                                <ErrorBoundary>
                                    <Routes>
                                        <Route path="/admin/*" element={<AdminPage />} />
                                        <Route path="/" element={<MainPage />} />
                                        <Route path="/news" element={<NewsPage />} />
                                        <Route path="/news/:slug" element={<NewsSinglePage />} />
                                        <Route path="/matches" element={<MatchesPage />} />
                                        <Route path="/matches/:id" element={<MatchPage />} />
                                        <Route path="/media" element={<MediaPage />} />
                                        <Route path="/media/:id" element={<MediaSinglePage />} />
                                        <Route path="/players" element={<PlayersPage />} />
                                        <Route path="/players/:id" element={<SinglePlayerPage />} />
                                        <Route path="/coaches" element={<CoachPage />} />
                                        <Route path="/staff" element={<StaffPage />} />
                                        <Route path="/academy" element={<AcademyPage />} />
                                        <Route path="/sponsors" element={<SponsorsPage />} />
                                        <Route path="/about" element={<AboutPage />} />
                                        <Route path="/statistics" element={<StatisticsPage />} />
                                        <Route path="/tournaments" element={<TournamentsPage />} />
                                        <Route path="/mass-media" element={<MassMediaPage />} />
                                        <Route path="/womens-football" element={<WomensFotballPage />} />
                                        <Route path="/u-21" element={<U21Page />} />
                                        <Route path="/u-19" element={<U19Page />} />
                                        <Route path='*' element={<NotFound />} />
                                    </Routes>

                                </ErrorBoundary>

                            </Container>
                        </SponsorsContext.Provider>
                    </SocialContext.Provider>
                </SystemContext.Provider>
            </LangContext.Provider>
        </ThemeProvider>
    );
};

export default App;

