import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { imageBaseUrl } from "src/utils/endpoints";
import classes from "./styles.module.css";
import { format } from "date-fns";
import { useContext } from "react";
import SystemContext from "src/context/systemContext";

export default function NewsItem({ data, page }) {
  const { system } = useContext(SystemContext)
  const { i18n } = useTranslation();
  return (
    <div className={`${page === "match" ? classes.matchNews : classes.newsItemWrapper}`}>
      <Link
        to={`/news/${data.slug}`}
        style={{ display: "block", height: "100%" }}
      >
        <div className={classes.newsItem}>
          <img
            src={imageBaseUrl + data.previewImage}
            alt={data.title[i18n.language]}
            onError={({ currentTarget }) => {
              currentTarget.src = imageBaseUrl + system.find(s => s.name === "imagePlaceholder")?.value;
          }}
          />
          <div className={classes.newsItemContent}>
            <div className={classes.newsItemContentInner}>
              <p>{data.title[i18n.language]}</p>
              <p className={classes.shortDescription}>{data.shortDescription[i18n.language]}</p>
              <span className={classes.date}>
                {format(new Date(data.createdAt.replace(/-/g, '/')), "dd.MM.yyyy")}
              </span>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}
