import { useMediaQuery } from "@mui/material";
import { format } from "date-fns";
import { ru, uz, enGB } from "date-fns/locale";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import SystemContext from "src/context/systemContext";
import { imageBaseUrl } from "src/utils/endpoints";

import classes from "./styles.module.css";

export default function MatchItem({ match }) {
  const { system } = useContext(SystemContext)
  const { t, i18n } = useTranslation();
  const smUp = useMediaQuery((theme) => theme.breakpoints.up("sm"), {
    defaultMatches: true,
  });
  return (
    <Link to={`/matches/${match.id}`} style={{ textDecoration: "none" }}>
      {smUp ? (
        <div className={classes.matchItem}>
          <div className={classes.matchData}>
            <span className={classes.title}>{t("Date")}</span>
            <span>
              {format(new Date(match.date.replace(/-/g, '/')), "d LLLL", {
                locale:
                  i18n.language === "ru"
                    ? ru
                    : i18n.language === "uz"
                      ? uz
                      : enGB,
              })}
            </span>
            <span>{format(new Date(match.date.replace(/-/g, '/')), "HH:mm", { locale: ru })}</span>
          </div>
          <div className={classes.matchData}>
            <span className={classes.title}>{t("Stadium")}</span>
            <span>{match.stadium}</span>
          </div>
          <div className={classes.matchData}>
            <span className={classes.title}>{t("League")}</span>
            <span>{match.championship.name[i18n.language]}</span>
          </div>
          <div className={`${classes.matchData} ${classes.clubs}`}>
            <div className={classes.club}>
              {match.isHome ? (
                <>
                  <span className={classes.title}>{match.myClub.name}</span>
                  <img src={imageBaseUrl + match.myClub.logo} alt="" width={67} onError={({ currentTarget }) => {
                    currentTarget.src = imageBaseUrl + system.find(s => s.name === "imagePlaceholder")?.value;
                  }} />
                </>
              ) : (
                <>
                  <img src={imageBaseUrl + match.club.logo} alt="club" width={67} onError={({ currentTarget }) => {
                    currentTarget.src = imageBaseUrl + system.find(s => s.name === "imagePlaceholder")?.value;
                  }} />
                  <span className={classes.title}>{match.club.name}</span>
                </>
              )}
            </div>
            <span className={classes.title}>VS.</span>
            <div className={classes.club}>
              {!match.isHome ? (
                <>
                  <span className={classes.title}>{match.myClub.name}</span>
                  <img src={imageBaseUrl + match.myClub.logo} alt="" width={67} onError={({ currentTarget }) => {
                    currentTarget.src = imageBaseUrl + system.find(s => s.name === "imagePlaceholder")?.value;
                  }} />
                </>
              ) : (
                <>
                  <img src={imageBaseUrl + match.club.logo} alt="club" width={67} onError={({ currentTarget }) => {
                    currentTarget.src = imageBaseUrl + system.find(s => s.name === "imagePlaceholder")?.value;
                  }} />
                  <span className={classes.title}>{match.club.name}</span>
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.matchItem}>
          <div className={classes.clubs}>
            <div className={classes.logos}>

              <img src={imageBaseUrl + match.myClub.logo} alt="" width={50} onError={({ currentTarget }) => {
                currentTarget.src = imageBaseUrl + system.find(s => s.name === "imagePlaceholder")?.value;
              }} />
              <span>VS.</span>
              <img src={imageBaseUrl + match.club.logo} alt="" width={50} onError={({ currentTarget }) => {
                currentTarget.src = imageBaseUrl + system.find(s => s.name === "imagePlaceholder")?.value;
              }} />
            </div>
            <div className={classes.names}>
              <span>{match.myClub.name}</span>
              <span> — </span>
              <span>{match.club.name}</span>
            </div>
            <div className={classes.info}>
              <div className={classes.data}>
                <span>{t("Date")}:</span>
                <span>{format(new Date(match.date.replace(/-/g, '/')), "dd MMM HH:mm", {
                  locale: i18n.language === "ru"
                    ? ru
                    : i18n.language === "uz"
                      ? uz
                      : enGB,
                })}</span>
              </div>
              <div className={classes.data}>
                <span>{t("Stadium")}:</span>
                <span>{match.stadium}</span>
              </div>
              <div className={classes.data}>
                <span>{t("League")}:</span>
                <span>{match.championship.name[i18n.language]}</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </Link>
  );
}
