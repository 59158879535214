import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";

import {
  useTranslate,
  DashboardMenuItem,
  MenuItemLink,
  useSidebarState,
} from "react-admin";

import users from "src/components/admin/users";
import userRoles from "src/components/admin/userRoles";
import news from "src/components/admin/news";
import slides from "src/components/admin/slides";
import newsCategory from "src/components/admin/newsCategory";
import media from "src/components/admin/media";
import seasons from "src/components/admin/seasons";
import championships from "src/components/admin/championships";
import clubs from "src/components/admin/clubs";
import referees from "src/components/admin/referees";
import matches from "src/components/admin/matches";
import players from "src/components/admin/players";
import playerRoles from "src/components/admin/playerRoles";
import coach from "src/components/admin/coach";
import staff from "src/components/admin/staff";
import sponsor from "src/components/admin/sponsor";
import academy from "src/components/admin/academy";
import about from "src/components/admin/about";
import massMedia from "src/components/admin/massMedia";
import system from "src/components/admin/system";
import SubMenu from "./SubMenu";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

// type MenuName = 'menuCatalog' | 'menuSales' | 'menuCustomers';

const Menu = ({ dense = false }) => {
  const location = useLocation();
  const [state, setState] = useState({
    // menuUsers: true,
    // menuNews: true,
    // menuClubs: true,
    // menuPlayers: true,
    // menuMatches: true,
  });
  useEffect(() => {
    if (location.pathname.includes("user")) {
      handleToggle("menuUsers");
      setState((state) => ({ ...state, menuUsers: true }));
    } else if (location.pathname.includes("news")) {
      setState((state) => ({ ...state, menuNews: true }));
    } else if (
      location.pathname.includes("club") ||
      location.pathname.includes("championship")
    ) {
      setState((state) => ({ ...state, menuClubs: true }));
    } else if (location.pathname.includes("player")) {
      setState((state) => ({ ...state, menuPlayers: true }));
    } else if (
      location.pathname.includes("match") ||
      location.pathname.includes("referee")
    ) {
      setState((state) => ({ ...state, menuMatches: true }));
    }
  }, [location]);

  const translate = useTranslate();
  const [open] = useSidebarState();

  const handleToggle = (menu) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <Box
      sx={{
        width: open ? 300 : 50,
        marginTop: 1,
        marginBottom: 1,
        transition: (theme) =>
          theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }}
    >
      <DashboardMenuItem />
      <SubMenu
        handleToggle={() => handleToggle("menuUsers")}
        isOpen={state.menuUsers}
        name="pos.menu.users"
        icon={<users.icon />}
        dense={dense}
      >
        <MenuItemLink
          to="/admin/users"
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.users.name`, {
            smart_count: 2,
          })}
          leftIcon={<users.icon />}
          dense={dense}
        />
        <MenuItemLink
          to="/admin/users/roles"
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.userRoles.name`, {
            smart_count: 2,
          })}
          leftIcon={<userRoles.icon />}
          dense={dense}
        />
      </SubMenu>
      <MenuItemLink
        to="/admin/slides"
        state={{ _scrollToTop: true }}
        primaryText={translate(`resources.slides.name`, {
          smart_count: 2,
        })}
        leftIcon={<slides.icon />}
        dense={dense}
      />
      <SubMenu
        handleToggle={() => handleToggle("menuNews")}
        isOpen={state.menuNews}
        name="pos.menu.news"
        icon={<news.icon />}
        dense={dense}
      >
        <MenuItemLink
          to="/admin/news"
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.news.name`, {
            smart_count: 2,
          })}
          leftIcon={<news.icon />}
          dense={dense}
        />
        <MenuItemLink
          to="/admin/mass-media"
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.mass-media.name`, {
            smart_count: 2,
          })}
          leftIcon={<massMedia.icon />}
          dense={dense}
        />
        <MenuItemLink
          to="/admin/news/category"
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.newsCategory.name`, {
            smart_count: 2,
          })}
          leftIcon={<newsCategory.icon />}
          dense={dense}
        />
      </SubMenu>
      <MenuItemLink
        to="/admin/media"
        state={{ _scrollToTop: true }}
        primaryText={translate(`resources.media.name`, {
          smart_count: 2,
        })}
        leftIcon={<media.icon />}
        dense={dense}
      />

      <SubMenu
        handleToggle={() => handleToggle("menuMatches")}
        isOpen={state.menuMatches}
        name="pos.menu.matches"
        icon={<matches.icon />}
        dense={dense}
      >
        <MenuItemLink
          to="/admin/match"
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.match.name`, {
            smart_count: 2,
          })}
          leftIcon={<matches.icon />}
          dense={dense}
        />
        <MenuItemLink
          to="/admin/season"
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.seasons.name`, {
            smart_count: 2,
          })}
          leftIcon={<seasons.icon />}
          dense={dense}
        />
        <MenuItemLink
          to="/admin/referee"
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.referee.name`, {
            smart_count: 2,
          })}
          leftIcon={<referees.icon />}
          dense={dense}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle("menuClubs")}
        isOpen={state.menuClubs}
        name="pos.menu.clubs"
        icon={<clubs.icon />}
        dense={dense}
      >
        <MenuItemLink
          to="/admin/club/me"
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.club.myName`, {
            smart_count: 2,
          })}
          leftIcon={<clubs.icon />}
          dense={dense}
        />
        <MenuItemLink
          to="/admin/club"
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.club.name`, {
            smart_count: 2,
          })}
          leftIcon={<clubs.icon />}
          dense={dense}
        />
        <MenuItemLink
          to="/admin/championship"
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.championship.name`, {
            smart_count: 2,
          })}
          leftIcon={<championships.icon />}
          dense={dense}
        />
      </SubMenu>

      <SubMenu
        handleToggle={() => handleToggle("menuPlayers")}
        isOpen={state.menuPlayers}
        name="pos.menu.players"
        icon={<players.icon />}
        dense={dense}
      >
        <MenuItemLink
          to="/admin/player"
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.player.name`, {
            smart_count: 2,
          })}
          leftIcon={<players.icon />}
          dense={dense}
        />
        <MenuItemLink
          to="/admin/player/role"
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.player/role.name`, {
            smart_count: 2,
          })}
          leftIcon={<playerRoles.icon />}
          dense={dense}
        />
      </SubMenu>

      <MenuItemLink
        to="/admin/coach"
        state={{ _scrollToTop: true }}
        primaryText={translate(`resources.coach.name`, {
          smart_count: 2,
        })}
        leftIcon={<coach.icon />}
        dense={dense}
      />
      <MenuItemLink
        to="/admin/staff"
        state={{ _scrollToTop: true }}
        primaryText={translate(`resources.staff.name`, {
          smart_count: 2,
        })}
        leftIcon={<staff.icon />}
        dense={dense}
      />
      <MenuItemLink
        to="/admin/sponsor"
        state={{ _scrollToTop: true }}
        primaryText={translate(`resources.sponsor.name`, {
          smart_count: 2,
        })}
        leftIcon={<sponsor.icon />}
        dense={dense}
      />
      <MenuItemLink
        to="/admin/academy"
        state={{ _scrollToTop: true }}
        primaryText={translate(`resources.academy.name`, {
          smart_count: 2,
        })}
        leftIcon={<academy.icon />}
        dense={dense}
      />
      <MenuItemLink
        to="/admin/about/1"
        state={{ _scrollToTop: true }}
        primaryText={translate(`resources.about.name`, {
          smart_count: 2,
        })}
        leftIcon={<about.icon />}
        dense={dense}
      />

      <MenuItemLink
        to="/admin/system"
        state={{ _scrollToTop: true }}
        primaryText={translate(`resources.system.name`, {
          smart_count: 2,
        })}
        leftIcon={<system.icon />}
        dense={dense}
      />
    </Box>
  );
};

export default Menu;
