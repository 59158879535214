import React from 'react'
import { Create, ImageField, ImageInput, ReferenceInput, SelectInput, SimpleForm, TextInput } from 'react-admin'

export default function ClubCreate(props) {

    return (
        <Create {...props}>
            <SimpleForm>

                <TextInput source="name" label="Название" />
                <ReferenceInput source="championshipId" reference="championship">
                    <SelectInput optionText="name.ru" required label="Чемпионат" />
                </ReferenceInput>
                <ImageInput source="logo" label="Логотип клуба" accept="image/*" placeholder={<p>Нажмите сюда или перетащите картинку</p>}>
                    <ImageField source="src" title="avatar" />
                </ImageInput>
                <ImageInput source="image" label="Фотография клуба" accept="image/*" placeholder={<p>Нажмите сюда или перетащите картинку</p>}>
                    <ImageField source="src" title="фото" />
                </ImageInput>
            </SimpleForm>
        </Create>
    )
}
