import {
  List,
  Datagrid,
  FunctionField,
} from "react-admin";

export default function ChampionshipList() {
  return (
    <List>
      <Datagrid rowClick="edit">
        <FunctionField label="Описание" render={record => <div dangerouslySetInnerHTML={{ __html: record?.description?.ru }}></div>} />
      </Datagrid>
    </List >
  );
}
