import { useContext, useEffect, useState } from "react";
import format from "date-fns/format";

import { MONTHES } from "src/utils/const";
import LangContext from "src/context/langContext";
import classes from "./styles.module.css";
import { imageBaseUrl } from "src/utils/endpoints";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import MainItemWrapper from "src/components/site/main/MainItemWrapper";
import SystemContext from "src/context/systemContext";

export default function Match({ match, page }) {
  const { lang } = useContext(LangContext);
  const { system } = useContext(SystemContext)
  const { t } = useTranslation();
  const [isLive, setIsLive] = useState();
  useEffect(() => {
    if (
      new Date(match.date.replace(/-/g, '/')).getTime() + 90 * 60 * 1000 > Date.now() &&
      new Date(match.date.replace(/-/g, '/')).getTime() < Date.now()
    ) {
      setIsLive(true);
    } else {
      setIsLive(false);
    }
  }, [match]);
  const isLarge = useMediaQuery((theme) => theme.breakpoints.up("576"), {
    defaultMatches: true,
    noSsr: false,
  });
  return (
    <MainItemWrapper page={page}>
      <Link
        to={`/matches/${match.id}`}
        style={{ display: "block", textDecoration: "none", height: "100%" }}
      >
        <div className={classes.match}>
          <div className={classes.matchTop}>
            <div className={classes.matchTopInfo}>
              <span className={classes.matchDay}>
                {new Date(match.date.replace(/-/g, '/')).getDate()}
              </span>
              <span className={classes.matchMonth}>
                {MONTHES[new Date(match.date.replace(/-/g, '/')).getMonth()][lang]}
              </span>
            </div>
            {isLive && <span className={classes.matchMonth}>{t("Live")}</span>}
          </div>
          <div className={classes.matchInfo}>
            <div className={classes.clubsInfo}>
              <div className={classes.club}>
                {match.isHome ? (
                  <>
                    <img src={imageBaseUrl + match.myClub.logo} alt="" width={isLarge ? 64 : 47}
                      onError={({ currentTarget }) => {
                        currentTarget.src = imageBaseUrl + system.find(s => s.name === "imagePlaceholder")?.value;
                      }}
                    />
                    <span>{match.myClub.name}</span>
                  </>
                ) : (
                  <>
                    <img
                      src={imageBaseUrl + match.club.logo}
                      alt="club logo"
                      width={isLarge ? 64 : 47}
                      onError={({ currentTarget }) => {
                        currentTarget.src = imageBaseUrl + system.find(s => s.name === "imagePlaceholder")?.value;
                      }}
                    />
                    <span>{match.club.name}</span>
                  </>
                )}
              </div>
              <div className={classes.timeWrapper}>
                {isLarge && (
                  <span className={classes.type}>
                    {match.championship.name[lang]}
                  </span>
                )}
                <span className={classes.time}>
                  {format(new Date(match.date.replace(/-/g, '/')), "HH:mm")}
                </span>
              </div>
              <div className={classes.club}>
                {!match.isHome ? (
                  <>
                    <img src={imageBaseUrl + match.myClub.logo} alt="" width={isLarge ? 64 : 47}
                      onError={({ currentTarget }) => {
                        currentTarget.src = imageBaseUrl + system.find(s => s.name === "imagePlaceholder")?.value;
                      }}
                    />
                    <span>{match.myClub.name}</span>
                  </>

                ) : (
                  <>
                    <img
                      src={imageBaseUrl + match.club.logo}
                      alt="club logo"
                      width={isLarge ? 64 : 47}
                      onError={({ currentTarget }) => {
                        currentTarget.src = imageBaseUrl + system.find(s => s.name === "imagePlaceholder")?.value;
                      }}
                    />
                    <span>{match.club.name}</span>
                  </>
                )}
              </div>
            </div>
            {isLarge && <div className={classes.stadium}>{match.stadium}</div>}
          </div>
          <div className="matchAnimation"></div>
        </div>
      </Link>

    </MainItemWrapper>
  );
}
