const { fetchUtils } = require("react-admin");

export default function httpClient(url, options = {}) {
    if (!options.headers) {
        options.headers = new Headers({ Accept: "application/json" });
    }
    const token = localStorage.getItem("token");
    if (token) {
        options.headers.set("x-auth-token", token);
    }

    return fetchUtils.fetchJson(url, options);
};