import InfoSharpIcon from '@mui/icons-material/InfoSharp';

import AboutEdit from "./AboutEdit";
import AboutList from "./AboutList";

const resource = {
  edit: AboutEdit,
  list: AboutList,
  icon: InfoSharpIcon,
};

export default resource;
