import { Box, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";

import Flag from "src/components/common/Flag";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function RenderWithLangs({
  TabRu,
  TabUz,
  TabEn,
}) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", maxWidth: "350px" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label={<Flag code="RU" style={{ height: "25px" }} />} {...a11yProps(0)} />
          <Tab label={<Flag code="UZ"  style={{ height: "25px" }}/>} {...a11yProps(1)} />
          <Tab label={<Flag code="GB"  style={{ height: "25px" }}/>} {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        {TabRu}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {TabUz}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {TabEn}
      </TabPanel>
    </Box>
  );
}
