import React from 'react'

import classes from './styles.module.css'

export default function MainBlock({ children }) {
    return (
        <div className={classes.block}>
            {children}
        </div>
    )
}
