import React from "react";
import { Box } from "@mui/material";
import classes from "./styles.module.css";

export default function SocialContainer({ children, ...props }) {
    return (
        <Box className={classes.socialContainer} {...props}>
            {children}
        </Box>
    );
}