import { useContext, useState } from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

import { Navbar } from "./navbar";
import { Sidebar } from "./sidebar";
import Footer from "./footer";
import Social from "src/components/site/common/Social";
import SystemContext from "src/context/systemContext";
import { useTranslation } from "react-i18next";

import bgImage from "src/assets/images/bgImage.png";
import BodyBottom from "./BodyBottom";

const LayoutRoot = styled("div")(({ page, theme }) => ({
  display: "flex",
  flexDirection: "column",
  flex: "1 1 auto",
  maxWidth: "100%",
  background: `url(${bgImage})`,
  backgroundAttachment: "fixed",
  backgroundSize: "cover",
  backgroundPositionX: "center",
  backgroundPositionY: "bottom",
  backgroundRepeat: "no-repeat",
  paddingTop: page === "main" ? 0 : 30,
  paddingBottom: page === "main" ? 0 : 30,
  [theme.breakpoints.up("sm")]: {
    paddingTop: page === "main" ? 0 : 100,
    paddingBottom: page === "main" ? 0 : 100,
  },
  [theme.breakpoints.down("576")]: {
    background: "#020C34",
    paddingTop: 0,
  },
}));

export default function Layout({ loading = false, page, ...props }) {
  const { t } = useTranslation();

  const menu = [
    {
      id: 1,
      href: "/matches",
      title: t("Matches"),
      subMenu: [
        {
          id: 1,
          href: "/tournaments",
          title: t("Tournaments"),
        },
        {
          id: 2,
          href: "/statistics",
          title: t("Statistics"),
        },
        {
          id: 3,
          href: "/matches",
          title: t("Calendar"),
        },
      ],
    },
    {
      id: 2,
      href: "/players",
      title: t("Team"),
      subMenu: [
        {
          id: 1,
          href: "/players",
          title: t("Players"),
        },
        {
          id: 2,
          href: "/coaches",
          title: t("Coaches"),
        },
        {
          id: 3,
          href: "/staff",
          title: t("Management and Staff"),
        },
        {
          id: 4,
          href: "/womens-football",
          title: t("Women's football"),
        },

      ],
    },
    {
      id: 3,
      href: "/academy",
      title: t("Academy"),
      subMenu: [
        {
          id: 1,
          href: "/academy",
          title: t("Academy"),
        },
      ],
    },
    {
      id: 4,
      href: "/news",
      title: t("News"),
      subMenu: [
        {
          id: 1,
          href: "/news",
          title: t("News"),
        },
        {
          id: 2,
          href: "/mass-media",
          title: t("Mass media"),
        },
      ],
    },
    {
      id: 6,
      href: "/media",
      title: t("Gallery"),
      subMenu: [
        {
          id: 1,
          href: "/media",
          title: t("Gallery"),
        },
      ],
    },
    {
      id: 7,
      href: "/about",
      title: t("About us"),
      subMenu: [
        {
          id: 1,
          href: "/about",
          title: t("About us"),
        },
      ],
    },
    {
      id: 5,
      href: "/sponsors",
      title: t("Sponsors"),
      subMenu: [
        {
          id: 1,
          href: "/sponsors",
          title: t("Sponsors"),
        },
      ],
    },
  ];

  const { system } = useContext(SystemContext);
  const { children } = props;
  const { user } = props;
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  if (page === "error") {
    return (
      <div>
        <Navbar menu={menu} page={page} onSidebarOpen={() => setSidebarOpen(true)} />
        <LayoutRoot page={page}>
          <Box
            sx={{
              display: "flex",
              flex: "1 1 auto",
              flexDirection: "column",
              width: "100%",
            }}
          >
            {children}
          </Box>
          <BodyBottom />
        </LayoutRoot>

        <Sidebar
          onClose={() => setSidebarOpen(false)}
          open={isSidebarOpen}
          setSidebarOpen={setSidebarOpen}
          menu={menu}
        />
      </div>)
  }

  // if (loading) {
  //   return null;
  // }
  return (
    <div>
      {/* <Social system={system} /> */}
      <Navbar menu={menu} onSidebarOpen={() => setSidebarOpen(true)} />
      <LayoutRoot page={page}>
        <Box
          sx={{
            display: "flex",
            flex: "1 1 auto",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {children}
        </Box>
        <BodyBottom />
      </LayoutRoot>

      <Sidebar
        onClose={() => setSidebarOpen(false)}
        open={isSidebarOpen}
        setSidebarOpen={setSidebarOpen}
        user={user}
        system={system}
        menu={menu}
      />
      <Footer system={system} />
    </div>
  );
}
