import { Box } from "@mui/material";
import { BooleanInput, Create, SimpleForm, TextInput } from "react-admin";

export default function UserRoleCreate(props) {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" label="Название" />
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          <BooleanInput
            label="Создание админа"
            source="createAdmin"
            parse={(v) => {
              return v ? 1 : 0;
            }}
            format={(v) => {
              return v === 1 ? true : false;
            }}
          />
          <BooleanInput
            label="Создание"
            source="create"
            parse={(v) => {
              return v ? 1 : 0;
            }}
            format={(v) => {
              return v === 1 ? true : false;
            }}
          />
          <BooleanInput
            label="Редактирование"
            source="update"
            parse={(v) => {
              return v ? 1 : 0;
            }}
            format={(v) => {
              return v === 1 ? true : false;
            }}
          />
          <BooleanInput
            label="Удаление"
            source="del"
            parse={(v) => {
              return v ? 1 : 0;
            }}
            format={(v) => {
              return v === 1 ? true : false;
            }}
          />
          <BooleanInput
            label="Чтение"
            source="read"
            parse={(v) => {
              return v ? 1 : 0;
            }}
            format={(v) => {
              return v === 1 ? true : false;
            }}
          />
        </Box>
      </SimpleForm>
    </Create>
  );
}
