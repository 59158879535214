import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

import ChampionshipList from "./ChampionshipList";
import ChampionshipEdit from "./ChampionshipEdit";
import ChampionshipCreate from "./ChampionshipCreate";

const resource = {
  list: ChampionshipList,
  create: ChampionshipCreate,
  edit: ChampionshipEdit,
  icon: EmojiEventsIcon,
};

export default resource;
