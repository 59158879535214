import http from "./httpService";
import { apiBaseUrl } from "../utils/endpoints";
async function getPlayers({ limit = "", filter = {} }) {
    try {
        if (limit) {
            const { data } = await http.get(apiBaseUrl + "/player", { params: { range: `[0, ${limit}]`, sort: `["goal", "DESC"]`, filter: filter } });
            return data;
        }
        const { data } = await http.get(apiBaseUrl + "/player", { params: { sort: `["goal", "DESC"]`, filter: filter } });
        return data;

    } catch (err) {
        return err.response;
    }
}

async function getPlayerById(id) {
    try {
        const { data } = await http.get(apiBaseUrl + "/player/" + id);
        return data;

    } catch (err) {
        return err.response;
    }
}
async function getPlayerStatistics(id) {
    try {
        const { data } = await http.get(apiBaseUrl + "/player/statistics/" + id);

        return data;

    } catch (err) {
        return err.response;
    }
}

async function getPlayerRoles({ limit = "" }) {
    try {
        if (limit) {
            const { data } = await http.get(apiBaseUrl + "/player/role", { params: { range: `[0, ${limit}]` } });
            return data;
        }
        const { data } = await http.get(apiBaseUrl + "/player/role");
        return data;
    } catch (err) {
        return err.response;
    }
}
export { getPlayers, getPlayerById, getPlayerStatistics, getPlayerRoles };