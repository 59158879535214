import SportsIcon from '@mui/icons-material/Sports';

import RefereeList from './RefereeList';
import RefereeEdit from './RefereeEdit';
import RefereeCreate from './RefereeCreate';

const resource = {
    list: RefereeList,
    create: RefereeCreate,
    edit: RefereeEdit,
    icon: SportsIcon,
};

export default resource;