
import React from 'react'
import { Create, ImageField, ImageInput, SimpleForm, TextInput, TranslatableInputs } from 'react-admin'

export default function MassMediaCreate(props) {

    return (
        <Create {...props}>
            <SimpleForm>
                <TranslatableInputs locales={['ru', 'uz', 'en']} defaultLocale="ru">
                    <TextInput source="title" label="Заголовок" />
                </TranslatableInputs>
                <TextInput source="source" label="Источник (Instagram, Facebook, ...)" />
                <TextInput source="slug" label="Ссылка" />
                <ImageInput
                    source="image"
                    label="Картинка"
                    accept="image/*"
                    placeholder={<p>Нажмите сюда или перетащите картинку</p>}
                >
                    <ImageField source="src" title="title" />
                </ImageInput>
            </SimpleForm>
        </Create>
    )
}
