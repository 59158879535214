import nProgress from "nprogress";
import { useContext } from "react";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import MoreButton from "src/components/site/common/MoreButton";
import Title from "src/components/site/common/Title";
import AllMedia from "src/components/site/media/AllMedia";
import BodyContainer from "src/Container/BodyContainer";
import SystemContext from "src/context/systemContext";

import Layout from "src/layout/site";
import { getMedia } from "src/services/media";

export default function MediaPage() {
  const { system } = useContext(SystemContext);
  const { t } = useTranslation();
  const [media, setMedia] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchAll() {
      nProgress.start();
      const tempMedia = await getMedia({ limit: 6 });

      setMedia(tempMedia);
      setLoading(false);
      nProgress.done();
    }
    fetchAll();
  }, []);

  async function handleClick() {
    nProgress.start();
    let currentMedia = [...media];
    const tempMedia = await getMedia({ offset: currentMedia.length, limit: 6 });
    currentMedia = currentMedia.concat(tempMedia);
    setMedia(currentMedia);
    nProgress.done();
  }
  return (
    <Layout loading={loading}>
      <Helmet>
        <title key="title">{`Медиа – ${
          system?.find((s) => s.name === "siteName")?.value
        }`}</title>
      </Helmet>
      <BodyContainer>
        <Title text={t("Media")} />
        <AllMedia media={media} />
        <MoreButton text={t("More photos")} onClick={handleClick} />
      </BodyContainer>
    </Layout>
  );
}
