import React from 'react'
import { Link } from 'react-router-dom'
import SingleNewsBlock from '../../common/SingleNewsBlock'

export default function NewsItem({ data }) {
    return (
        <Link to={`/news/${data.slug}`} style={{ display: "block", height: "100%" }}>
            <SingleNewsBlock data={data} />
        </Link>
    )
}
