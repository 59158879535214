import { format } from 'date-fns';
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next';
import SystemContext from 'src/context/systemContext';
import { imageBaseUrl } from 'src/utils/endpoints';
import classes from './styles.module.css';
export default function SingleMassMedia({ data }) {
    const { system } = useContext(SystemContext)
    const { i18n } = useTranslation()
    return (
        <div className={classes.media}>
            <img src={imageBaseUrl + data.image} alt="" className={classes.image} onError={({ currentTarget }) => {
                currentTarget.src = imageBaseUrl + system.find(s => s.name === "imagePlaceholder")?.value;
            }} />
            <div className={classes.content}>
                <span className={classes.source}>{data.source}</span>
                <div className={classes.info}>
                    <span>{data.title[i18n.language]}</span>
                    <span className={classes.date}>{format(new Date(data.createdAt.replace(/-/g, '/')), "dd.MM.yyyy")}</span>
                </div>
            </div>
        </div>
    )
}
