import nProgress from "nprogress";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Player from "src/components/site/common/player/Player";
import Title from "src/components/site/common/Title";
import MainItems from "src/components/site/main/MainItems";
import BodyContainer from "src/Container/BodyContainer";
import SystemContext from "src/context/systemContext";
import Layout from "src/layout/site";
import { getPlayerRoles, getPlayers } from "src/services/players";
import { SwiperSlide } from "swiper/react";

import classes from './styles.module.css'

export default function PlayersPage() {
    const { system } = useContext(SystemContext)
    const { t, i18n } = useTranslation()
    const [loading, setLoading] = useState(true)
    const [playerRoles, setPlayerRoles] = useState()
    const [players, setPlayers] = useState()

    useEffect(() => {
        async function fetchAll() {
            nProgress.start();
            const tempPlayerRoles = await getPlayerRoles({});
            setPlayerRoles(tempPlayerRoles);

            const tempPlayers = await getPlayers({ filter: { clubId: 1 } });
            setPlayers(tempPlayers);
            
            setLoading(false);
            nProgress.done();
        }
        fetchAll();
    }, []);

    return (
        <Layout loading={loading}>
            <Helmet>
                <title key="title">{`Игроки – ${system?.find(s => s.name === "siteName")?.value}`}</title>
            </Helmet>
            <BodyContainer>
                <>
                    <Title text={t("Players")} />
                    <div className={classes.playerRoles}>
                        {playerRoles?.map(role => {
                            return (<div key={role.id} className={classes.playerRolesItem}>
                                <h2>{role.namePlural[i18n.language]}</h2>
                                <div className={classes.players}>
                                    {players?.map(p => p.player_role.id === role.id ?
                                        <Player key={p.id} player={p} page="main" />
                                        : null)}
                                </div>
                            </div>)
                        })}
                    </div>
                </>

            </BodyContainer>
        </Layout>
    );
}
