import { apiBaseUrl } from "src/utils/endpoints";
import httpClient from "src/utils/httpClient";

async function postEditorIMage(image) {
    try {
        const formData = new FormData();
        formData.append("editorImage", image);
        let result = await httpClient(apiBaseUrl + "/editor", { method: 'post', body: formData });
        return result;
    } catch (err) {
        return err.response;
    }
}

export { postEditorIMage };