
import { Edit,  SimpleForm, TextInput } from 'react-admin'

export default function SeasonEdit() {

    return (
        <Edit>
            <SimpleForm>
                <TextInput source="name" label="Название"  />
            </SimpleForm>
        </Edit>
    )
}
