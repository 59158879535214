import http from "./httpService";
import { apiBaseUrl } from "../utils/endpoints";
async function getAbout() {
    try {
        let { data } = await http.get(apiBaseUrl + "/about");
        return data;
    } catch (err) {
        return err.response;
    }
}

export { getAbout };