import { useContext } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Title from "src/components/site/common/Title";
import Sponsor from "src/components/site/sponsor/Sponsor";
import BodyContainer from "src/Container/BodyContainer";
import SponsorsContext from "src/context/sponsorsContext";
import SystemContext from "src/context/systemContext";
import Layout from "src/layout/site";

import classes from "./styles.module.css";

export default function SponsorsPage() {
    const { system } = useContext(SystemContext)
    const { t } = useTranslation();
    const { sponsors } = useContext(SponsorsContext);

    return (
        <Layout>
              <Helmet>
        <title key="title">{`Спонсоры – ${system?.find(s => s.name === "siteName")?.value}`}</title>
      </Helmet>
            <BodyContainer>
                <>
                    <Title text={t("Sponsors")} />
                    <div className={classes.academy}>
                        {sponsors?.map(sponsor => <Sponsor data={sponsor} key={sponsor.id} />)}
                    </div>
                </>
            </BodyContainer>
        </Layout>
    );
}