import ShareIcon from '@mui/icons-material/Share';

import MassMediaList from "./MassMediaList";
import MassMediaEdit from "./MassMediaEdit";
import MassMediaCreate from "./MassMediaCreate";

const resource = {
  list: MassMediaList,
  create: MassMediaCreate,
  edit: MassMediaEdit,
  icon: ShareIcon,
};

export default resource;
