// const apiBaseUrl = "http://192.168.0.108:9000/api";
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || "http://192.168.0.111:9000/api";
const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL || "http://192.168.0.109:9000";

// const apiBaseUrl = "http://192.168.0.109:9000/api";
// const imageBaseUrl = "http://192.168.0.109:9000";

// const imageBaseUrl = "http://192.168.0.113:9000";
// const apiBaseUrl = "https://api.pfcsurkhon.uz/api";
// const imageBaseUrl = "https://api.pfcsurkhon.uz";
export { apiBaseUrl, imageBaseUrl };
