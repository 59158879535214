import { Swiper } from 'swiper/react'
import { useMediaQuery } from '@mui/material';

import "swiper/css";
import classes from './styles.module.css'

export default function MainItems({ children, page }) {
    const lgUp = useMediaQuery(theme => theme.breakpoints.up("lg"));
    const smUp = useMediaQuery(theme => theme.breakpoints.up("sm"));

    return (
        <Swiper
            slidesPerView={lgUp ? 3 : smUp ? 2.2 : 1.5}
            spaceBetween={smUp ? 20 : 10}
            grabCursor={true}
            className={classes.slider}
            style={{
                paddingBottom: page === "players" ? "1px" : 0
            }}
        >
            {children}
        </Swiper>
    )
}
