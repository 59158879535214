import i18next from "../utils/i18n";

const KEY = "lang";

export const setLanguage = async (lang) => {
  if (typeof window !== "undefined") {
    localStorage.setItem(KEY, lang);
    const currentLang = await i18next.changeLanguage(lang).then(() => {
      i18next.options.lng = lang;
      return i18next.options.lng;
    });
    return currentLang;
  }
};
export const getLanguage = () => {
  if (typeof window !== "undefined") {
    return localStorage.getItem(KEY) || "uz";
  }
  return "uz";
};