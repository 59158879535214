import http from "./httpService";

const weatherApiUrl = "https://api.openweathermap.org/data/2.5/forecast?"

async function getWeather({ city = "tashkent" }) {
    try {
        const { data } = await http.get(weatherApiUrl + "units=metric&lang=ru&q=" + city + "&appid=" + process.env.REACT_APP_WEATHER_API_KEY);
        return data;
    } catch (err) {
        return err.response;
    }
}

export { getWeather };