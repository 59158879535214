import nProgress from "nprogress";
import { useContext } from "react";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Academy from "src/components/site/academy/Academy";
import Club from "src/components/site/clubs/Club";
import Title from "src/components/site/common/Title";
import BodyContainer from "src/Container/BodyContainer";
import SystemContext from "src/context/systemContext";
import Layout from "src/layout/site";
import { getAcademy } from "src/services/academy";
import { getClub } from "src/services/club";

import classes from "./styles.module.css";

export default function AcademyPage() {
    const { t } = useTranslation();
    const { system } = useContext(SystemContext)
    const [loading, setLoading] = useState(true);
    const [u21, setU21] = useState();
    const [u19, setU19] = useState();

    useEffect(() => {
        async function fetchAll() {
            nProgress.start();
            const tempClub21 = await getClub(2);
            setU21(tempClub21)

            const tempClub19 = await getClub(3);
            setU19(tempClub19)
            
            setLoading(false);
            nProgress.done();
        }
        fetchAll();
    }, []);

    return (
        <Layout loading={loading}>
            <Helmet>
                <title key="title">{`Академия – ${system?.find(s => s.name === "siteName")?.value}`}</title>
            </Helmet>
            <BodyContainer>
                <>
                    <Title text={t("Academy")} />

                    <div className={classes.academy}>
                        {/* {academy?.map(a => <Academy data={a} key={a.id} />)} */}
                        <Club club={u21} title={"U-21"} type="second" />
                        <Club club={u19} title={"U-19"} type="second" />
                    </div>
                </>
            </BodyContainer>
        </Layout>
    );
}