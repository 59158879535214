import React, { useContext, useRef } from "react";
import { EffectFade, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

import classes from "./styles.module.css";
import { useMediaQuery } from "@mui/material";
import { imageBaseUrl } from "src/utils/endpoints";
import SmallContainer from "src/Container/SmallContainer";
import SystemContext from "src/context/systemContext";
import CustomNavigation from "./CustomNavigation";
import { useTranslation } from "react-i18next";

export default function Slider({ slides }) {
  const { system } = useContext(SystemContext);
  const { t, i18n } = useTranslation();

  const smUp = useMediaQuery((theme) => theme.breakpoints.up("sm"), {
    defaultMatches: true,
    noSsr: false,
  });
  const isLarge = useMediaQuery((theme) => theme.breakpoints.up("576"), {
    defaultMatches: true,
    noSsr: false,
  });

  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const pagRef = useRef(null);

  return (
    <Swiper
      spaceBetween={30}
      effect={"fade"}
      initialSlide={0}
      navigation={{
        prevEl: prevRef.current,
        nextEl: nextRef.current,
      }}
      pagination={{
        el: pagRef.current,
        clickable: true,
      }}
      modules={[EffectFade, Pagination, Navigation]}
      className={classes.mainSwiper}
      onBeforeInit={(swiper) => {
        swiper.params.navigation.prevEl = prevRef.current;
        swiper.params.navigation.nextEl = nextRef.current;
        swiper.params.pagination.el = pagRef.current;
        swiper.navigation.init();
        swiper.navigation.update();
        swiper.pagination.init();
        swiper.pagination.update();
      }}
    >
      {slides.map((slide) => (
        <SwiperSlide key={slide.id}>
          <div className={classes.sliderItem}>
            <div className={classes.slideBg}>
              <img
                src={
                  smUp
                    ? imageBaseUrl + slide.imageDesktop
                    : imageBaseUrl + slide.imageMobile
                }
                alt={slide.title[i18n.language]}
                onError={({ currentTarget }) => {
                  currentTarget.src =
                    imageBaseUrl +
                    system.find((s) => s.name === "imagePlaceholder")?.value;
                }}
              />
              <div className={classes.slideLayer}></div>
            </div>
            <div className={classes.slideContent}>
              <SmallContainer style={{ position: "relative" }}>
                <div className={classes.slideContentInner}>
                  <span>{`${new Date(
                    slide.createdAt.replace(/-/g, "/")
                  ).toLocaleString("ru-RU", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  })}`}</span>
                  <h2>
                    <a href={slide.slug} style={{ textDecoration: "none" }}>
                      {slide.title[i18n.language]}
                    </a>
                  </h2>
                  {isLarge && <p>{slide.description[i18n.language]}</p>}
                </div>
              </SmallContainer>
            </div>
          </div>
        </SwiperSlide>
      ))}
      {slides.length > 1 && (
        <CustomNavigation prevRef={prevRef} nextRef={nextRef} pagRef={pagRef} />
      )}
    </Swiper>
  );
}
