import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSwiper } from "swiper/react";

import classes from "./styles.module.css";

export default function MatchesPagination({ props }) {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0)
  const swiper = useSwiper();
  swiper.on("realIndexChange", (e) => {
    setActiveIndex(e.realIndex);
  })
  return (
    <div className={classes.pagination}>
      <button className={`${classes.paginationItem} ${activeIndex === 0 ? classes.active : ""}`}
        onClick={() => { swiper.slideTo(0) }}>{t("Report")}
      </button>
      <button className={`${classes.paginationItem} ${activeIndex === 1 ? classes.active : ""}`} onClick={() => { swiper.slideTo(1) }}>{t("Lineup")}</button>
      <button className={`${classes.paginationItem} ${activeIndex === 2 ? classes.active : ""}`} onClick={() => { swiper.slideTo(2) }}>{t("Statistics")}</button>
      <button className={`${classes.paginationItem} ${activeIndex === 3 ? classes.active : ""}`} onClick={() => { swiper.slideTo(3) }}>{t("News")}</button>
      <button className={`${classes.paginationItem} ${activeIndex === 4 ? classes.active : ""}`} onClick={() => { swiper.slideTo(4) }}>{t("Photo/Video")}</button>
    </div>
  );
}
