import http from "./httpService";
import { apiBaseUrl } from "../utils/endpoints";
async function getMassMedia({ limit, offset = 0, exclude }) {
    try {
        let { data } = await http.get(apiBaseUrl + "/mass-media", { params: { range: `[${offset}, ${limit}]`, sort: `["createdAt", "DESC"]`, exclude: exclude } });
        return data;
    } catch (err) {
        return err.response;
    }
}

export { getMassMedia };