import React from 'react'

import classes from './styles.module.css'

export default function MainItemsWrapper({ children, border, page }) {
    return (
        <div className={`${border ? classes.border : ""} ${page === "main" ? classes.main : classes.mainItemsWrapper}`}>
            {children}
        </div>
    )
}
