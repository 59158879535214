import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';

export default function MatchStatistics({ data, isHome }) {
    const { i18n } = useTranslation()
    return (
        <div className={classes.statistics}>
            <div className={classes.statisticsInfo}>
                {isHome ? (
                    <><span>{data.value}</span>
                        <span>{data.name[i18n.language]}</span>
                        <span>{data.valueOpposite}</span>
                    </>
                ) : (
                    <>
                        <span>{data.valueOpposite}</span>
                        <span>{data.name[i18n.language]}</span>
                        <span>{data.value}</span>
                    </>
                )}

            </div>
            <div className={classes.statisticsImage}>
                {isHome ? (
                    <> <div className={classes.own} style={{ width: data.value / (data.value + data.valueOpposite) * 100 + '%' }}></div>
                        <div className={classes.opposite} style={{ width: data.valueOpposite / (data.value + data.valueOpposite) * 100 + '%' }}></div>
                    </>
                ) : (
                    <>
                        <div className={classes.opposite} style={{ width: data.valueOpposite / (data.value + data.valueOpposite) * 100 + '%' }}></div>
                        <div className={classes.own} style={{ width: data.value / (data.value + data.valueOpposite) * 100 + '%' }}></div>
                    </>
                )}

            </div>
        </div>
    )
}
