import React from "react";
import {
  Edit,
  ImageField,
  ImageInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { imageBaseUrl } from "src/utils/endpoints";

export default function ClubEdit() {
  return (
    <Edit>
      <SimpleForm>
        <TextInput source="name" label="Название" />
        <ReferenceInput source="championshipId" reference="championship">
          <SelectInput optionText="name.ru" required label="Чемпионат" />
        </ReferenceInput>
        <ImageInput
          source="logo"
          label="Логотип клуба"
          accept="image/*"
          placeholder={<p>Нажмите сюда или перетащите картинку</p>}
          format={(value) => {
            if (typeof value === "string") {
              return { src: imageBaseUrl + value };
            }
            return value;
          }}
        >
          <ImageField source="src" title="avatar" />
        </ImageInput>
        <ImageInput
          source="image"
          label="Фотография клуба"
          accept="image/*"
          placeholder={<p>Нажмите сюда или перетащите картинку</p>}
          format={(value) => {
            if (typeof value === "string") {
              return { src: imageBaseUrl + value };
            }
            return value;
          }}
        >
          <ImageField source="src" title="фото" />
        </ImageInput>
      </SimpleForm>
    </Edit>
  );
}
