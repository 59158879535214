import nProgress from "nprogress";
import { useEffect, useState } from "react";
import BodyContainer from "src/Container/BodyContainer";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import Title from "src/components/site/common/Title";
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';
import TelegramIcon from 'src/assets/icons/telegram.png'
import FacebookIcon from 'src/assets/icons/facebook.png'
import "src/global/common.css";
import Layout from "src/layout/site";

import classes from './styles.module.css'
import { useMediaQuery } from "@mui/material";
import { getMediaById } from "src/services/media";
import { format } from "date-fns";
import { imageBaseUrl } from "src/utils/endpoints";
import SystemContext from "src/context/systemContext";
import { useContext } from "react";
import { Helmet } from "react-helmet";

export default function MediaSinglePage() {
  const { system } = useContext(SystemContext)
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("576"), {
    defaultMatches: true,
    noSsr: false,
  });
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [media, setMedia] = useState();
  const { t, i18n } = useTranslation()

  useEffect(() => {
    async function fetchAll() {
      nProgress.start();
      const tempMedia = await getMediaById(id)
      setMedia(tempMedia);
      setLoading(false);
      nProgress.done();
    }
    fetchAll();
  }, [id]);

  return (
    <Layout loading={loading}>
      <Helmet>
        <title key="title">{`Медиа – ${system?.find(s => s.name === "siteName")?.value}`}</title>
      </Helmet>
      <BodyContainer>
        <div className={classes.newsTop}>
          <Title text={media?.title?.[i18n.language]} style={{ textTransform: "none", padding: 0 }} />
          {!isSmall &&
            <div className={classes.share}>
              <a href={`https://t.me/share/url?url=${window.location.href}&text=${media?.title[i18n.language]}`} target="_blank" rel="noreferrer" >
                <img src={TelegramIcon} alt="telegram" width={50} />
              </a>
              <a href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`} target="_blank" rel="noreferrer">
                <img src={FacebookIcon} alt="facebook" width={50} />
              </a>
              <a href="/"
                onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(window.location.href);
                }} title={t("Copy")}>
                <ShareRoundedIcon htmlColor="#191919" className={classes.icon} />
              </a>
            </div>
          }
        </div>
        <div className={classes.newsDate}>
          <span>

            <CalendarMonthRoundedIcon htmlColor="#646464" className={classes.dateIcon} />
            {media && format(new Date(media?.createdAt.replace(/-/g, '/')), "dd.LL.yyyy")}
          </span>
          {isSmall &&
            <div className={classes.share}>
              <a href={`https://t.me/share/url?url=${window.location.href}&text=${media?.title[i18n.language]}`} target="_blank" rel="noreferrer" >
                <img src={TelegramIcon} alt="telegram" width={50} />
              </a>
              <a href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`} target="_blank" rel="noreferrer">
                <img src={FacebookIcon} alt="facebook" width={50} />
              </a>
              <a href="/"
                onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(window.location.href);
                }} title={t("Copy")}>
                <ShareRoundedIcon htmlColor="#191919" className={classes.icon} />
              </a>
            </div>
          }
        </div>
        <div className={classes.mediaContent}>
          {media?.images.map((image, i) => <div className={classes.image} key={i}><img src={imageBaseUrl + image} alt="media" /></div>)}
        </div>

      </BodyContainer>
    </Layout>
  );
}
