import {
  Create,
  SimpleForm,
  TextInput,
} from "react-admin";


export default function UserCreate(props) {

  return (
    <Create {...props} sx={{ overflow: "visible" }} >
      <SimpleForm >

        <TextInput
          source="name"
          label="Полное имя"
        />
      </SimpleForm>
    </Create>
  );
}
