
import React from 'react'
import { Create, ImageField, ImageInput, SimpleForm, TextInput, TranslatableInputs } from 'react-admin'
import Editor from '../common/Editor'

export default function ChampionshipCreate(props) {

    return (
        <Create {...props}>
            <SimpleForm>
                <TranslatableInputs locales={['ru', 'uz', 'en']} defaultLocale="ru">
                    <TextInput source="title" label="Название" />
                </TranslatableInputs>
                <p style={{ marginTop: "15px" }}>Описание</p>
                <TranslatableInputs locales={['ru', 'uz', 'en']} defaultLocale="ru">
                    <Editor source="description" />
                </TranslatableInputs>
                <ImageInput source="image" label="Фото" accept="image/*" placeholder={<p>Нажмите сюда или перетащите картинку</p>}>
                    <ImageField source="src" title="avatar" />
                </ImageInput>
            </SimpleForm>
        </Create>
    )
}
