import { useEffect } from "react";
import { useController } from "react-hook-form";
import { useQuill } from "react-quilljs";
import { postEditorIMage } from "src/services/editor";
import { imageBaseUrl } from "src/utils/endpoints";

import "quill/dist/quill.snow.css";

import classes from "./styles.module.css";

export default function Editor({
  lang,
  readOnly,
  theme,
  className = "",
  style = {},
  source
}) {
  const fullDescription = useController({ name: source });

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"],
      [{ align: [] }],

      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],

      [{ header: [2, 3, 4, false] }],
      ["link", "image"],
      [{ color: [] }, { background: [] }],
      ["code-block"],
      ["blockquote"],
      ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };
  const formats = [
    "bold",
    "italic",
    "underline",
    "strike",
    "align",
    "list",
    "indent",
    "header",
    "link",
    "image",
    "color",
    "background",
    "code-block",
    "blockquote",
    "clean",
  ];
  const { quill, quillRef } = useQuill({
    theme: theme || "snow",
    scrollingContainer: "html",
    readOnly: readOnly || false,
    modules: modules,
    formats: formats,
    // modules: { blotFormatter: { specs: [CustomImageSpec] } },
  });
  // if (Quill && !quill) {
  //   Quill.register("modules/blotFormatter", BlotFormatter);
  // }

  const insertToEditor = (url) => {
    const range = quill.getSelection();
    quill.insertEmbed(range.index, "image", url);
  };

  // Upload Image to Image Server such as AWS S3, Cloudinary, Cloud Storage, etc..
  const saveToServer = async (file) => {
    const result = await postEditorIMage(file);
    insertToEditor(imageBaseUrl + result.body);
  };

  // Open Dialog to select Image File
  const selectLocalImage = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = () => {
      const file = input.files[0];
      saveToServer(file);
    };
  };

  useEffect(() => {
    if (quill) {
      // Add custom handler for Image Upload
      quill.getModule("toolbar").addHandler("image", selectLocalImage);
      if (fullDescription.field.value) {
        if (typeof fullDescription.field.value === "string") {
          // quill.setText(value);
          quill.root.innerHTML = fullDescription.field.value;
          // quill.clipboard.dangerouslyPasteHTML(fullDescription.field.value);
        } else if (Array.isArray(fullDescription.field.value)) {
          quill.setContents(fullDescription.field.value);
        }
      }
    }
  }, [quill]);

  useEffect(() => {
    if (quill) {
      quill.on("text-change", (delta, oldDelta, source) => {

        fullDescription.field.onChange(quill.root.innerHTML);
        // console.log(quill.getText()); // Get text only
        // console.log(quill.getContents().ops); // Get delta contents
        // console.log(quill.root.innerHTML); // Get innerHTML using quill
        // console.log(quillRef.current.firstChild.innerHTML); // Get innerHTML using quillRef
      });
    }
  }, [quill, fullDescription.field]);

  return (
    <div
      className={
        className
          ? [classes[className], classes["editor"]].join(",")
          : classes["editor"]
      }
      style={style}
    >
      <div ref={quillRef}></div>
    </div>
  );
}
