import http from "./httpService";
import { apiBaseUrl } from "../utils/endpoints";
async function getNews({ limit, offset = 0, exclude }) {
    try {
        const { data } = await http.get(apiBaseUrl + "/news", { params: { range: `[${offset}, ${limit}]`, sort: `["createdAt", "DESC"]`, exclude: exclude } });
        return data;
    } catch (err) {
        return err.response;
    }
}
async function getNewsBySlug(slug) {
    try {
        const { data } = await http.get(apiBaseUrl + "/news/slug/" + slug);
        return data;
    } catch (err) {
        return err.response;
    }
}
export { getNews, getNewsBySlug };