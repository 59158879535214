import http from "./httpService";
import { apiBaseUrl } from "../utils/endpoints";
async function getMedia({ limit, offset = 0, exclude }) {
    try {
        const { data } = await http.get(apiBaseUrl + "/media", { params: { range: `[${offset}, ${limit}]`, sort: `["createdAt", "DESC"]`, exclude: exclude } });
        return data;
    } catch (err) {
        return err.response;
    }
}
async function getMediaById(id) {
    try {
        const { data } = await http.get(apiBaseUrl + "/media/" + id);
        return data;
    } catch (err) {
        return err.response;
    }
}
export { getMedia, getMediaById };