import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { Accordion, AccordionDetails, AccordionSummary, Box, Drawer } from "@mui/material";

import classes from './styles.module.css'

export const Sidebar = ({ menu, ...props }) => {
    const { open, onClose } = props;
    let location = useLocation();



    useEffect(
        () => {
            if (!location) {
                return;
            }

            if (open) {
                onClose?.();
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [location]
    );

    const content = (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                padding: "30px 40px",
            }}
        >

            <Box sx={{ flexGrow: 1, marginBottom: "48px" }}>
                {menu.map((m) => {
                    return (
                        <Accordion key={m.id} className={classes.sidebarAccordion}>
                            <AccordionSummary
                                expandIcon={""}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <p>{m.title}</p>
                            </AccordionSummary>
                            <AccordionDetails className={classes.sidebarSubmenu}>
                                {m.subMenu.map(sm => (<Link to={sm.href} key={"submenu-" + m.id + sm.id}>{sm.title}</Link>))}
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </Box>
        </Box>
    );

    return (
        <Drawer
            anchor="left"
            onClose={onClose}
            open={open}
            PaperProps={{
                sx: {
                    backgroundColor: "background.darkBlue",
                    color: "#FFFFFF",
                    width: "68%",
                    maxWidth: "220px",
                },
            }}
            sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
            variant="temporary"
        >
            {content}
        </Drawer>
    );
};

Sidebar.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
};