import { useContext, useEffect, useState } from 'react'
import SystemContext from 'src/context/systemContext';
import { imageBaseUrl } from 'src/utils/endpoints'
import classes from './styles.module.css'

export default function SingleClub({ index, club, season }) {
    const { system } = useContext(SystemContext)
    const [result, setResult] = useState({});
    useEffect(() => {
        const tempResult = club?.club_results?.find(cr => cr.seasonId === season);
        setResult(tempResult)
    }, [club, season])
    return (
        <div className={`${classes.club} ${club.isMine ? "active" : ""}`}>
            <span className={classes.columnIndex}>{index + 1}</span>
            <span className={classes.columnLogo}><img src={imageBaseUrl + club.logo} alt="logo" onError={({ currentTarget }) => {
                currentTarget.src = imageBaseUrl + system.find(s => s.name === "imagePlaceholder")?.value;
            }} /></span>
            <span className={classes.columnName}>{club.name}</span>
            <span className={classes.columnMatches}>{result?.totalMatches || 0}</span>
            <span className={classes.columnMatches}>{result?.wins || 0}</span>
            <span className={classes.columnMatches}>{result?.draws || 0}</span>
            <span className={classes.columnMatches}>{result?.losses || 0}</span>
            <span className={classes.columnGoals}>{(result?.goalsScored || 0) + "-" + (result?.goalsConceded || 0)}</span>
            <span className={classes.columnMatches}>{(result?.wins * 3 + result?.draws * 1) || 0}</span>
        </div>
    )
}
