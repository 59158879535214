// in src/StoreAdmin.js
import { Admin, CustomRoutes, Resource, useTranslate } from "react-admin";
import simpleRestProvider from "ra-data-simple-rest";
import polyglotI18nProvider from "ra-i18n-polyglot";

import "src/global/common.css";

import Layout from "src/layout/admin";
import slides from "src/components/admin/slides";
import users from "src/components/admin/users";
import userRoles from "src/components/admin/userRoles";
import news from "src/components/admin/news";
import newsCategory from "src/components/admin/newsCategory";
import media from "src/components/admin/media";
import seasons from "src/components/admin/seasons";
import championships from "src/components/admin/championships";
import clubs from "src/components/admin/clubs";
import myClubs from "src/components/admin/myClubs";
import referees from "src/components/admin/referees";
import matches from "src/components/admin/matches";
import players from "src/components/admin/players";
import playerRoles from "src/components/admin/playerRoles";
import system from "src/components/admin/system";
import coach from "src/components/admin/coach";
import staff from "src/components/admin/staff";
import sponsor from "src/components/admin/sponsor";
import academy from "src/components/admin/academy";
import about from "src/components/admin/about";
import massMedia from "src/components/admin/massMedia";

import dashboard from "src/components/admin/dashboard";

import ruMessages from "src/i18n/ru";
import LoginPage from "./LoginPage";
import authProvider from "src/utils/authProvider";
import { apiBaseUrl } from "src/utils/endpoints";
import { lightTheme } from "src/layout/themes";
import { Route } from "react-router-dom";
import Configuration from "src/components/admin/configuration/Configuration";
import httpClient from "src/utils/httpClient";

import myDataProvider from "src/utils/myDataProvider";

const i18nProvider = polyglotI18nProvider((locale) => {
  if (locale === "uz") {
    return import("src/i18n/uz").then((messages) => messages.default);
  }

  // Always fallback on russian
  return ruMessages;
}, "en");

export default function AdminPage() {
  const translate = useTranslate();
  const dataProvider = simpleRestProvider(apiBaseUrl, httpClient);

  return (
    <Admin
      authProvider={authProvider}
      requireAuth
      basename="/admin"
      disableTelemetry
      layout={Layout}
      dashboard={dashboard}
      dataProvider={myDataProvider(dataProvider)}
      i18nProvider={i18nProvider}
      title=""
      loginPage={LoginPage}
      theme={lightTheme}
    >
      <CustomRoutes>
        <Route path="/configuration" element={<Configuration />} />
      </CustomRoutes>
      <Resource name="slides" {...slides} />
      <Resource name="users" {...users} />
      <Resource
        name="users/roles"
        {...userRoles}
        options={{ label: translate("resources.userRoles.name") }}
      />
      <Resource name="news" {...news} />
      <Resource
        name="news/category"
        {...newsCategory}
        options={{ label: translate("resources.newsCategory.name") }}
      />
      <Resource name="media" {...media} />
      <Resource name="season" {...seasons} />
      <Resource name="championship" {...championships} />
      <Resource name="club" {...clubs} />
      <Resource name="club/me" {...myClubs} />
      <Resource name="player" {...players} />
      <Resource name="player/role" {...playerRoles} />
      <Resource name="match" {...matches} />
      <Resource name="referee" {...referees} />
      <Resource name="system" {...system} />
      <Resource name="coach" {...coach} />
      <Resource name="staff" {...staff} />
      <Resource name="sponsor" {...sponsor} />
      <Resource name="academy" {...academy} />
      <Resource name="about" {...about} />
      <Resource name="mass-media" {...massMedia} />
    </Admin>
  );
}
