import { FormControl, MenuItem, Select } from "@mui/material";
import nProgress from "nprogress";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";

import Title from "src/components/site/common/Title";

import BodyContainer from "src/Container/BodyContainer";
import Layout from "src/layout/site";
import { getTournaments } from "src/services/tournaments";

import classes from "./styles.module.css";
import TournamentTable from "src/components/site/tournament/TournamentTable";
import SystemContext from "src/context/systemContext";
import { useContext } from "react";
import { Helmet } from "react-helmet";

export default function TournamentsPage() {
  const { system } = useContext(SystemContext);
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [seasons, setSeasons] = useState();
  const [tournaments, setTournaments] = useState();
  const [clubs, setClubs] = useState();

  const [seasonSelected, setSeasonSelected] = useState(1);
  const [tournamentSelected, setTournamentSelected] = useState(1);

  useEffect(() => {
    async function fetchAll() {
      nProgress.start();
      const data = await getTournaments();
      const tempSeasons = data.seasons;
      const tempTournaments = data.tournaments;
      const tempClubs = data.clubs;
      setSeasons(tempSeasons);
      setSeasonSelected(tempSeasons.length);
      setTournaments(tempTournaments);
      setClubs(tempClubs);
      setLoading(false);
      nProgress.done();
    }
    fetchAll();
  }, []);

  function handleTournamentChange(value) {
    setTournamentSelected(value);
  }

  function handleSeasonChange(value) {
    setSeasonSelected(value);
  }

  return (
    <Layout loading={loading}>
      <Helmet>
        <title key="title">{`Турниры – ${
          system?.find((s) => s.name === "siteName")?.value
        }`}</title>
      </Helmet>
      <BodyContainer>
        <>
          <div className={classes.tournamentsTop}>
            <Title text={t("Tournament table")} style={{ padding: 0 }} />
            <div className={classes.selectWrapper}>
              <FormControl className={classes.select} variant="standard">
                <Select
                  labelId="tournament-select-label"
                  id="tournament-select"
                  value={tournamentSelected}
                  onChange={(e) => handleTournamentChange(e.target.value)}
                  IconComponent={() => <ExpandMoreOutlinedIcon />}
                  disableUnderline
                >
                  {tournaments?.map((t) => {
                    return (
                      <MenuItem
                        key={t.id}
                        value={t.id}
                        className={classes.monthOption}
                      >
                        {t.name[i18n.language]}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <FormControl className={classes.select} variant="standard">
                <Select
                  labelId="season-select-label"
                  id="season-select"
                  value={seasonSelected}
                  onChange={(e) => handleSeasonChange(e.target.value)}
                  IconComponent={() => <ExpandMoreOutlinedIcon />}
                  disableUnderline
                >
                  {seasons?.map((s) => {
                    return (
                      <MenuItem
                        key={s.id}
                        value={s.id}
                        className={classes.monthOption}
                      >
                        {s.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
          <TournamentTable
            clubs={clubs}
            seasonSelected={seasonSelected}
            tournamentSelected={tournamentSelected}
          />
        </>
      </BodyContainer>
    </Layout>
  );
}
