
import React from 'react'
import { Create, SimpleForm, TextInput } from 'react-admin'

export default function SeasonCreate(props) {

    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput source="name" label="Название" />
            </SimpleForm>
        </Create>
    )
}
