import { Link } from "react-router-dom";

import classes from "./styles.module.css";

export default function MoreButton({ link, text, ...props }) {
  if (link) {
    return (
      <Link to={link} className={classes.moreButton} {...props}>
        {text}
      </Link>
    );
  } else {
    return (
      <div className={classes.moreButton} {...props}>
        {text}
      </div>
    );
  }
}
