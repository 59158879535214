import PersonPinTwoToneIcon from '@mui/icons-material/PersonPinTwoTone';
import StaffList from "./StaffList";
import StaffEdit from "./StaffEdit";
import StaffCreate from "./StaffCreate";

const resource = {
  list: StaffList,
  create: StaffCreate,
  edit: StaffEdit,
  icon: PersonPinTwoToneIcon,
};

export default resource;
