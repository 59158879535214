import http from "./httpService";
import { apiBaseUrl } from "../utils/endpoints";
async function getCoahes({ filter = {} }) {
    try {
        let { data } = await http.get(apiBaseUrl + "/coach", { params: { filter: filter } });
        return data;
    } catch (err) {
        return err.response;
    }
}

export { getCoahes };