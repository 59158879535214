import { Box } from "@mui/material";
import {
  AutocompleteInput,
  DateInput,
  Edit,
  ImageField,
  ImageInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { COUNTRIES } from "src/utils/const";
import { imageBaseUrl } from "src/utils/endpoints";

export default function UserEdit() {
  return (
    <Edit>
      <SimpleForm>
        <TextInput source="name" label="Полное имя" />
        <ReferenceInput source="playerRoleId" reference="player/role">
          <SelectInput optionText="name.ru" required label="Роль" />
        </ReferenceInput>
        <ReferenceInput source="clubId" reference="club/me">
          <SelectInput optionText="name" required label="Клуб" />
        </ReferenceInput>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
          <NumberInput source="height" label="Рост" />
          <NumberInput source="weight" label="Вес" />
          <DateInput source="dob" label="Дата рождения" />
          <NumberInput source="number" label="Номер игрока" />
        </Box>
        <AutocompleteInput
          sx={{ minWidth: "300px" }}
          source="country"
          choices={COUNTRIES}
          optionValue="id"
          optionText="name"
          format={(value) => value.toUpperCase()}
        />
        <ImageInput
          source="image"
          label="Фото игрока"
          accept="image/*"
          placeholder={<p>Нажмите сюда или перетащите картинку</p>}
          format={(value) => {
            if (typeof value === "string") {
              return { src: imageBaseUrl + value };
            }
            return value;
          }}
        >
          <ImageField source="src" title="avatar" />
        </ImageInput>
      </SimpleForm>
    </Edit>
  );
}
