import PermMediaIcon from '@mui/icons-material/PermMedia';

import MediaList from "./MediaList";
import MediaEdit from "./MediaEdit";
import MediaCreate from "./MediaCreate";

const resource = {
  list: MediaList,
  create: MediaCreate,
  edit: MediaEdit,
  icon: PermMediaIcon,
};

export default resource;
