import { Link } from "react-router-dom";

import MainItemsWrapper from "../../main/MainItemWrapper";
import SingleNewsBlock from "../SingleNewsBlock";

export default function SingleMedia({ data, page }) {
  return (
    <MainItemsWrapper page={page}>
      <Link
        to={`/media/${data.id}`}
        style={{ display: "block", height: "100%" }}
      >
        <SingleNewsBlock data={data} type="media" />
      </Link>
    </MainItemsWrapper>
  );
}
