import nProgress from "nprogress";
import React, { useEffect, useState } from "react";
import Matches from "src/components/site/main/matches/Matches";
import Media from "src/components/site/main/media/Media";
import News from "src/components/site/main/news/News";
import Players from "src/components/site/main/players/Players";
import Slider from "src/components/site/main/slider/Slider";
import BodyContainer from "src/Container/BodyContainer";

import "src/global/common.css";
import Layout from "src/layout/site";
import { getMatches } from "src/services/matches";
import { getMedia } from "src/services/media";
import { getNews } from "src/services/news";
import { getPlayers } from "src/services/players";
import { getSlides } from "src/services/slides";

import classes from "./styles.module.css";

export default function MainPage() {
  const [slides, setSlides] = useState([]);
  const [matches, setMatches] = useState([]);
  const [news, setNews] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [players, setPlayers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchAll() {
      nProgress.start();
      const tempSlides = await getSlides();
      setSlides(tempSlides);
      
      const tempMatches = await getMatches({ limit: 3 });
      setMatches(tempMatches);
      
      const tempNews = await getNews({ limit: 5 });
      setNews(tempNews);
      
      const tempPhotos = await getMedia({ limit: 3 });
      setPhotos(tempPhotos);
      
      const tempPlayers = await getPlayers({ limit: 3, filter: { clubId: 1 } });
      setPlayers(tempPlayers);
      
      setLoading(false);
      nProgress.done();
    }
    fetchAll();
  }, []);

  return (
    <Layout page="main" loading={loading}>
      <Slider slides={slides} />
      <div className={classes.mainPageWrapper}>
        <BodyContainer>
          <Matches matches={matches} />
          <News news={news} />
          <Media photos={photos} />
          {/* <Players players={players} /> */}

        </BodyContainer>
      </div>
    </Layout>
  );
}
