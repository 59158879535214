import RecentActorsIcon from '@mui/icons-material/RecentActors';

import PlayerList from "./PlayerRoleList";
import PlayerEdit from "./PlayerRoleEdit";
import PlayerCreate from "./PlayerRoleCreate";

const resource = {
  list: PlayerList,
  create: PlayerCreate,
  edit: PlayerEdit,
  icon: RecentActorsIcon,
};

export default resource;
