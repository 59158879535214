
import { DateInput, Edit, ImageField, ImageInput, NumberInput, SelectInput, SimpleForm, TextInput, TranslatableInputs } from 'react-admin'
import { imageBaseUrl } from 'src/utils/endpoints';

export default function StaffEdit() {

    return (
        <Edit>
            <SimpleForm>
                <TextInput source="name" label="Полное имя" />
                <SelectInput source="category" choices={[
                    { id: 'lead', name: 'Руководство' },
                    { id: 'staff', name: 'Персонал' },
                ]} />
                <TranslatableInputs locales={['ru', 'uz', 'en']} defaultLocale="ru">
                    <TextInput source="role" label="роль" />
                </TranslatableInputs>
                <DateInput source="dob" label="Дата рождения" />
                <NumberInput source="arrivalYear" label="Год начала сотрудничества" />
                <ImageInput source="image" label="Фото сотрудника" accept="image/*" placeholder={<p>Нажмите сюда или перетащите картинку</p>} format={(value) => {
                    if (typeof value === "string") {
                        return { src: imageBaseUrl + value };
                    }
                    return value;
                }}>
                    <ImageField source="src" title="avatar" />
                </ImageInput>
            </SimpleForm>
        </Edit>
    )
}
