import { Box } from '@mui/material';
import { useContext } from 'react'
import LangContext from 'src/context/langContext';

import classes from './styles.module.css'

export default function LangButtons() {
    const { lang, setLang } = useContext(LangContext);
    return (
        <Box sx={{
            color: "black", display: "flex", gap: "19px",
            // [theme.breakpoints.down("1550")]: { marginLeft: "auto" }
        }}>
            <button
                className={`${classes.langButton} ${lang === "ru" ? "active" : ""}`}
                onClick={async () => {
                    // const lang = await setLanguage("ru");
                    setLang("ru");
                }}
            >
                RU
            </button>

            <button
                className={`${classes.langButton} ${lang === "uz" ? "active" : ""}`}

                onClick={async () => {
                    // const lang = await setLanguage("uz");
                    setLang("uz");
                }}
            >
                UZ
            </button>

        </Box>
    )
}
