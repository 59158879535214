import SlidesListContent from "./SlidesListContent";

export default function SlidesList() {
  return (
    // <List>
    <SlidesListContent/>

    // </List>
  );
}
