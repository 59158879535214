import { useMediaQuery } from '@mui/material'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import classes from './styles.module.css'
import { useNavigate } from 'react-router-dom';

export default function Title({ text, page, style, ...props }) {
    const navigate = useNavigate();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down("576"), {
        defaultMatches: true,
        noSsr: false,
    });
    if (page !== "main") {
        return <h1 className={classes.title} style={style} {...props}>{isSmall && <KeyboardBackspaceIcon onClick={() => navigate(-1)} />}{text}</h1>

    }
    return (
        <h2 className={`${classes.title} ${classes.main}`} style={style} {...props}>{text}</h2>
    )
}
