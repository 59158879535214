import {
  List,
  Datagrid,
  TextField
} from "react-admin";

export default function UserList() {
  return (
    <List>
      <Datagrid rowClick="edit">
        <TextField source="id" label="id" />
        <TextField source="title.ru" label="Заголовок" />

        <TextField source="images.length" label="Количество фото" />
      </Datagrid>
    </List >
  );
}
